import { UrlQuery } from 'types/url'
import { createQueryString } from 'utils/createQueryString'
import { getDeviceParameters } from 'utils/getDeviceParameters'
import { api } from '..'
import { offerResponseFromDto, oneOfferResponseFromDto } from './dto'
import {
  EarnResponseDto,
  Offer,
  OfferResponse,
  OfferResponseDto,
  Platform,
} from './types'

/**
 * tab_1 : Most paying offers: hard and medium difficulties offers
 * tab_2 : Easy offers
 */
type OfferTab = 'tab_1_page' | 'tab_2_page'

export const PAGE_LIMIT = 20

export default class OfferService {
  static async getAll(
    urlQuery: UrlQuery,
    page: number,
    requestID: string,
    tab: OfferTab | null = null
  ): Promise<OfferResponse> {
    const url = createGetAllUrl(urlQuery, page, requestID, tab)
    const response = await api.get<OfferResponseDto>(url)
    const lastPosition = (response.data.page - 1) * PAGE_LIMIT
    performance.measure('LOAD_FINISHED')
    return offerResponseFromDto(
      response.data,
      lastPosition,
      urlQuery.platform as Platform
    )
  }

  static async get(urlQuery: UrlQuery): Promise<Offer> {
    const url = createGetUrl(urlQuery)
    const response = await api.get(url)
    return oneOfferResponseFromDto(response.data)
  }

  static async getEarnUrl(offerUrl: string, platform: string): Promise<string> {
    const response = await api.post<EarnResponseDto>(offerUrl)
    const {
      offer_url,
      background_tracking,
      mobile_app_identifier,
      ignite_url,
    } = response.data
    if (ignite_url) {
      const encodedIgniteUrl = `sponsorpay://exit?url=${encodeURIComponent(
        ignite_url as string
      )}`
      return encodedIgniteUrl
    }
    const urlToRedirect =
      background_tracking && platform === 'android'
        ? `market://details?id=${mobile_app_identifier}`
        : offer_url
    const encodedUrl = `sponsorpay://exit?url=${encodeURIComponent(
      urlToRedirect as string
    )}`
    return encodedUrl
  }
}

// Utils
function createGetAllUrl(
  urlQuery: UrlQuery,
  page: number,
  requestID: string,
  tab: string | null
): string {
  const BASE_URL = '/offer'

  delete urlQuery['request_id']

  const queryString = createQueryString(urlQuery)
  const { deviceId, deviceType } = getDeviceParameters(urlQuery)
  //refactor after tabs experiment
  let pageParams = `page=${page}`

  if (tab) {
    pageParams = `${tab}=${page}&${
      tab === 'tab_1_page' ? 'tab_2_page=1' : 'tab_1_page=1'
    }`
  }

  return `${BASE_URL}?${queryString}&limit=${PAGE_LIMIT}&${pageParams}&device_id=${deviceId}&device_id_type=${deviceType}&request_id=${requestID}`
}

function createGetUrl(urlQuery: UrlQuery): string {
  const BASE_URL = '/show/fyber'

  delete urlQuery['impression']

  const queryString = createQueryString(urlQuery)

  return `${BASE_URL}?${queryString}`
}
