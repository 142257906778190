export function HardTagIcon() {
  return (
    <svg
      className="w-[26px] h-[12px] md:w-[40px] md:h-[18px]"
      width="27"
      height="13"
      viewBox="0 0 27 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 2.41978C18.6227 0.926897 15.7728 0.110352 12.8288 0.110352C10.0249 0.110352 7.30643 0.85103 5 2.21147L9.1643 5.93751C10.2987 5.45512 11.5489 5.198 12.8288 5.198C14.2541 5.198 15.6426 5.51675 16.8751 6.11035L21 2.41978Z"
        fill="#FFB300"
      />
      <path
        d="M4.03658 3.28191C4.1008 3.22406 4.16583 3.16683 4.23141 3.11035L8 6.59356C7.93009 6.65129 7.86099 6.71028 7.79271 6.77039C7.7499 6.8082 7.70763 6.84652 7.66563 6.88534C6.16637 8.27112 5.32421 10.1505 5.32421 12.1104H0C0 8.84541 1.40333 5.71421 3.90083 3.40564C3.94581 3.36406 3.99106 3.32286 4.03658 3.28191Z"
        fill="#4CAF50"
      />
      <path
        d="M18 6.68708C18.0194 6.70459 18.0387 6.72222 18.0581 6.73985C19.6114 8.16421 20.4839 10.096 20.4839 12.1104H26C26 8.7545 24.5461 5.53611 21.9586 3.16325C21.9392 3.14549 21.9199 3.12799 21.9005 3.11035L18 6.68708Z"
        fill="#FD0000"
      />
      <circle cx="13" cy="11.1104" r="1" fill="#353535" />
      <path
        d="M26 11.1104L13.0001 11.1103"
        stroke="#353535"
        strokeLinecap="round"
      />
    </svg>
  )
}
