export const createQueryString = (urlQuery) =>
  Object.keys(urlQuery)
    .filter(
      (key) =>
        urlQuery[key] !== undefined &&
        urlQuery[key] !== null &&
        urlQuery[key] !== 'undefined'
    )
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(urlQuery[key])}`
    )
    .join('&')
