import { useEffect, useRef } from 'preact/hooks'

export function useTimer() {
  const secondsRef = useRef<number>(0)

  useEffect(() => {
    const timer = setInterval(() => {
      secondsRef.current += 1
    }, 1000)
    return () => clearInterval(timer)
  }, [])

  return { secondsRef }
}
