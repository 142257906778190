import { Offer } from 'api/offer/types'
import { Trans, useTranslation } from 'react-i18next'
import { secondsToDays, secondsToHours } from 'utils/date'
import { kFormatter } from 'utils/kFormatter'
import { percentage } from 'utils/number'
import { Button } from '../../shared/button'
import { Flex } from '../../shared/layout'
import { ProgressCircle } from '../../shared/ProgressCircle'

interface Props {
  offer: Offer
  color: string
  currencyIconUrl: string
  onClick: () => void
}

export function ReengagementRow(props: Props) {
  const { offer, color, currencyIconUrl, onClick } = props
  const completedSteps = offer.steps.filter((step) => step.completed)
  const progress = percentage(completedSteps.length, offer.steps.length)

  const { t } = useTranslation()

  const days = secondsToDays(offer.expiresIn)
  const hours = secondsToHours(offer.expiresIn)

  function getExpiresInText() {
    if (days > 7) {
      return t('reengagement_status_text')
    }

    if (days > 0) {
      return (
        <Trans i18nKey="reengagement_expires1">
          Expires in {{ days }} days
        </Trans>
      )
    }

    return (
      <Trans i18nKey="reengagement_expires2">
        Expires in {{ hours }} hours
      </Trans>
    )
  }

  return (
    <Flex alignItems="center" gap="12px" onClick={onClick}>
      <img
        className="flex w-11 h-11 rounded"
        width={44}
        height={44}
        src={offer.icon}
        onError={(e: any) => {
          e.target.onerror = null
          e.target.src = '/images/digital-turbine-logo.svg'
        }}
        loading="lazy"
      />
      <Flex flexGrow={1} flexDirection="column" gap="4px">
        <h3 className="m-0 font-semibold text-sm text-left w-[200px] landscape:w-[400px] md:w-[300px] landscape:md:w-[300px] whitespace-nowrap overflow-hidden text-ellipsis">
          {offer.title}
        </h3>
        <Flex gap="8px">
          <div
            className="font-normal text-[12px] leading-4"
            style={{
              color: days === 0 ? 'var(--red-500)' : 'var(--gray-700)',
            }}
          >
            {getExpiresInText()}
          </div>

          {offer.multiPayout && (
            <Flex justifyContent="center">
              <ProgressCircle size="sm" percentage={progress} />
              <div className="font-normal text-[12px] leading-4 text-fyberLightBlue">
                {progress}%
              </div>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Button
        style={{
          width: 48,
          height: 40,
        }}
        color={color}
        borderRadius="5px"
      >
        <img
          style={{ width: 20, height: 20, marginRight: 4, maxWidth: 'unset' }}
          src={currencyIconUrl}
        />
        {kFormatter(offer.reward.amount)}
      </Button>
    </Flex>
  )
}
