import { useEffect, useState } from 'preact/hooks'
import { useMenuStore } from 'store/menu'
import { Position } from 'types/misc'

export default function useInfiniteScrollViewport() {
  const [scrollTopInfinite, setScrollTopInfinite] = useState(0)

  const activeTab = useMenuStore((state) => state.activeTab)

  function isOfferVisible(rect: Position) {
    const leftCheck = rect.left >= 0
    const topCheck = rect.top >= 0

    // Math.floor because in Android phones the right position
    // can be slightly higher than the width, causing the AT pixels to stop firing
    const rightCheck =
      Math.floor(rect.right) <=
      (window.innerWidth || document.documentElement.clientWidth)

    const bottomCheck =
      rect.bottom - rect.height + 20 <=
      (window.innerHeight || document.documentElement.clientHeight)

    const withinX = leftCheck && rightCheck
    const withinY = topCheck && bottomCheck

    return withinX && withinY
  }

  useEffect(() => {
    const handleScroll = () => {
      setScrollTopInfinite(
        document.querySelector('.infinite-scroll-component').scrollTop
      )
    }

    const scrollComponent = document.querySelector('.infinite-scroll-component')

    if (scrollComponent) {
      scrollComponent.addEventListener('scroll', handleScroll, {
        passive: true,
      })
    }

    return () => {
      if (scrollComponent) {
        scrollComponent.removeEventListener('scroll', handleScroll)
      }
    }
  }, [activeTab])

  return {
    scrollTopInfinite,
    isOfferVisible,
  }
}
