import { useGetConfig } from 'api/config/hooks'
import { ArrowLeftIcon, CloseIcon, MenuIcon } from 'components/shared/icons'
import useBannerHeight from 'hooks/useBannerHeight'
import useGetCurrentPage from 'hooks/useGetCurrentPage'
import useInfiniteScrollViewport from 'hooks/useInfiniteScrollViewport'
import useViewport from 'hooks/useViewport'
import { route } from 'preact-router'
import { Trans, useTranslation } from 'react-i18next'
import { useStore } from 'store'
import { useMenuStore } from 'store/menu'
import { useRouterStore } from 'store/router'
import { clamp } from 'utils/clamp'
import { environment } from 'utils/environment'
import { makeGradient } from 'utils/makeGradient'
import styles from './styles.module.css'

const Banner = () => {
  const { scrollTopInfinite } = useInfiniteScrollViewport()
  const { isTablet, isLandscape } = useViewport({ registerEventListener: true })
  const setIsMenuOpen = useMenuStore((state) => state.setIsMenuOpen)
  const { setExitPixelSource } = useStore()
  const { query } = useRouterStore()
  const { currentPage } = useGetCurrentPage()
  const { t } = useTranslation()
  const { data: config } = useGetConfig({ enabled: false })

  const bannerHeight = useBannerHeight()

  let imageUrl
  if (isTablet || isLandscape) {
    imageUrl = config?.bannerTabletUrl
  } else {
    imageUrl = config?.bannerMobileUrl
  }

  const gradientStyle = makeGradient(config?.colors.button_color)
  const gradientStyleSupportBanner = makeGradient(
    config?.colors.button_color,
    true
  )

  function onClickBack() {
    if (currentPage === 'preview') return

    if (currentPage === 'support') {
      route(`/status${window.location.search}`)
    }

    if (currentPage === 'status') {
      route(`/mobile${window.location.search}`)
    }
  }

  function getExitUrl() {
    if (currentPage === 'preview') return '#'
    if (currentPage === 'placementPreview') return '#'
    if (environment === 'test') {
      return '#'
    }

    return 'sponsorpay://exit?status=1'
  }

  function onClickMenu() {
    if (currentPage === 'preview') return
    if (currentPage === 'placementPreview') return

    setIsMenuOpen(true)
  }

  switch (currentPage) {
    case 'placementPreview':
    case 'home':
      return (
        <header
          className={styles.bannerHome}
          style={{
            background: imageUrl ?? gradientStyle,
            height: clamp(47, bannerHeight - scrollTopInfinite, bannerHeight),
          }}
        >
          {imageUrl && (
            <div
              id="banner-image"
              className={styles.image}
              style={{
                background: `url(${imageUrl}) 50% 50% / cover`,
                opacity: clamp(0, 1 - scrollTopInfinite / 100, 1),
              }}
            />
          )}

          <div className={styles.overlay} style={{ background: gradientStyle }}>
            <div className={styles.layout}>
              <a
                data-cy="menu_button"
                className={styles.button}
                onClick={onClickMenu}
              >
                <MenuIcon />
              </a>
              <h1
                className={styles.title}
                style={{
                  opacity: imageUrl
                    ? clamp(0, scrollTopInfinite / 100, 1)
                    : null,
                }}
              >
                <Trans>Earn</Trans> {config?.currencyName}
                {config?.currencyIcon && (
                  <img className={styles.icon} src={config?.currencyIcon} />
                )}
              </h1>
              <a
                className={styles.button}
                data-cy="exit_button"
                onClick={() => {
                  setExitPixelSource('button')
                  setTimeout(() => {
                    window.location.href = getExitUrl()
                  }, 0)
                }}
              >
                <CloseIcon />
              </a>
            </div>
          </div>
        </header>
      )
    case 'status':
      return (
        <header
          className={styles.simpleBanner}
          style={{ background: gradientStyle ?? 'var(--fyber)' }}
        >
          <div className={styles.layout}>
            {query['client'] === 'sdk' ? (
              <a className={styles.button} onClick={onClickBack}>
                <ArrowLeftIcon />
              </a>
            ) : (
              <a className={styles.button} onClick={onClickMenu}>
                <MenuIcon />
              </a>
            )}

            <h1 className={styles.title}>{t('menu_status')}</h1>
            <div className={styles.emptyElement} />
          </div>
        </header>
      )
    case 'support':
      return (
        <header
          className={styles.simpleBanner}
          style={{ background: gradientStyleSupportBanner ?? 'var(--fyber)' }}
        >
          <div className={styles.layout}>
            {!query?.subid && (
              <a className={styles.button} onClick={onClickBack}>
                <ArrowLeftIcon />
              </a>
            )}
            {query?.subid && <div className={styles.emptyElement} />}
            <h1 className={styles.title}>{t('banner_support')}</h1>
            <div className={styles.emptyElement} />
          </div>
        </header>
      )
    case 'preview':
      return (
        <header
          className={styles.bannerHome}
          style={{
            background: imageUrl ?? gradientStyle,
            height: clamp(47, bannerHeight - scrollTopInfinite, bannerHeight),
          }}
        >
          <div className={styles.overlay} style={{ background: gradientStyle }}>
            <div className={styles.layout}>
              <a className={styles.button}>
                <MenuIcon />
              </a>
              <h1 className={styles.title}>Earn Coin</h1>
              <a className={styles.button}>
                <CloseIcon />
              </a>
            </div>
          </div>
        </header>
      )
    default:
      return null
  }
}

export default Banner
