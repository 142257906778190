import { useTranslation } from 'react-i18next'

interface Props {
  color: string
}

export function FreeIcon({ color }: Props) {
  const { t } = useTranslation()

  return (
    <div
      style={{
        backgroundColor: color,
        clipPath: 'polygon(0 0, 5% 50%, 0 100%, 100% 100%, 95% 50%, 100% 0)',
      }}
      className="flex items-center justify-center whitespace-nowrap"
    >
      <span className="text-[7px] md:text-[12px] font-semibold text-white uppercase px-2 pt-[2px] md:px-4">
        {t('tags_free')}
      </span>
    </div>
  )
}
