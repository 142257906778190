export const de = {
  translations: {
    action_complete_q_action: `Vervollständige eine schnelle Aktion`,
    action_complete_survey: `Vervollständige eine schnelle Umfrage`,
    action_social_media: `Engagement in sozialen Medien`,
    action_simple_registration: `Führe eine einfache Anmeldung durch`,
    action_discover_new: `Entdecke einen neuen Dienst/ein neues Produkt`,
    action_mobile_sub: `Hole dir ein spezielles Mobilfunkabonnement`,
    action_purchase: `Tätige einen Kauf`,
    action_new_service: `Probier einen neuen Dienst/ein neues Produkt aus`,
    action_complete_action: `Vervollständige eine Aktion`,
    action_quick_quiz: `Beantworte ein schnelles Quiz`,
    action_donation: `Mach eine Spende`,
    tags_free: `Gratis`,
    tags_limited_offer: `Begrenztes Angebot`,
    tags_multi_reward: `Multi-Reward`,
    tags_top_offer: `Top-Angebot!`,
    tags_new_users: `Nur für neue Benutzer`,
    up_to: `Bis zu +{{ currencyValue }} {{ currency }}`,
    menu_faq: `FAQ`,
    menu_offers: `Angebote`,
    menu_status: `Status`,
    menu_privacy: `Datenschutz`,
    status_pending: `Ausstehend`,
    status_reported: `Gemeldet`,
    status_expired: `Abgelaufen`,
    status_rejected: `Abgelehnt`,
    status_completed: `Abgeschlossene Aufgabe`,
    dtUserId: `Deine Digitale Turbine-Benutzer-ID lautet <1>{{ digitalTurbineId }}</1>`,
    complete_text: `Abschließen`,
    check_inbox: `Dieses Angebot wurde gemeldet. Bitte überprüfe deine E-Mail auf die Antwort.`,
    contacted: `Vielen Dank, dass du unseren Kundenservice kontaktiert hast. Wir werden uns so schnell wie möglich bei dir melden.`,
    description_report: `<0>Vielen Dank, dass Sie sich an das Benutzer-Support-Team gewandt haben. Es gibt zwei häufige Gründe für Probleme beim Empfang von {{ currencyText }}</0><1>1. Nicht erfüllte Angebotsanforderungen</1><2>Apps müssen heruntergeladen UND gestartet werden, um das Angebot abzuschließen.</2><3>Alle Daten müssen korrekt und wahrheitsgemäß eingegeben werden.</3><4>Oft müssen Sie Ihre E-Mail-Adresse über einen Link validieren, der an Ihre E-Mail gesendet wurde.</4><5>Viele Werbepartner erlauben nur eine Transaktion pro Person oder Haushalt.</5><6>2. Technische Gründe</6><7>Sie hatten keine funktionierende Datenverbindung, als Sie eine beworbene App gestartet haben.</7><8>Sie hatten die beworbene App bereits installiert, bevor Sie das Angebot gestartet haben.</8>`,
    problem_report: `Problembericht für {{ appName }}`,
    problem_type1: `Keine Gutschriften erhalten`,
    problem_type2: `Fehler während des Angebots`,
    problem_type3: `Sonstiges`,
    email_confirmation: `Bestätige deine E-Mail`,
    full_name: `Vollständiger Name`,
    message: `Nachricht`,
    submit_text: `Absenden`,
    onboarding_title_1: `Vervollständige das Angebot und erhalte Belohnungen`,
    onboarding_title_2: `Überprüfe den Status des Angebots`,
    onboarding_title_3: `Fehlende Belohnungen?`,
    offer_instructions: `Anleitung für das Angebot`,
    offer_details: `*Es kann mehrere Tage dauern, bis Belohnungen in deinem Konto erscheinen`,
    next_text: `Weiter`,
    done_text: `Fertig`,
    onboarding_page2_text1: `Öffne das Menü oben links`,
    onboarding_page2_text2: `Tippe auf "Status", um alle deine Angebote anzuzeigen`,
    onboarding_page2_text3: `Finde dein Angebot und tippe darauf, um Details zu öffnen`,
    onboarding_page3_text1: `Finde das Angebot und tippe auf "Problem melden"`,
    onboarding_page3_text2: `Fülle das Formular aus und sende dein Problem ab`,
    onboarding_page3_text3: `Unser Support-Team wird sich bei dir melden!`,
    reengagement_earn_up: `Verdiene bis zu <1>+{{ currencyValue }}</1> {{ currencyName }}`,
    reengagement_description: `Du hast immer noch laufende Angebote! <1></1> Es ist nicht zu spät, sie abzuschließen`,
    reengagement_status_text: `Spiele weiter`,
    reengagement_expires1: `Läuft ab in {{ days}} Tagen`,
    reengagement_expires2: `Läuft ab in {{ hours }} Stunden`,
    reengagement_button_text: `ALLE ANGEBOTE ANZEIGEN`,
    modal_reward: `Gesamtbelohnungen`,
    modal_task: `Gesamtaufgaben`,
    modal_time: `Verbleibende Tage zur Fertigstellung`,
    modal_tasks_completed: `Abgeschlossene Aufgaben`,
    modal_remaining_days: `Verbleibende Tage übrig`,
    modal_tasks_text: `Aufgaben`,
    modal_instructions_text: `Anweisungen`,
    complete_offer: `Angebot abschließen`,
    report_button_text: `Probleme? Hier klicken`,
    report_timestamp: `Sie können dieses Angebot in {{ reportTime }} melden`,
    menu_user_support_guide: `Benutzer-Support-Leitfaden`,
    banner_support: `Unterstützung`,
    mr_text_earned: `Verdient`,
    mr_text_total: `Gesamt`,
    earn_up_to: `Verdienen Sie bis zu {{ currencyValue }} {{ currency }}`,
    earn_text: `Verdienen Sie {{ currencyValue }} {{ currency }}`,
    up_to_text: `Bis zu`,
    tab_1_label: `Gut bezahlte Angebote`,
    tab_2_label: `Einfache Angebote`,
  },
}
