import { Client } from 'types/url'
import { DataCommon, DataMBE, QueryMBE } from '../types'

type Data = DataCommon & DataMBE

export default function mbe({ query, ...extras }: Data): QueryMBE {
  const language = navigator.language.split('-')[0].toLocaleLowerCase()
  return {
    a: query.appid,
    ad_format: 'offer',
    client: query.client as Client,
    i: 7,
    l: language,
    o: '',
    platform: query.platform,
    request_id: extras.requestID,
    u: query.uid,
    timestamp: Date.now(),
    session_id: query.session_id,
    sdk_version: query.sdk_version,
    os_version: query.os_version,
    network_connection_type: query.network_connection_type,
    m: extras.metric,
    s: extras.duration,
    details: extras.details,
    nc: 0,
  }
}
