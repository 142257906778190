import { useGetOffers, useGetTabsOffers } from 'api/offer/hooks'
import useExperiment from 'hooks/useExperiment'
import { useEffect } from 'preact/hooks'
import { useStore } from 'store'
import { MbeStatus } from '../types'
import useTracking from './useTracking'

const METRICS = [
  'HTML_LOADED',
  'DOM_LOADED_EVENT',
  'ON_LOAD_EVENT',
  'MAIN_BUNDLE_LOADED',
  'MAIN_STYLE_LOADED',
  'LOAD_FINISHED',
  'OFFERS_LOADED',
]

interface MbeData {
  metric: string
  duration: number
  details?: MbeStatus
}

export default function useEventTracking() {
  const { mbePixelsFired, setMBEPixelsFired } = useStore()
  const { injectPixel } = useTracking()

  const isTabs = useExperiment({
    experimentName: 'tab_switch',
    variantA: () => false,
    variantB: () => true,
  })()

  const { isSuccess } = !isTabs
    ? useGetOffers({ enabled: false })
    : useGetTabsOffers({ enabled: false })

  const details = isSuccess ? 'success' : 'failure'

  useEffect(() => {
    if (mbePixelsFired) {
      return
    }

    const metricsCollected = METRICS.every(
      (metric) => performance.getEntriesByName(metric).length > 0
    )

    if (metricsCollected) {
      setMBEPixelsFired(true)
      trackEvents()
    }
  })

  function trackEvents() {
    // @ts-expect-error: modified in metrics.js
    if (performance.supported === false) {
      return
    }

    for (const metric of METRICS) {
      const entry = performance.getEntriesByName(metric)[0]
      if (entry) {
        const duration = entry.duration / 1000
        const mbeData: MbeData = { metric, duration }
        if (metric === 'OFFERS_LOADED') {
          mbeData.details = details
        }
        injectPixel('MBE', mbeData)
      }
    }
  }

  useEffect(() => {
    // The application has been mounted and ready to intereact
    performance.measure('MAIN_STYLE_LOADED')
    performance.measure('MAIN_BUNDLE_LOADED')
  }, [])
}
