import { Client } from 'types/url'
import { getDeviceParameters } from 'utils/getDeviceParameters'
import { DataCommon, DataTAB } from '../types'

type Data = DataCommon & DataTAB

export default function exit(data: Data) {
  const { query, attributes, config, requestID, fromTab, toTab } = data

  const { deviceId, deviceType } = getDeviceParameters(query)

  return {
    appid: query.appid,
    uid: query.uid,
    experiments: JSON.stringify(config.activeExperiments),
    client: query.client as Client,
    integration: attributes?.integration,
    country_code: attributes.country,
    request_id: requestID,
    request_timestamp: attributes?.requestTimestamp,
    device_id: deviceId,
    device_id_type: deviceType,
    sdk_version: query.sdk_version,
    os_version: query.os_version,
    platform: query.platform,
    placement_id: attributes?.placementId,
    nc: 0,
    from_tab: fromTab,
    to_tab: toTab,
  }
}
