import { useInfiniteQuery } from '@tanstack/react-query'
import useGetCurrentPage from 'hooks/useGetCurrentPage'
import { useStore } from 'store'
import { useRouterStore } from 'store/router'
import OfferService from './service'

interface UseGetOffersOptions {
  enabled: boolean
}

export function useGetOffers({ enabled = false }: UseGetOffersOptions) {
  const { requestID } = useStore()
  const { query } = useRouterStore()
  const { currentPage } = useGetCurrentPage()
  const { client } = query

  const getNextPageParam = ({ pagination }) => {
    const { currentPage, totalPages } = pagination
    if (currentPage < totalPages) {
      return currentPage + 1
    }
    return false
  }

  return useInfiniteQuery(
    ['offers'],
    ({ pageParam = 1 }) =>
      OfferService.getAll(query as any, pageParam, requestID as string),
    {
      getNextPageParam: (lastPage) => getNextPageParam(lastPage),
      retry: 5,
      retryDelay: 2000,
      staleTime: Infinity,
      enabled: client !== 'api' && enabled && currentPage === 'home',
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )
}

interface GetTabsOffersOptions {
  currentTab?: string
  enabled?: boolean
}

export function useGetTabsOffers({
  currentTab = 'tab_1',
  enabled = false,
}: GetTabsOffersOptions) {
  const { requestID } = useStore()
  const { query } = useRouterStore()
  const { currentPage } = useGetCurrentPage()
  const { client } = query

  const getNextPageParamTabOne = ({ pagination }) => {
    const { tabOneCurrentPage, tabOneTotalPages } = pagination
    return tabOneCurrentPage < tabOneTotalPages ? tabOneCurrentPage + 1 : false
  }

  const getNextPageParamTabTwo = ({ pagination }) => {
    const { tabTwoCurrentPage, tabTwoTotalPages } = pagination
    return tabTwoCurrentPage < tabTwoTotalPages ? tabTwoCurrentPage + 1 : false
  }

  const tabKey = currentTab === 'tab_1' ? 'tab_1_page' : 'tab_2_page'

  return useInfiniteQuery(
    [tabKey],
    ({ pageParam = 1 }) =>
      OfferService.getAll(query as any, pageParam, requestID as string, tabKey),
    {
      getNextPageParam: (lastPage) =>
        currentTab === 'tab_1'
          ? getNextPageParamTabOne(lastPage)
          : getNextPageParamTabTwo(lastPage),
      retry: 10,
      retryDelay: 2000,
      staleTime: Infinity,
      enabled: client !== 'api' && enabled && currentPage === 'home',
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )
}
