export function getDeviceParameters(urlQuery) {
  let deviceId = ''
  let deviceType = 'none'

  if (urlQuery['apple_idfa']) {
    deviceId = urlQuery['apple_idfa'] as string
    deviceType = 'apple_idfa'
  }

  if (urlQuery['google_ad_id']) {
    deviceId = urlQuery['google_ad_id'] as string
    deviceType = 'google_ad_id'
  }

  if (urlQuery['android_id']) {
    deviceId = urlQuery['android_id'] as string
    deviceType = 'android_id'
  }

  return { deviceId, deviceType }
}
