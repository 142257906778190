export const LoaderContainer = ({ children }) => (
  <div className="w-full min-h-[64px] p-4">{children}</div>
)

export const Loader = ({ size }: { size?: number }) => (
  <div
    className={`m-auto`}
    style={{
      backgroundImage: `url(/images/loader.svg)`,
      width: `${size || 71}px`,
      height: `${size || 71}px`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
    }}
  />
)

export const CenteredLoaderContainer = ({ minHeight, children }) => (
  <div className={`grid place-items-center min-h-[${minHeight}px]`}>
    {children}
  </div>
)
