import { QueryBase } from 'api/tracking/types'

type Data = any

export default function event(event: string, data: Data): QueryBase & any {
  return {
    event,
    ...data,
  }
}
