import { getDeviceParameters } from 'utils/getDeviceParameters'
import { DataAT, DataCommon, QueryAT } from '../types'

type Data = DataCommon & DataAT

export default function at(data: Data): QueryAT {
  const {
    query,
    attributes,
    config,
    pagination,
    currencySale,
    offer,
    requestID,
    tabCurrentPage = null,
    tabName,
  } = data

  const { deviceId, deviceType } = getDeviceParameters(query)

  return {
    a: query.appid,
    client: query.client,
    device_id: deviceId,
    device_id_type: deviceType,
    manufacturer: query.manufacturer,
    os_version: query.os_version,
    phone_model: query.phone_model,
    platform: query.platform,
    sdk_version: query.sdk_version,
    request_id: requestID,
    c: attributes?.country,
    i: attributes?.integration,
    l: attributes?.language,
    placement_id: attributes?.placementId,
    pub_app_category: attributes?.appCategory,
    publisher_id: attributes?.publisherId,
    request_timestamp: attributes?.requestTimestamp,
    u: attributes?.userId,
    uct: attributes?.userCreationTimestamp,
    uls: attributes?.userLifeStage,
    p: tabCurrentPage ?? pagination.currentPage,
    vcs_enabled: currencySale.isEnabled,
    experiments: JSON.stringify(config.activeExperiments),
    o: offer.id,
    d: offer.attributes?.position,
    g: offer.attributes?.pid,
    b: offer.attributes?.billingRelationId,
    dpe: offer.attributes?.dynamicPayoutEnabled,
    arid: offer.attributes?.advertiserId,
    amid: offer.attributes?.accountManagerId,
    cost_model: offer.attributes?.costModel,
    store_id: offer.attributes?.storeId,
    complexity: offer.complexity.text,
    complexity_value: offer.complexity.value,
    user_task_category_id: offer.attributes?.userTaskCategoryId,
    ad_app_category: offer.attributes?.appCategory,
    reward_amount: offer.reward.amount,
    reward_amount_pre_vcs: offer.reward.amountPreSales,
    creative_id: offer.creativeId ?? '',
    carrier_name: query.carrier_name,
    carrier_country: query.carrier_country,
    timezone_id: query.timezone_id,
    network_connection_type: query.network_connection_type,
    is_ignite_user: attributes?.userHasIgnite,
    tab_name: tabName ?? null,
    in_grace_period: offer.in_grace_period,
    one_dtid: query.one_dtid ?? null,
  }
}
