export const it = {
  translations: {
    action_complete_q_action: `Completa un'azione rapida`,
    action_complete_survey: `Completa un sondaggio veloce`,
    action_social_media: `Coinvolgimento sui social media`,
    action_simple_registration: `Effettua una registrazione semplice`,
    action_discover_new: `Scopri un nuovo servizio/prodotto`,
    action_mobile_sub: `Ottieni una speciale sottoscrizione mobile`,
    action_purchase: `Effettua un acquisto`,
    action_new_service: `Prova un nuovo servizio/prodotto`,
    action_complete_action: `Completa un'azione`,
    action_quick_quiz: `Rispondi a un quiz veloce`,
    action_donation: `Fai una donazione`,
    tags_free: `Gratis`,
    tags_limited_offer: `Offerta limitata`,
    tags_multi_reward: `Multi-Ricompensa`,
    tags_top_offer: `Migliore Offerta!`,
    tags_new_users: `Solo nuovi utenti`,
    up_to: `Fino a +{{ currencyValue }} {{ currency }}`,
    menu_faq: `FAQ`,
    menu_offers: `Offerte`,
    menu_status: `Stato`,
    menu_privacy: `Privacy`,
    status_pending: `In sospeso`,
    status_reported: `Segnalato`,
    status_expired: `Scaduto`,
    status_rejected: `Respinto`,
    status_completed: `Compito completato`,
    dtUserId: `Il tuo ID utente Digital Turbine è <1>{{ digitalTurbineId }}</1>`,
    complete_text: `Completo`,
    check_inbox: `Questa offerta è stata segnalata. Controlla la tua email per la risposta.`,
    contacted: `Grazie per aver contattato il nostro servizio clienti. Ti risponderemo il prima possibile`,
    description_report: `Grazie per aver contattato il team di supporto utente. Ci sono due motivi comuni per i problemi nel ricevere {{ currencyText }}</0><1>1. Requisiti dell'offerta non soddisfatti</1><2>Le app devono essere scaricate E avviate per completare l'offerta.</2><3>Tutti i dati devono essere inseriti correttamente e veramente.</3><4>Spesso è necessario convalidare il proprio indirizzo email tramite un link inviato alla tua email.</4><5>Molti partner pubblicitari consentono solo una transazione per persona o famiglia.</5><6>2. Motivi tecnici</6><7>Non avevi una connessione dati funzionante quando hai avviato un'app promossa.</7><8>Avevi già installato l'app promossa prima di avviare l'offerta.</8>`,
    problem_report: `Segnalazione di problema per {{ appName }}`,
    problem_type1: `Nessun credito ricevuto`,
    problem_type2: `Errore durante l'offerta`,
    problem_type3: `Altro`,
    email_confirmation: `Conferma la tua email`,
    full_name: `Nome completo`,
    message: `Messaggio`,
    submit_text: `Invia`,
    onboarding_title_1: `Completa l'offerta e ricevi premi`,
    onboarding_title_2: `Verifica lo stato dell'offerta`,
    onboarding_title_3: `Ricompense mancanti?`,
    offer_instructions: `Istruzioni dell'offerta`,
    offer_details: `*Alcune ricompense potrebbero richiedere alcuni giorni per apparire nell'account`,
    next_text: `Avanti`,
    done_text: `Fatto`,
    onboarding_page2_text1: `Apri il menu situato nell'angolo in alto a sinistra`,
    onboarding_page2_text2: `Tocca "Stato" per visualizzare tutte le tue offerte`,
    onboarding_page2_text3: `Trova la tua offerta e tocca per aprire i dettagli`,
    onboarding_page3_text1: `Trova l'offerta e tocca "Segnala problema"`,
    onboarding_page3_text2: `Compila il modulo e invia il tuo problema`,
    onboarding_page3_text3: `Il nostro team di supporto ti risponderà!`,
    reengagement_earn_up: `Guadagna fino a <1>+{{ currencyValue }}</1> {{ currencyName }}`,
    reengagement_description: `Hai ancora offerte in corso! <1></1> Non è troppo tardi per completarle`,
    reengagement_status_text: `Continua a giocare`,
    reengagement_expires1: `Scade tra {{ days }} giorni`,
    reengagement_expires2: `Scade tra {{ hours }} ore`,
    reengagement_button_text: `VEDI TUTTE LE OFFERTE`,
    modal_reward: `Ricompense Totali`,
    modal_task: `Compiti Totali`,
    modal_time: `Giorni per completare`,
    modal_tasks_completed: `Compiti completati`,
    modal_remaining_days: `Giorni rimasti`,
    modal_tasks_text: `Compiti`,
    modal_instructions_text: `Istruzioni`,
    complete_offer: `Completa l'Offerta`,
    report_button_text: `Hai problemi? Clicca qui`,
    report_timestamp: `Potrai segnalare questa offerta in {{ reportTime }}`,
    menu_user_support_guide: `Guida di Supporto per l'Utente`,
    banner_support: `Supporto`,
    mr_text_earned: `Guadagnato`,
    mr_text_total: `Totale`,
    earn_up_to: `Guadagna fino a {{ currencyValue }} {{ currency }}`,
    earn_text: `Guadagna {{ currencyValue }} {{ currency }}`,
    up_to_text: `Fino a`,
    tab_1_label: `Offerte ben pagate`,
    tab_2_label: `Offerte facili`,
  },
}
