import { http, HttpResponse } from 'msw'
import { transactionResponse } from './data'

const transactionHandlers = [
  http.get('*/transactions', () => {
    return HttpResponse.json(transactionResponse)
  }),
]

export default transactionHandlers
