export function TimeIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 6.75997V1.99997H6.5V6.75997C6.49959 7.28541 6.6027 7.80579 6.80346 8.29137C7.00421 8.77695 7.29866 9.21822 7.67 9.58997L10.09 12L7.67 14.41C6.92555 15.1644 6.50565 16.1801 6.5 17.24V22H19.5V17.24C19.4944 16.1801 19.0745 15.1644 18.33 14.41L15.91 12L18.33 9.58997C18.7013 9.21822 18.9958 8.77695 19.1965 8.29137C19.3973 7.80579 19.5004 7.28541 19.5 6.75997ZM8.5 3.99997H17.5V5.99997H8.5V3.99997ZM16.91 15.83C17.2856 16.2033 17.4978 16.7104 17.5 17.24V20H8.5V17.24C8.50223 16.7104 8.71441 16.2033 9.09 15.83L11.85 13.06L11.9 13H14.1L14.15 13.06L16.91 15.83ZM14.15 10.94L14.1 11H11.9L11.85 10.94L9.09 8.16997C9.03483 8.11808 8.98462 8.06117 8.94 7.99997H17.06C17.0154 8.06117 16.9652 8.11808 16.91 8.16997L14.15 10.94Z"
        fill="var(--orange-500)"
      />
    </svg>
  )
}
