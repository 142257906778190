export const ar = {
  translations: {
    action_complete_q_action: `أكمل إجراءً سريعًا`,
    action_complete_survey: `أكمل استطلاعًا سريعًا`,
    action_social_media: `مشاركة وسائل التواصل الاجتماعي`,
    action_simple_registration: `قم بتسجيل بسيط`,
    action_discover_new: `اكتشف خدمة/منتج جديد`,
    action_mobile_sub: `احصل على اشتراك محمول خاص`,
    action_purchase: `قم بعملية شراء`,
    action_new_service: `جرب خدمة/منتج جديد`,
    action_complete_action: `أكمل إجراءً`,
    action_quick_quiz: `أجب على استطلاع سريع`,
    action_donation: `تبرّع`,
    tags_free: `مجاني`,
    tags_limited_offer: `عرض محدود`,
    tags_multi_reward: `مكافأة متعددة`,
    tags_top_offer: `أفضل عرض!`,
    tags_new_users: `للمستخدمين الجدد فقط`,
    up_to: `حتى {{ currency }}`,
    menu_faq: `الأسئلة الشائعة (FAQ)`,
    menu_offers: `العروض`,
    menu_status: `الحالة`,
    menu_privacy: `الخصوصية`,
    status_pending: `معلق`,
    status_reported: `تم الإبلاغ`,
    status_expired: `انتهت`,
    status_rejected: `تم رفضه`,
    status_completed: `المهمة المكتملة`,
    dtUserId: `معرّف مستخدم Digital Turbine الخاص بك هو <1>{{ digitalTurbineId }}</1>`,
    complete_text: `اكتمل`,
    check_inbox: `تم الإبلاغ عن هذا العرض. يرجى التحقق من بريدك الإلكتروني للرد.`,
    contacted: `شكرًا لاتصالك بخدمة العملاء لدينا. سنعود إليك في أقرب وقت ممكن`,
    description_report: `شكرًا لتواصلك مع فريق دعم المستخدم. هناك سببان شائعان لمشاكل استلام {{ currencyText }}.</0><1>1. عدم تحقيق متطلبات العرض</1><2>يجب تنزيل التطبيقات وتشغيلها لاستكمال العرض.</2><3>يجب إدخال جميع البيانات بشكل صحيح وصادق.</3><4>في كثير من الأحيان، يجب عليك التحقق من عنوان البريد الإلكتروني الخاص بك من خلال رابط يتم إرساله إلى بريدك الإلكتروني.</4><5>العديد من شركاء الإعلانات يسمحون فقط بإجراء صفقة واحدة لكل شخص أو أسرة.</5><6>2. أسباب تقنية</6><7>لم يكن لديك اتصال بيانات عامل عند بدء تشغيل تطبيق تم الترويج له.</7><8>كان التطبيق المروج له مثبتًا بالفعل قبل بدء العرض.</8>`,
    problem_report: `تقرير المشكلة بالنسبة لتطبيق {{ appName }}`,
    problem_type1: `لم يتم استلام الرصيد`,
    problem_type2: `خطأ أثناء العرض`,
    problem_type3: `غير ذلك`,
    email_confirmation: `التأكد من البريد الالكتروني`,
    full_name: `الاسم الكامل`,
    message: `الرسالة`,
    submit_text: `إرسال`,
    onboarding_title_1: `أكمل العرض واستلم الجوائز`,
    onboarding_title_2: `تحقق من حالة العرض`,
    onboarding_title_3: `المكافآت المفقودة؟`,
    offer_instructions: `تعليمات العرض`,
    offer_details: `*قد تستغرق بعض الجوائز عدة أيام حتى تظهر في الحساب`,
    next_text: `التالي`,
    done_text: `تم`,
    onboarding_page2_text1: `افتح القائمة الموجودة في الزاوية اليسرى العليا`,
    onboarding_page2_text2: `اضغط على "الحالة" لعرض جميع عروضك`,
    onboarding_page2_text3: `ابحث عن عرضك واضغط لفتح التفاصيل`,
    onboarding_page3_text1: `ابحث عن العرض واضغط على "الإبلاغ عن مشكلة"`,
    onboarding_page3_text2: `املأ الاستمارة وقدم مشكلتك`,
    onboarding_page3_text3: `سيقوم فريق الدعم بالرد عليك!`,
    reengagement_earn_up: `اربح ما يصل إلى <1>+{{ currencyValue }}</1> {{ currencyName }}`,
    reengagement_description: `لديك لا تزال عروض قيد التنفيذ! <1></1> ليس متأخرًا لاستكمالها`,
    reengagement_status_text: `واصل اللعب`,
    reengagement_expires1: `تنتهي خلال {{ days }} أيام`,
    reengagement_expires2: `تنتهي خلال {{ hours }} ساعة`,
    reengagement_button_text: `عرض جميع العروض`,
    modal_reward: `إجمالي الجوائز`,
    modal_task: `إجمالي المهام`,
    modal_time: `أيام لاستكمال`,
    modal_tasks_completed: `المهام المكتملة`,
    modal_remaining_days: `الأيام المتبقية`,
    modal_tasks_text: `مهام`,
    modal_instructions_text: `تعليمات`,
    complete_offer: `اكمل العرض`,
    report_button_text: `هل تواجه مشكلات؟ انقر هنا`,
    report_timestamp: `ستتمكن من الإبلاغ عن هذا العرض في غضون {{ reportTime }}`,
    menu_user_support_guide: `دليل دعم المستخدم`,
    banner_support: `الدعم`,
    mr_text_earned: `تم الحصول`,
    mr_text_total: `الإجمالي`,
    earn_up_to: `اكسب حتى {{ currencyValue }} {{ currency }}`,
    earn_text: `اكسب {{ currencyValue }} {{ currency }}`,
    up_to_text: `حتى `,
    tab_1_label: `عروض ذات دفع عالي`,
    tab_2_label: `عروض سهلة`,
  },
}
