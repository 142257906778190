import { useStore } from 'store'
import { clamp } from 'utils/clamp'
import { CloseIcon } from '../icons'
import { Loader } from '../loader'
import NoOffers from '../NoOffers'

interface ApiErrorFallbackUIProps {
  bannerHeight: number
  scrollTopInfinite: number
  isError: boolean
  defaultHeader?: boolean
  isFetching?: boolean
}

function ApiErrorFallbackUI({
  bannerHeight,
  scrollTopInfinite,
  isError,
  defaultHeader = false,
  isFetching = false,
}: ApiErrorFallbackUIProps) {
  const { setExitPixelSource } = useStore()
  return (
    <div style={{ backgroundColor: '#ffffff' }} data-cy="fallback-error-ui">
      {defaultHeader && (
        <header className={'defaultBanner flex items-center justify-end'}>
          <a
            className={`flex justify-center items-center p-4 w-[50px] h-full z-[2]`}
            data-cy="exit_button"
            onClick={() => {
              setExitPixelSource('button')
              setTimeout(() => {
                window.location.href = 'sponsorpay://exit?status=1'
              }, 0)
            }}
          >
            <CloseIcon />
          </a>
        </header>
      )}
      <div
        style={{
          background: '#fff',
          paddingBottom: 16,
          marginTop: !defaultHeader
            ? clamp(47, bannerHeight - scrollTopInfinite, bannerHeight)
            : 0,
          height:
            document.documentElement.clientHeight -
            clamp(47, bannerHeight - scrollTopInfinite, bannerHeight),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {isError && <NoOffers text="No offers available" />}
        <div
          style={{
            position: 'absolute',
            top: clamp(47, bannerHeight - scrollTopInfinite, bannerHeight),
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            pointerEvents: 'auto',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {isFetching && <Loader size={32} />}
        </div>
      </div>
    </div>
  )
}

export default ApiErrorFallbackUI
