import { getDeviceParameters } from 'utils/getDeviceParameters'
import { DataCommon, DataNAV, QueryNAV } from '../types'

type Data = DataCommon & DataNAV

export default function nav(data: Data): QueryNAV {
  const {
    query,
    attributes,
    config,
    currencySale,
    offer,
    eventType,
    requestID,
  } = data

  const { deviceId, deviceType } = getDeviceParameters(query)

  return {
    a: query.appid,
    client: query.client,
    device_id: deviceId,
    device_id_type: deviceType,
    manufacturer: query.manufacturer,
    os_version: query.os_version,
    phone_model: query.phone_model,
    platform: query.platform,
    sdk_version: query.sdk_version,
    request_id: requestID,
    c: attributes?.country,
    i: attributes?.integration,
    l: attributes?.language,
    p: 1,
    placement_id: attributes?.placementId,
    pub_app_category: attributes?.appCategory,
    publisher_id: attributes?.publisherId,
    request_timestamp: attributes?.requestTimestamp,
    u: attributes?.userId,
    uct: attributes?.userCreationTimestamp,
    uls: attributes?.userLifeStage,
    vcs_enabled: currencySale.isEnabled,
    experiments: JSON.stringify(config.activeExperiments),
    offer_id: offer?.id ?? 0,
    event_type: eventType,
  }
}
