import { Config, ConfigDto } from './types'

export function configFromDto(dto: ConfigDto): Config {
  return {
    activeExperiments: dto.active_experiments,
    mainColor: dto.main_color,
    colors: dto.colors,
    bannerMobileUrl:
      dto.custom_banner_mobile === '' ? null : dto.custom_banner_mobile,
    bannerTabletUrl:
      dto.custom_banner_tablet === '' ? null : dto.custom_banner_tablet,
    vcsEnabled: dto.virtual_currency_sale_enabled,
    vcsTimeLeft: dto.vcs_time_left,
    currencyIcon: dto.currency_icon,
    currencyName: dto.currency_name,
    rewardMultiplier: dto.virtual_currency_sale_reward_multiplier,
    showOnboarding: dto.show_onboarding,
  }
}
