export function MenuIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6592 2.8418H3.33887C2.79102 2.8418 2.34277 3.29004 2.34277 3.84082C2.34277 4.3916 2.79102 4.83984 3.3418 4.83984H11.6592C12.207 4.83984 12.6582 4.3916 12.6582 3.84082C12.6582 3.29004 12.207 2.8418 11.6592 2.8418Z"
        fill="white"
      />
      <path
        d="M11.6602 6.50098H3.33984C2.79199 6.50098 2.34375 6.95215 2.34375 7.5C2.34375 8.04785 2.79199 8.49902 3.34277 8.49902H11.6602C12.208 8.49902 12.6592 8.05078 12.6592 7.5C12.6592 6.94922 12.208 6.50098 11.6602 6.50098Z"
        fill="white"
      />
      <path
        d="M11.6602 10.1631H3.33984C2.79199 10.1631 2.34082 10.6113 2.34082 11.1621C2.34082 11.7129 2.79199 12.1582 3.33984 12.1582H11.6602C12.208 12.1582 12.6592 11.71 12.6592 11.1592C12.6592 10.6084 12.208 10.1631 11.6602 10.1631Z"
        fill="white"
      />
    </svg>
  )
}
