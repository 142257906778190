import useViewport from 'hooks/useViewport'
import { useMemo } from 'preact/hooks'
import { Transition } from 'react-transition-group'

const duration = 300

const defaultStyle = {
  transition: `top ${duration}ms ease-out`,
  position: 'absolute',
}

export function SlideIn({ in: inProp, children, offers }) {
  const { isTablet } = useViewport({ registerEventListener: true })

  const transitionStyles = useMemo(
    () =>
      !isTablet && {
        entering: {
          top: offers > 2 && 'min(450px, 60%)',
          bottom: offers < 3 && '0px',
        },
        entered: {
          top: offers > 2 && 'min(450px, 60%)',
          bottom: offers < 3 && '0px',
        },
        exiting: { top: offers > 2 && '100%' },
        exited: { top: offers > 2 && '100%' },
      },
    [isTablet, offers]
  )

  return (
    <Transition in={inProp} timeout={duration}>
      {(state) => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  )
}
