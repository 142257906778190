import { Client } from 'types/url'
import { getDeviceParameters } from 'utils/getDeviceParameters'
import { DataCommon, DataEXIT } from '../types'

type Data = DataCommon & DataEXIT

export default function exit(data: Data) {
  const { query, attributes, config, requestID, duration, exit_source } = data

  const { deviceId, deviceType } = getDeviceParameters(query)

  return {
    appid: query.appid,
    uid: query.uid,
    experiments: JSON.stringify(config.activeExperiments),
    duration,
    exit_source,
    client: query.client as Client,
    integration: attributes?.integration,
    country_code: attributes.country,
    request_id: requestID,
    request_timestamp: attributes?.requestTimestamp,
    device_id: deviceId,
    device_id_type: deviceType,
    sdk_version: query.sdk_version,
    os_version: query.os_version,
    platform: query.platform,
    placement_id: attributes?.placementId,
    nc: 0,
  }
}
