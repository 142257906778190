import styles from './styles.module.css'

const NoOffers = ({ text }) => {
  return (
    <div className={styles.container}>
      <img
        data-cy="img-no-offers"
        className={styles.icon}
        src="/images/no-offers.png"
        alt=""
      />
      <p className={styles.description}>{text}</p>
    </div>
  )
}

export default NoOffers
