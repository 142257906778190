interface Props {
  children: any
  style?: any
  onClick?: () => any
  color?: string
  borderRadius?: string
}

export const Button = ({
  children,
  style,
  color,
  borderRadius,
  ...props
}: Props) => (
  <button
    {...props}
    style={{
      ...style,
      borderRadius: borderRadius ?? '35px',
      background: color ?? 'var(--red-500)',
    }}
    className={`flex justify-center items-center h-[45px] px-10 py-0 text-white text-base font-semibold rounded-[30px] landscape:w-full md:h-[70.5px] md:text-[21px] md:font-semibold md:leading-[30px] md:text-center md:rounded-[35px]`}
  >
    {children}
  </button>
)
