import { LinkIcon } from 'components/shared/icons'
import Match from 'preact-router/match'
import { useTranslation } from 'react-i18next'
import { SwipeEventData, useSwipeable } from 'react-swipeable'
import { useMenuStore } from 'store/menu'
import { useRouterStore } from 'store/router'
import styles from './styles.module.css'
interface Props {
  isOpen: boolean
  color: string
  onOpen: () => void
  onClose: () => void
}

const PRIVACY_URL = 'https://fyber.com/services-privacy-statement/'
const FAQ_URL =
  'https://developer.digitalturbine.com/hc/en-us/articles/360015015678-FAQs-Multi-Language'

export function Menu({ isOpen, color, onOpen, onClose }: Props) {
  const { t } = useTranslation()
  const { ref: swipeRef } = useSwipeable({ onSwiped })
  const setOpenOnboarding = useMenuStore((store) => store.setOpenOnboarding)

  function onSwiped({ dir }: SwipeEventData) {
    if (dir === 'Right') {
      onOpen()
    }

    if (dir === 'Left') {
      onClose()
    }
  }

  const { query, pathname } = useRouterStore()

  const hideNavbar = ['/support', '/preview', '/placement-preview'].includes(
    pathname
  )
  const isSDK = query?.client === 'sdk'

  function generateLinearGradiant(color) {
    return `linear-gradient(135deg, ${color} 0%, ${'#222'} 100%)`
  }

  let privacyUrl = PRIVACY_URL
  let faqUrl = FAQ_URL

  if (isSDK) {
    privacyUrl = `sponsorpay://exit?url=${PRIVACY_URL}`
    faqUrl = `sponsorpay://exit?url=${FAQ_URL}`
  }

  return (
    <nav ref={swipeRef} style={{ display: hideNavbar ? 'none' : 'block' }}>
      <div
        className={styles.sideMenu}
        style={{
          left: isOpen ? 0 : '-75%',
          background: generateLinearGradiant(color),
          boxShadow: isOpen ? '2px 0px 16px -4px rgba(0, 0, 0, 0.75)' : 'none',
        }}
      >
        {isSDK && (
          <>
            <a
              href={`/mobile${window.location.search}`}
              className={styles.link}
              onClick={() => onClose()}
            >
              <Match path="/mobile">
                {({ matches }) =>
                  matches && (
                    <div
                      className={styles.linkActive}
                      style={{ background: generateLinearGradiant(color) }}
                    />
                  )
                }
              </Match>

              <div className={styles.linkText}>{t('menu_offers')}</div>
            </a>

            <a
              className={styles.link}
              href={`/status${window.location.search}`}
              onClick={() => onClose()}
            >
              <Match path="/status">
                {({ matches }) =>
                  matches && (
                    <div
                      className={styles.linkActive}
                      style={{ background: generateLinearGradiant(color) }}
                    />
                  )
                }
              </Match>

              <div className={styles.linkText}>{t('menu_status')}</div>
            </a>
          </>
        )}

        <a
          className={styles.link}
          onClick={() => {
            setOpenOnboarding(true)
            onClose()
          }}
        >
          <div className={styles.linkText}>{t('menu_user_support_guide')}</div>
        </a>

        <a className={styles.link} href={faqUrl}>
          <div className={styles.linkText}>{t('menu_faq')}</div>
          <LinkIcon />
        </a>

        <a className={styles.link} href={privacyUrl}>
          <div className={styles.linkText}>{t('menu_privacy')}</div>
          <LinkIcon />
        </a>
      </div>

      <div ref={swipeRef} className={styles.swipeArea} />
      <div
        className={styles.overlay}
        style={{ opacity: isOpen ? 1 : 0, zIndex: isOpen ? 998 : -1 }}
        onClick={onClose}
      />
    </nav>
  )
}
