import { useGetConfig } from 'api/config/hooks'
import { OfferTag } from 'api/offer/types'
import {
  EasyTagIcon,
  FreeIcon,
  HardTagIcon,
  MediumTagIcon,
  NewUsersIcon,
  TopOfferIcon,
} from 'components/shared/icons'
import { MultiRewardIcon } from 'components/shared/icons/tags/MultiRewardIcon'
import { Size } from 'types/misc'
interface Props {
  type: OfferTag
  size?: Size
}

function Tag({ type, size }: Props) {
  const { data: config } = useGetConfig({ enabled: false })

  function getTagDesign() {
    switch (type) {
      case 'easy':
        return <EasyTagIcon />
      case 'medium':
        return <MediumTagIcon />
      case 'hard':
        return <HardTagIcon />
      case 'free':
        return <FreeIcon color={config?.colors.tag_color} />
      case 'multi-rewards':
        return <MultiRewardIcon color={config?.colors.tag_color} />
      case 'new-users-only':
        return <NewUsersIcon color={config?.colors.tag_color} />
      case 'top-offer':
        return (
          <div
            style={{
              position: size === 'md' ? 'unset' : 'absolute',
              top: 12,
              right: 12,
            }}
          >
            <TopOfferIcon color={config?.colors.tag_color} />
          </div>
        )

      default:
        return null
    }
  }

  return getTagDesign()
}

export default Tag
