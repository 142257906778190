import * as React from 'react'
import { worker } from 'tests/mocks/server'
import { environment } from 'utils/environment'

export function useMockServiceWorker() {
  const [hasWorkerStarted, setWorkerStarted] = React.useState(false)
  if (['development', 'test'].includes(environment)) {
    React.useEffect(() => {
      if (typeof window === 'object') {
        worker.start().then(() => {
          setWorkerStarted(true)
        })
      } else {
        setWorkerStarted(true)
      }
    }, [])

    return hasWorkerStarted
  }
  return true
}
