export function InstallIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-full h-full"
    >
      <path
        d="M9.9493 12.4794H4.0507C3.71802 12.4791 3.39907 12.3467 3.16382 12.1115C2.92858 11.8763 2.79626 11.5573 2.7959 11.2246V7.58954C2.7959 7.50977 2.82759 7.43326 2.884 7.37685C2.9404 7.32045 3.01691 7.28876 3.09668 7.28876C3.17645 7.28876 3.25296 7.32045 3.30936 7.37685C3.36577 7.43326 3.39746 7.50977 3.39746 7.58954V11.2246C3.39761 11.3978 3.46644 11.5638 3.58885 11.6863C3.71127 11.8087 3.87727 11.8776 4.05043 11.8779H9.9493C10.1225 11.8776 10.2885 11.8087 10.4109 11.6863C10.5333 11.5638 10.6021 11.3978 10.6023 11.2246V9.13282C10.6023 9.05305 10.634 8.97654 10.6904 8.92014C10.7468 8.86373 10.8233 8.83204 10.903 8.83204C10.9828 8.83204 11.0593 8.86373 11.1157 8.92014C11.1721 8.97654 11.2038 9.05305 11.2038 9.13282V11.2246C11.2035 11.5573 11.0712 11.8762 10.836 12.1114C10.6008 12.3466 10.2819 12.479 9.9493 12.4794V12.4794Z"
        fill="#201F66"
      />
      <path
        d="M11.5749 7.7911H2.42512C2.34535 7.7911 2.26884 7.75941 2.21243 7.703C2.15603 7.64659 2.12434 7.57009 2.12434 7.49032V5.55356C2.12434 5.47379 2.15603 5.39728 2.21243 5.34087C2.26884 5.28447 2.34535 5.25278 2.42512 5.25278H11.5749C11.6547 5.25278 11.7312 5.28447 11.7876 5.34087C11.844 5.39728 11.8757 5.47379 11.8757 5.55356V7.49032C11.8757 7.57009 11.844 7.64659 11.7876 7.703C11.7312 7.75941 11.6547 7.7911 11.5749 7.7911ZM2.72617 7.18953H11.2738V5.85461H2.72617V7.18953Z"
        fill="#201F66"
      />
      <path
        d="M7.00001 6.59642C6.95186 6.59656 6.90437 6.58513 6.86154 6.5631C6.81872 6.54107 6.78181 6.50908 6.75392 6.46982L4.70314 3.7956C4.67113 3.75054 4.65216 3.69753 4.64831 3.64239C4.64445 3.58725 4.65587 3.53212 4.68129 3.48304C4.70672 3.43397 4.74518 3.39285 4.79245 3.3642C4.83971 3.33555 4.89396 3.32048 4.94923 3.32064H6.06677V1.82138C6.06677 1.74161 6.09846 1.6651 6.15487 1.6087C6.21128 1.55229 6.28778 1.5206 6.36755 1.5206H7.63138C7.71115 1.5206 7.78766 1.55229 7.84407 1.6087C7.90047 1.6651 7.93216 1.74161 7.93216 1.82138V3.32064H9.0508C9.10607 3.32048 9.16032 3.33555 9.20758 3.3642C9.25485 3.39285 9.29331 3.43397 9.31874 3.48304C9.34416 3.53212 9.35558 3.58725 9.35172 3.64239C9.34787 3.69753 9.3289 3.75054 9.29689 3.7956L7.24611 6.46982C7.21822 6.50908 7.18131 6.54107 7.13849 6.5631C7.09566 6.58513 7.04817 6.59656 7.00001 6.59642V6.59642ZM5.5322 3.92247L7.00001 5.77583L8.46783 3.92247H7.63193C7.55216 3.92247 7.47565 3.89079 7.41924 3.83438C7.36284 3.77797 7.33115 3.70147 7.33115 3.62169V2.12216H6.66916V3.62142C6.66916 3.70119 6.63747 3.7777 6.58106 3.8341C6.52465 3.89051 6.44815 3.9222 6.36837 3.9222L5.5322 3.92247Z"
        fill="#201F66"
      />
    </svg>
  )
}
