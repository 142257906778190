export const pt = {
  translations: {
    action_complete_q_action: `Completa uma ação rápida`,
    action_complete_survey: `Completa uma pesquisa rápida`,
    action_social_media: `Interação em redes sociais`,
    action_simple_registration: `Realiza um registo simples`,
    action_discover_new: `Descobre um novo serviço/produto`,
    action_mobile_sub: `Obtém uma assinatura especial para telemóvel`,
    action_purchase: `Realiza uma compra`,
    action_new_service: `Experimenta um novo serviço/produto`,
    action_complete_action: `Completa uma ação`,
    action_quick_quiz: `Responde a um quiz rápido`,
    action_donation: `Faz uma doação`,
    tags_free: `Grátis`,
    tags_limited_offer: `Oferta limitada`,
    tags_multi_reward: `Multi-recompensa`,
    tags_top_offer: `Melhor oferta!`,
    tags_new_users: `Apenas para novos Usuários`,
    up_to: `Até +{{ currencyValue }} {{ currency }}`,
    menu_faq: `FAQ`,
    menu_offers: `Ofertas`,
    menu_status: `Estado`,
    menu_privacy: `Privacidade`,
    status_pending: `Pendente`,
    status_reported: `Relatado`,
    status_expired: `Expirado`,
    status_rejected: `Rejeitado`,
    status_completed: `Tarefa concluída`,
    dtUserId: `O teu ID de Usuário da Digital Turbine é <1>{{ digitalTurbineId }}</1>`,
    complete_text: `Concluir`,
    check_inbox: `Esta oferta foi relatada. Por favor, verifica o teu e-mail para obter resposta.`,
    contacted: `Obrigado por entrar em contato com a equipa de suporte ao usuário. Retornaremos o mais breve possível.`,
    description_report: `<0>Obrigado por entrar em contato com a equipa de suporte ao usuário. Existem duas razões comuns para problemas ao receber {{ currencyText }}</0><1>1. Requisitos da oferta não concluídos</1><2>As aplicaçōes precisam de ser descarregadas e inicializadas para concluir a oferta.</2><3>Todos os dados precisam de ser inseridos corretamente e com veracidade.</3><4>Muitas vezes, é necessário validar o teu endereço de e-mail por meio de um link enviado para o teu e-mail.</4><5>Muitos parceiros de publicidade permitem apenas uma transação por pessoa ou domicílio.</5><6>2. Razões técnicas</6><7>Não tinhas uma conexão de dados funcionando quando iniciaste a aplicação promovida.</7><8>Já tinhas a aplicação promovida instalada antes de iniciar a oferta.</8>`,
    problem_report: `Relatório de problema para {{ appName }}`,
    problem_type1: `Nenhum crédito recebido`,
    problem_type2: `Erro durante a oferta`,
    problem_type3: `Outro`,
    email_confirmation: `Confirma o teu e-mail`,
    full_name: `Nome completo`,
    message: `Mensagem`,
    submit_text: `Enviar`,
    onboarding_title_1: `Completa a oferta e recebe prémios`,
    onboarding_title_2: `Verifica o estado da oferta`,
    onboarding_title_3: `Estão faltando recompensas?`,
    offer_instructions: `Instruções da oferta`,
    offer_details: `*Algumas recompensas podem demorar alguns dias para aparecer na conta`,
    next_text: `Próximo`,
    done_text: `Concluído`,
    onboarding_page2_text1: `Abre o menu localizado no canto superior esquerdo`,
    onboarding_page2_text2: `Toca em "Estado" para ver todas as tuas ofertas participadas`,
    onboarding_page2_text3: `Encontra a tua oferta e toca para abrir os detalhes`,
    onboarding_page3_text1: `Encontra a oferta e toca em "Relatar Problema"`,
    onboarding_page3_text2: `Preenche o formulário e relata o teu problema`,
    onboarding_page3_text3: `A nossa equipa de assistência entrará em contato contigo!`,
    reengagement_earn_up: `Ganha até <1>+{{ currencyValue }}</1> {{ currencyName }}`,
    reengagement_description: `Ainda tens ofertas em processamento! <1></1> Ainda dá tempo de completá-las`,
    reengagement_status_text: `Continua jogando`,
    reengagement_expires1: `Expira em {{ days }} dias`,
    reengagement_expires2: `Expira em {{ hours }} horas`,
    reengagement_button_text: `VER TODAS AS OFERTAS`,
    modal_reward: `Recompensas Totais`,
    modal_task: `Tarefas Totais`,
    modal_time: `Dias para Concluir`,
    modal_tasks_completed: `Tarefas Concluídas`,
    modal_remaining_days: `Dias Restantes`,
    modal_tasks_text: `Tarefas`,
    modal_instructions_text: `Instruções`,
    complete_offer: `Concluir Oferta`,
    report_button_text: `Enfrentando problemas? Clica aqui`,
    report_timestamp: `Poderás relatar esta oferta em {{ reportTime }}`,
    menu_user_support_guide: `Guia de Assistência ao Usuário`,
    banner_support: `Assistência`,
    mr_text_earned: `Ganho`,
    mr_text_total: `Total`,
    earn_up_to: `Ganha até {{ currencyValue }} {{ currency }}`,
    earn_text: `Ganha {{ currencyValue }} {{ currency }}`,
    up_to_text: `Até`,
    tab_1_label: `Ofertas de Alto Pagamento`,
    tab_2_label: `Ofertas Fáceis`,
  },
}
