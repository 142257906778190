import { AssetType, AssetUrl } from 'api/offer/types'

/**
 * Returns [video, images] asset urls
 */
export function separateAssets(assetUrls: AssetUrl[]): [AssetUrl, AssetUrl[]] {
  let video
  const images = []

  for (const url of assetUrls) {
    if (getAssetType(url) === 'video') {
      video = url
    } else {
      images.push(url)
    }
  }

  return [video, images]
}

export function getAssetType(assetUrl: AssetUrl): AssetType {
  return assetUrl.raw.includes('.mp4') ? 'video' : 'image'
}
