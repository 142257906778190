import { create } from 'zustand'

interface VideoState {
  playing: boolean
  play: () => void
  pause: () => void

  muted: boolean
  mute: () => void
  unmute: () => void
}

export const useVideoStore = create<VideoState>((set) => ({
  playing: true, // autoplay
  play: () => set(() => ({ playing: true })),
  pause: () => set(() => ({ playing: false })),

  muted: false, // default unmuted
  mute: () => set(() => ({ muted: true })),
  unmute: () => set(() => ({ muted: false })),
}))
