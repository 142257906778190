import {
  Transition as ReactTransition,
  TransitionGroup,
} from 'react-transition-group'

type TransitionKind = {
  location: string
}

const duration = 350

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
  entering: {
    opacity: 0.5,
  },
  entered: {
    opacity: 1,
  },
  exiting: { opacity: 0 },

  exited: { opacity: 0 },
}

export const RouterTransition: React.FC<TransitionKind> = ({
  children,
  location,
}) => {
  return (
    <TransitionGroup>
      <ReactTransition key={location} timeout={200}>
        {(status) => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[status],
            }}
          >
            {children}
          </div>
        )}
      </ReactTransition>
    </TransitionGroup>
  )
}
