import { ReactElement } from 'react'
import styles from './styles.module.css'

interface Props {
  children: ReactElement[]
}

export function OfferInstruction({ children }: Props) {
  return <ol className={styles.instruction}>{children}</ol>
}

interface ItemProps {
  description: string
}

export function OfferInstructionItem({ description }: ItemProps) {
  return <li className={styles.instructionItem}>{description}</li>
}
