import { ActionType } from 'api/offer/types'
import {
  DonationIcon,
  FreeTrialIcon,
  InstallIcon,
  MobileIcon,
  PurchaseIcon,
  RegisterIcon,
  SurveyIcon,
} from 'components/shared/icons'
import { useTranslation } from 'react-i18next'
/**
 * Maps action type enum to text values. Returns in capital case.
 * @param type
 */

export function getActionText(type: ActionType): string {
  const { t } = useTranslation()

  switch (type) {
    case 'complete-a-quick-action':
      return t('action_complete_q_action')

    case 'complete-a-quick-survey':
      return t('action_complete_survey')

    case 'social-media-engagement':
      return t('action_social_media')

    case 'do-a-simple-registration':
      return t('action_simple_registration')

    case 'discover-a-new-service/product':
      return t('action_discover_new')

    case 'get-a-special-mobile-subscription':
      return t('action_mobile_sub')

    case 'make-a-purchase':
      return t('action_purchase')

    case 'try-a-new-service-product':
      return t('action_new_service')

    case 'complete-an-action':
      return t('action_complete_action')

    case 'answer-a-quick-quiz':
      return t('action_quick_quiz')

    case 'make-a-donation':
      return t('action_donation')

    default:
      throw new Error(`Unknown offer action type: ${type}`)
  }
}

export function getActionIcon(type: ActionType) {
  if (type === 'complete-a-quick-action') {
    return <InstallIcon />
  }

  if (type === 'complete-a-quick-survey') {
    return <SurveyIcon />
  }

  if (type === 'answer-a-quick-quiz') {
    return <SurveyIcon />
  }

  if (type === 'try-a-new-service-product') {
    return <FreeTrialIcon />
  }

  if (type === 'do-a-simple-registration') {
    return <RegisterIcon />
  }

  if (type === 'make-a-purchase') {
    return <PurchaseIcon />
  }

  if (type === 'make-a-donation') {
    return <DonationIcon />
  }

  return <MobileIcon />
}
