import { useGetConfig } from 'api/config/hooks'
import { Offer } from 'api/offer/types'
import { useTracking } from 'api/tracking/hooks'
import { route } from 'preact-router'
import { memo } from 'preact/compat'
import { useEffect, useState } from 'preact/hooks'
import { Trans, useTranslation } from 'react-i18next'
import { numericFormatter } from 'react-number-format'
import { useStore } from 'store'
import { Button } from '../shared/button'
import { ArrowDownIcon } from '../shared/icons'
import { Flex } from '../shared/layout'
import { SlideIn } from '../transitions'
import { ReengagementRow } from './ReengagementRow'
import styles from './styles.module.css'

interface Props {
  totalReward: number
  offers: Offer[]
  onClose?: () => void
}

const Reengagement = memo((props: Props) => {
  const [mounted, setMounted] = useState<boolean>(false)
  const { totalReward, offers, onClose } = props
  const { setTransactionId } = useStore()

  const { data: config } = useGetConfig({ enabled: false })

  const { injectPixel } = useTracking()

  const { t } = useTranslation()

  const currencyName = config?.currencyName
  const currencyValue = numericFormatter(totalReward.toString(), {
    displayType: 'text',
    thousandSeparator: true,
    decimalScale: 0,
  })

  useEffect(() => {
    injectPixel('NAV', { eventType: 'reengagement_impression' })
    setMounted(true)
  }, [])

  function handleRowClick(offer: Offer) {
    onClose()

    injectPixel('NAV', { offer, eventType: 'reengagement_row_click' })
    setTransactionId(offer.subId)
    route(`/status${window.location.search}`)
  }

  function handleButtonClick() {
    onClose()

    injectPixel('NAV', { eventType: 'reengagement_see_all_click' })
    route(`/status${window.location.search}`)
  }

  return (
    <SlideIn offers={offers.length} in={mounted}>
      <div className={styles.container} data-cy="reengagement">
        <div className={styles.dragArea}>
          <div className={styles.dragIcon} />
        </div>
        <div className={styles.body} style={{ gridArea: 'body' }}>
          <h1 className={styles.title}>
            <Trans i18nKey="reengagement_earn_up">
              Earn up to <b data-cy="amount">+{{ currencyValue }}</b>{' '}
              {{ currencyName }}
            </Trans>
          </h1>
          <p className={styles.text}>
            <Trans i18nKey="reengagement_description">
              You still have offers that are in progress!
              <br />
              It is not too late to complete them
            </Trans>
          </p>

          <Divider arrow />

          <Flex flexDirection="column" gap="16px">
            {offers?.map((offer) => (
              <ReengagementRow
                key={offer.subId}
                offer={offer}
                color={config?.colors.button_color}
                currencyIconUrl={config?.currencyIcon}
                onClick={() => handleRowClick(offer)}
              />
            ))}
          </Flex>

          <Divider />

          <Button
            data-cy="see-all-button"
            color={config?.colors.button_color}
            borderRadius="5px"
            onClick={handleButtonClick}
          >
            {t('reengagement_button_text')}
          </Button>
        </div>
      </div>
    </SlideIn>
  )
})

function Divider({ arrow }: { arrow?: boolean }) {
  if (arrow) {
    return (
      <Flex alignItems="center" gap="8px">
        <div className={styles.line} />
        <Flex>
          <ArrowDownIcon />
        </Flex>
        <div className={styles.line} />
      </Flex>
    )
  }
  return <div className={styles.line} />
}

export default Reengagement
