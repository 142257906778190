import { useGetConfig } from 'api/config/hooks'
import { Offer as OfferType } from 'api/offer/types'
import useGetCurrentPage from 'hooks/useGetCurrentPage'
import useViewport from 'hooks/useViewport'
import { memo } from 'preact/compat'
import { Trans } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { mFormatter } from 'utils/kFormatter'
import { RewardClipPath } from '../icons/RewardClipPath'
import rewardbutton from './rewardbutton.module.css'
interface Props {
  offer: OfferType
}

const RewardButton = memo(({ offer }: Props) => {
  const { currentPage } = useGetCurrentPage()
  const { data: config } = useGetConfig({ enabled: false })
  const { isTablet } = useViewport({ registerEventListener: false })
  const icon =
    currentPage === 'status' ? offer.reward?.icon : config?.currencyIcon

  const showUpToText =
    offer.multiPayout || offer.attributes?.dynamicPayoutEnabled

  const RewardText = () => {
    return offer.reward.amount > 999999 ? (
      <span>{mFormatter(offer.reward.amount)}</span>
    ) : (
      <NumericFormat
        displayType="text"
        value={offer.reward.amount}
        thousandSeparator={true}
        decimalScale={0}
      />
    )
  }

  if (config?.vcsEnabled && currentPage !== 'status') {
    return (
      <div
        className={rewardbutton.rewardVCSContainer}
        style={{
          background: config?.colors.button_color,
          color: config?.colors.button_text_color,
        }}
      >
        {['home', 'placementPreview'].includes(currentPage) && (
          <div
            className={rewardbutton.rewardStriked}
            style={{
              color: config?.colors.button_text_color,
            }}
          >
            <RewardClipPath isTablet={isTablet} color={'#251546'} />
            <NumericFormat
              class="relative z-[2] mt-[3px] leading-[10px]"
              displayType="text"
              value={offer.reward.amountPreSales}
              thousandSeparator={true}
              decimalScale={0}
            />
          </div>
        )}
        <div
          style={{
            background: config?.colors.button_color,
            color: config?.colors.button_text_color,
          }}
          className={rewardbutton.rewardVCS}
        >
          {icon ? (
            <img className={rewardbutton.currencyImage} src={icon} />
          ) : (
            '+'
          )}
          {showUpToText ? (
            <span
              className={rewardbutton.upToContainer}
              style={{
                color: config?.colors.button_text_color ?? 'var(--red-500)',
              }}
            >
              <span className={rewardbutton.upToText}>
                <Trans i18nKey="up_to_text">Up to</Trans>
              </span>
              <RewardText />
            </span>
          ) : (
            <RewardText />
          )}
        </div>
      </div>
    )
  }

  return (
    <div
      className={rewardbutton.reward}
      style={{
        background: config?.colors.button_color,
        color: config?.colors.button_text_color,
      }}
    >
      {icon ? <img className={rewardbutton.currencyImage} src={icon} /> : '+'}
      {showUpToText ? (
        <div
          className={rewardbutton.upToContainer}
          style={{
            color: config?.colors.button_text_color,
          }}
        >
          <span className={rewardbutton.upToText}>
            <Trans i18nKey="up_to_text">Up to</Trans>
          </span>
          <div className={rewardbutton.currencyText}>
            <RewardText />
          </div>
        </div>
      ) : (
        <RewardText />
      )}
    </div>
  )
})

export default RewardButton
