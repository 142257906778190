import { EarnResponseDto, OfferResponseDto } from 'api/offer/types'
import { transactionResponse } from '../transaction/data'

export const offerUrlMock =
  '//engine-aws-stg.fyber.com/show/fyber?appid=1246&uid=marcelo&client=sdk&device_model=&platform=android&appname=+1246+Product+Android+Test&traffic_source=offer_sdk&country_code=RO&pubid=249&ip=82.76.211.0&request_timestamp=1622018522095&request_id=d046afe2-7ed3-4448-ae0a-aaa3c83ea399&ad_id=106311&ad_format=offer&group=Fyber&rank=1&gdpr_privacy_consent=0&sig=63cfab80654a965a2d7cde85dbdff01b9119e055'
export const encodedUrlMock =
  'sponsorpay://exit?url=https%3A%2F%2Fwww2.balao.de%2FSHejb%3Fvc%3D%26pname%3DProduct%2540sponsorpay.com%26bundle%3D%26pubcat%3D'

export const offerUrlResponseMock: EarnResponseDto = {
  background_tracking: false,
  country_code: 'RO',
  icon_hires:
    'https://staging-icons.fyber-static.com/assets/66821/tap-250-blue_square_175.png',
  icon_lores:
    'https://staging-icons.fyber-static.com/assets/66821/tap-250-blue_square_60.png',
  id: 106311,
  is_download: false,
  mobile_app_identifier: null,
  offer_url:
    'https://www2.balao.de/SHejb?vc=&pname=Product%40sponsorpay.com&bundle=&pubcat=',
  preview: true,
  preview_text:
    'Take a Super Survey and earn FREE rewards. \r\nAnswer a few easy questions about yourself then take a survey! These surveys are usually 7-12 minutes long. New surveys everyday. Try once a day!\r\nAnswer a few easy questions about yourself then take a survey! These surveys are usually 7-12 minutes long. New surveys everyday. Try once a day!\r\nAnswer a few easy questions about yourself then take a survey! These surveys are usually 7-12 minutes long. New surveys everyday. Try once a day!\r\nAnswer a few easy questions about yourself then take a survey! These surveys are usually 7-12 minutes long. New surveys everyday. Try once a day!\r\nAnswer a few easy questions about yourself then take a survey! These surveys are usually 7-12 minutes long. New surveys everyday. Try once a day!\r\nAnswer a few easy questions about yourself then take a survey! These surveys are usually 7-12 minutes long. New surveys everyday. Try once a day!\r\nAnswer a few easy questions about yourself then take a survey! These surveys ar\r\n',
  reload_offers: false,
  title: 'Take a Jablko Survey!',
  virtual_currency: 'Banana',
}

export const offerResponse: OfferResponseDto = {
  data_attrs: {
    'data-request_timestamp': 1606992960180,
    'data-userid': 'therandomuser',
    'data-user-life-stage': 'no_conversions',
    'data-user-creation-timestamp': 1602859669,
    'data-appid': '1246',
    'data-placement_id': '',
    'data-country': 'DE',
    'data-language': 'en',
    'data-integration': 7,
    'data-filter': null,
    'data-external-ofw': false,
    'data-app-category': 'Racing',
    'data-publisher-id': 249,
    'data-user-has-ignite': true,
  },
  page: 1,
  pages: 1,
  tab_1_page: 1,
  tab_2_page: 1,
  tab_1_pages: 1,
  tab_2_pages: 1,
  is_last: false,
  virtual_currency: 'Tokens',
  virtual_currency_sale_enabled: true,
  virtual_currency_sale_reward_multiplier: 1.1,
  vcs_time_left: 47040,
  mobile_scarcity: {
    '1393431': {
      original_quantity: 32,
      lifespan: 21256,
      timestamp: 1606992957,
      expire_at: 1607057413,
    },
    '1418563': {
      original_quantity: 76,
      lifespan: 21435,
      timestamp: 1606992960,
      expire_at: 1607057595,
    },
    '1418507': {
      original_quantity: 79,
      lifespan: 13115,
      timestamp: 1606992960,
      expire_at: 1607049275,
    },
  },
  in_progress_offers: true,
  in_progress_reward_amount: 2276,
  reengagement: {
    all_transactions_count: 7,
    total_reward: 120,
    transactions: transactionResponse.transactions.map((t) => ({
      ...t,
      expires_in: 1231231,
    })),
  },
  offers: [
    {
      type: 'task',
      lpid: 1418507,
      advertiser_id: 78997,
      account_manager_id: 114133,
      attribution_window: 30,
      comment: '',
      pid: 996525,
      title: 'KOA_Android_CPE_Level19_T1_Edge',
      mobile_title: 'Free Campaign',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1418507&ad_format=offer&group=Fyber&rank=2&gdpr_privacy_consent=0&sig=ccffd604c916e6f2750f6bcee963bd7b30313a8b',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 488,
      rewd_amnt_pre_sale: 444,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/offercampaign/155/icon/df2ef695381d79851756993de28df34e.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/61565/small_icon.png',
      icon: 'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/offercampaign/155/icon/df2ef695381d79851756993de28df34e.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.funplus.kingofavalon',
      size_tag: '687 MB',
      preview: true,
      preview_text:
        'Offer Valid to New Players Who have Never Installed the Game before\n*Please complete the task within 28 days*\nUpdate Your Stronghold Castle to Level 19 to Collect Reward!',
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        {
          step: 'Offer Valid to New Players Who have Never Installed the Game before',
          completed: false,
        },
        {
          step: '*Please complete the task within 28 days*',
          completed: false,
        },
        {
          step: 'Update Your Stronghold Castle to Level 19 to Collect Reward!',
          completed: false,
        },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 30,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 0,
      in_grace_period: true,
    },
    {
      type: 'task',
      lpid: 1418563,
      advertiser_id: 74422,
      account_manager_id: 100012,
      attribution_window: 30,
      comment: 'Start trading Bitcoins with our exchange',
      pid: 1014817,
      title: '[ANDROID] [INCENT] Bitcoin Trader',
      mobile_title: 'MultiReward Campaign',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1418563&ad_format=offer&group=Fyber&rank=1&gdpr_privacy_consent=0&sig=caea73f90691192a54b8d727979709c764663004',
      is_free: false,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 594,
      rewd_amnt_pre_sale: 540,
      actn_text: 'Rewards after you register and deposit 200 GBP/Euro.',
      icon_hires:
        'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/3911d910c15bbd53cfbb2b4a9a066d37.png',
      icon_lores:
        'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/3911d910c15bbd53cfbb2b4a9a066d37.png',
      icon: 'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/3911d910c15bbd53cfbb2b4a9a066d37.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68395/icos_ofw_purchase_original.svg',
      user_task: {
        id: 23,
        icon: '//icons.fyber-static.com/assets/68395/icos_ofw_purchase_original.svg',
      },
      is_highlighted: false,
      multi_payout: true,
      instruction_steps: [
        'Instruction step 1',
        'Instruction step 2',
        'Instruction step 3',
      ],
      action_steps: [
        {
          action_id: 'LEVEL10',
          completed: false,
          step: 'Complete level 10',
          payout_distribution: 30,
          rewd_amnt: 30,
        },
        {
          action_id: 'LEVEL20',
          completed: false,
          step: 'Complete level 20',
          payout_distribution: 20,
          rewd_amnt: 20,
        },
        {
          action_id: 'LEVEL30',
          completed: false,
          step: 'Complete level 30',
          payout_distribution: 20,
          rewd_amnt: 20,
        },
        {
          action_id: 'LEVEL40',
          completed: false,
          step: 'Complete level 40',
          payout_distribution: 20,
          rewd_amnt: 20,
        },
        {
          action_id: 'LEVEL50',
          completed: false,
          step: 'Complete level 50',
          payout_distribution: 10,
          rewd_amnt: 10,
        },
      ],
      store_id: '',
      size_tag: false,
      preview: true,
      preview_text:
        'Rewards after you register and deposit 200 GBP/Euro.\r\n\r\n*New users only*',
      is_download: false,
      is_video: false,
      initial_scarcity: 1,
      required_actions: 'Rewards after you register and deposit 200 GBP/Euro.',
      action_description:
        'Rewards after you register and deposit 200 GBP/Euro.',
      billing_relation_id: 0,
      latency: 30,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 1,
      mobile_app_primary_category: null,
      non_web_offer: false,
      avg_latency: 0,
    },
    {
      asset_urls: [
        {
          low: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/f538fc78-a0ea-4af4-a115-6be1239e73d7_low.mp4',
          raw: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/f538fc78-a0ea-4af4-a115-6be1239e73d7_raw.mp4',
          high: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/f538fc78-a0ea-4af4-a115-6be1239e73d7_high.mp4',
          medium:
            'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/f538fc78-a0ea-4af4-a115-6be1239e73d7_medium.mp4',
        },
        {
          low: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/69db997b-2ca0-4518-a8a5-e551a19c61d4_low.jpeg',
          raw: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/69db997b-2ca0-4518-a8a5-e551a19c61d4_raw.jpeg',
          high: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/69db997b-2ca0-4518-a8a5-e551a19c61d4_high.jpeg',
          medium:
            'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/69db997b-2ca0-4518-a8a5-e551a19c61d4_medium.jpeg',
        },
      ],
      type: 'task',
      lpid: 1403071,
      advertiser_id: 59967,
      account_manager_id: 110017,
      attribution_window: 30,
      comment: null,
      pid: 1006459,
      title: 'Platin Casino - DE/AT - 2045924  - 21.8',
      mobile_title: 'Carousel Campaign',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1403071&ad_format=offer&group=Fyber&rank=3&gdpr_privacy_consent=0&sig=dbef6e40b3095ec9a01e4e2acb94a2e0e4b8b1a5',
      is_free: false,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 100,
      rewd_amnt_pre_sale: 178,
      actn_text: 'Platin Casino Bis zu 100€ GESCHENKT!',
      icon_hires:
        'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/6ae5946a56f8950a838de9df07173d2a.png',
      icon_lores:
        'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/6ae5946a56f8950a838de9df07173d2a.png',
      icon: 'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/6ae5946a56f8950a838de9df07173d2a.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68395/icos_ofw_purchase_original.svg',
      user_task: {
        id: 23,
        icon: '//icons.fyber-static.com/assets/68395/icos_ofw_purchase_original.svg',
      },
      is_highlighted: false,
      multi_payout: true,
      instruction_steps: [
        'Instruction step 1',
        'Instruction step 2',
        'Instruction step 3',
      ],
      action_steps: [
        {
          action_id: 'LEVEL10',
          completed: false,
          step: 'Complete level 10',
          payout_distribution: 30,
          rewd_amnt: 30,
        },
        {
          action_id: 'LEVEL20',
          completed: false,
          step: 'Complete level 20',
          payout_distribution: 20,
          rewd_amnt: 20,
        },
        {
          action_id: 'LEVEL30',
          completed: false,
          step: 'Complete level 30',
          payout_distribution: 20,
          rewd_amnt: 20,
        },
        {
          action_id: 'LEVEL40',
          completed: false,
          step: 'Complete level 40',
          payout_distribution: 20,
          rewd_amnt: 20,
        },
        {
          action_id: 'LEVEL50',
          completed: false,
          step: 'Complete level 50',
          payout_distribution: 10,
          rewd_amnt: 10,
        },
      ],
      store_id: '',
      size_tag: false,
      preview: true,
      preview_text:
        '1. Registrieren 2. Einzahlung tätigen 3. Belohnungen erhalten!\r\nMindestzahlung von 25 €\r\n\r\nNur neue Benutzer über 18 Jahre\r\nGlücksspiel kann süchtig machen. Spielen Sie mit Bedacht auf eigenes Risiko.',
      is_download: false,
      is_video: false,
      initial_scarcity: 4,
      required_actions: 'Platin Casino Bis zu 100€ GESCHENKT!',
      action_description: 'Platin Casino Bis zu 100€ GESCHENKT!',
      billing_relation_id: 0,
      latency: 3440,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: null,
      non_web_offer: false,
      avg_latency: 4301,
    },
    {
      type: 'task',
      lpid: 1399746,
      advertiser_id: 78907,
      account_manager_id: 112266,
      attribution_window: 30,
      comment: '',
      pid: 1003763,
      title: 'Fyber_WSOP_CPE_ReachLevel50_T2_OSV8_Android',
      mobile_title: 'Hard Campaign',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1399746&ad_format=offer&group=Fyber&rank=4&gdpr_privacy_consent=0&sig=3c9dd9b94b2522450c5f063b6760e4618c995184',
      is_free: false,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 293,
      rewd_amnt_pre_sale: 266,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires: '/images/placement-preview/icon3.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/44786/small_icon.png',
      icon: 'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/1c3f139a5bcf4d701249462f749cd336.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.playtika.wsop.gp',
      size_tag: '118 MB',
      preview: true,
      preview_text:
        'Reach level 50 to Receive Your Reward!\n Level up fast by winning hands, completing missions, joining higher clubs, and winning tournaments!\nNEW USERS ONLY!\n**Offer is Valid For 21 Days After Installation!',
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        {
          step: 'Reach level 50 to Receive Your Reward!',
          completed: false,
        },
        {
          step: 'Level up fast by winning hands, completing missions, joining higher clubs, and winning tournaments!',
          completed: false,
        },
        { step: 'NEW USERS ONLY!', completed: false },
        {
          step: '**Offer is Valid For 21 Days After Installation!',
          completed: false,
        },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 9915,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Card',
      non_web_offer: true,
      avg_latency: 12397,
    },
    {
      type: 'task',
      lpid: 1407234,
      advertiser_id: 79039,
      account_manager_id: 100012,
      attribution_window: 30,
      comment:
        '* 10 calendar days from the time the game is first opened after download.\n* * Please note that this offer only works for new players *',
      pid: 996519,
      title: 'FyberCPE_DE_2VoidShards_10D_&adcampaign=359293',
      mobile_title: 'Comment in Campaign',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1407234&ad_format=offer&group=Fyber&rank=5&gdpr_privacy_consent=0&sig=8a3d31f8cf2cebf9a2a454ce6db642af31fb600e',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 391,
      rewd_amnt_pre_sale: 355,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/3887ba5a5ec981f40a5bce74cb9a3780.png',
      icon_lores:
        'https://icons.fyber-static.com/assets/69451/RAD_Virb_Shard_Purple_250x250_4816_square_60.gif',
      icon: 'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/3887ba5a5ec981f40a5bce74cb9a3780.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.plarium.raidlegends',
      size_tag: '147 MB',
      preview: true,
      preview_text:
        'Installiere Raid\nÖffne 2 Lila Splitter in 10 Tagen* & Erhalte kurz darauf deine Belohnung!',
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        { step: 'Install RPG videogame', completed: false },
        {
          step: 'Play for 20 levels and get to the last level',
          completed: false,
        },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 5420,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Role Playing',
      non_web_offer: true,
      avg_latency: 6780,
    },
    {
      type: 'task',
      lpid: 1407440,
      advertiser_id: 79324,
      account_manager_id: 100012,
      attribution_window: 30,
      comment:
        'New users only!\nAchieve level 25 within 15 calendar days.\nThe 1st calendar day starts when you download the app.',
      pid: 1006421,
      title: 'AP_FI_Android_WW_CPE_LVL25_22.09.2020',
      mobile_title: 'Complete Level 25 in Platformer Game!',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1407440&ad_format=offer&group=Fyber&rank=6&gdpr_privacy_consent=0&sig=14c63ae3e9a3e5bbbb55ed8575d13ba8d56e36cd',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 268,
      rewd_amnt_pre_sale: 244,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/3911d910c15bbd53cfbb2b4a9a066d37.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/108392/small_icon.png',
      icon: 'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/3911d910c15bbd53cfbb2b4a9a066d37.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.MelsoftGames.FamilyIslandFarm',
      size_tag: '112 MB',
      preview: true,
      preview_text: 'Get Family Island\nPlay the game and complete level 25',
      is_download: false,
      is_video: false,
      initial_scarcity: 3,
      required_actions: '',
      action_steps: [
        { step: 'Get Platformer game', completed: false },
        { step: 'Play the game and complete level 25', completed: false },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 865,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 52,
      mobile_app_primary_category: 'Casual',
      non_web_offer: true,
      avg_latency: 1086,
    },
    {
      type: 'task',
      lpid: 1394507,
      advertiser_id: 79183,
      account_manager_id: 115512,
      attribution_window: 30,
      comment: '',
      pid: 1001135,
      title: 'RoC-And-LVL17',
      mobile_title: 'Lead Generation Campaign',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1394507&ad_format=offer&group=Fyber&rank=7&gdpr_privacy_consent=0&sig=6da1d8a5b62d1005dc986b3d6dd316a193c1c1b0',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 415,
      rewd_amnt_pre_sale: 377,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/4bce883a0f39d1602f4b5c0fe0ad5920.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/102491/small_icon.png',
      icon: 'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/4bce883a0f39d1602f4b5c0fe0ad5920.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.lilithgame.roc.gp',
      size_tag: '101 MB',
      preview: true,
      preview_text:
        'Starten Sie Rise of Kingdoms. \nWerte dein Rathaus zu Level 17 auf\nHol dir Belohnungen!\n*Bitte erledigen Sie die Aufgabe in 19 Tagen\n*Die Registrierung eines NEUEN Kontos ist Voraussetzung für die Teilnahme an dieser Belohnung\n*Die Nutzer erhalten nur Prämien aus EINEM Angebot von Rise of Kingdoms',
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        { step: 'Start the game.', completed: false },
        { step: 'Get to level 17', completed: false },
        { step: 'Get the Magic Knife!', completed: false },
        {
          step: 'Get 19 gems',
          completed: false,
        },
        {
          step: 'Register a new account',
          completed: false,
        },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 16490,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 20615,
    },
    {
      type: 'task',
      lpid: 1417792,
      advertiser_id: 78999,
      account_manager_id: 114133,
      attribution_window: 30,
      comment: '',
      pid: 995705,
      title: 'SOS_Android_CPE_StateLv23_T1_Edge',
      mobile_title: 'State of Survival: Survive the Zomb',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1417792&ad_format=offer&group=Fyber&rank=8&gdpr_privacy_consent=0&sig=4c348f9254dbd9365aacdc92b2a38be853ce3aa8',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 488,
      rewd_amnt_pre_sale: 444,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/app_icons/106533/big_icon.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/106533/small_icon.png',
      icon: 'https://icons.fyber-static.com/app_icons/106533/big_icon.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.kingsgroup.sos',
      size_tag: '142 MB',
      preview: true,
      preview_text:
        'Offer Valid to Users Who Have Never Installed This Game Before!\n*Please complete the task within 30 days*\nUpgrade Settlement to Level 23 to Collect Reward!',
      is_download: false,
      is_video: false,
      initial_scarcity: 1,
      required_actions: '',
      action_steps: [
        {
          step: 'Offer Valid to Users Who Have Never Installed This Game Before!',
          completed: false,
        },
        {
          step: '*Please complete the task within 30 days*',
          completed: false,
        },
        {
          step: 'Upgrade Settlement to Level 23 to Collect Reward!',
          completed: false,
        },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 30,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 0,
    },
    {
      type: 'task',
      lpid: 1417181,
      advertiser_id: 79397,
      account_manager_id: 106955,
      attribution_window: 30,
      comment:
        '**Offer Is Valid ONLY to Users Who Has Never Installed The Game Before.\n**Offer Is Valid For 14 Days After Click and Install Days!',
      pid: 1006957,
      title: 'DT_600144_GP_BJ_L4_1127_LV60',
      mobile_title: 'Bingo Journey - Lucky & Fun Casino Bingo Games',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1417181&ad_format=offer&group=Fyber&rank=9&gdpr_privacy_consent=0&sig=9432d1014139d060a26beecf6f39303778827bd0',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 171,
      rewd_amnt_pre_sale: 155,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/app_icons/108509/big_icon.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/108509/small_icon.png',
      icon: 'https://icons.fyber-static.com/app_icons/108509/big_icon.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.bingo.scape.android.free',
      size_tag: '102 MB',
      preview: true,
      preview_text:
        'Join us for Bingo Journey\nReach up to Level60 in the game\nGet Rewarded!!!',
      is_download: false,
      is_video: false,
      initial_scarcity: 3,
      required_actions: '',
      action_steps: [
        { step: 'Join us for Bingo Journey', completed: false },
        { step: 'Reach up to Level60 in the game', completed: false },
        { step: 'Get Rewarded!!!', completed: false },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 30,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 100,
      mobile_app_primary_category: 'Casino',
      non_web_offer: true,
      avg_latency: 0,
    },
    {
      type: 'task',
      lpid: 1406406,
      advertiser_id: 79324,
      account_manager_id: 100012,
      attribution_window: 30,
      comment:
        'Complete the task in 15 calendar days.\nThe 1st calendar day starts when you download the app.\nFor new users only!',
      pid: 1004807,
      title: 'AP_MC_Android_WW_CPE_LVL15_15.09.2020',
      mobile_title: 'Complete Level 15 in My Cafe!',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1406406&ad_format=offer&group=Fyber&rank=10&gdpr_privacy_consent=0&sig=77b8fb3be2e7612de57d571ed33c09e114f73a96',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 156,
      rewd_amnt_pre_sale: 142,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires: 'https://icons.fyber-static.com/app_icons/59878/big_icon.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/59878/small_icon.png',
      icon: 'https://icons.fyber-static.com/app_icons/59878/big_icon.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.melesta.coffeeshop',
      size_tag: '40 MB',
      preview: true,
      preview_text: 'Get My Cafe\nPlay the game and complete level 15',
      is_download: false,
      is_video: false,
      initial_scarcity: 5,
      required_actions: '',
      action_steps: [
        { step: 'Get My Cafe', completed: false },
        { step: 'Play the game and complete level 15', completed: false },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 8710,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Casual',
      non_web_offer: true,
      avg_latency: 10891,
    },
    {
      type: 'task',
      lpid: 1401463,
      advertiser_id: 79352,
      account_manager_id: 115512,
      attribution_window: 30,
      comment:
        '*Bitte schließen Sie die Aufgabe innerhalb von 30 Tagen nach der Installation ab *Die Registrierung eines NEUEN Kontos ist Voraussetzung für die Teilnahme an dieser Belohnung.',
      pid: 1005493,
      title: 'WG-Android-T2-Centerlvl16',
      mobile_title: 'West Game',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1401463&ad_format=offer&group=Fyber&rank=11&gdpr_privacy_consent=0&sig=0befdb9ac6111da3828747653cc58136fbc2c68c',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 342,
      rewd_amnt_pre_sale: 311,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/app_icons/104601/big_icon.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/104601/small_icon.png',
      icon: 'https://icons.fyber-static.com/app_icons/104601/big_icon.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'leyi.westgame',
      size_tag: '117 MB',
      preview: true,
      preview_text:
        'Starten Sie West Game, \nErreiche Stadtzentrum Level 16,\nHol dir Belohnungen!',
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        { step: 'Starten Sie West Game,', completed: false },
        { step: 'Erreiche Stadtzentrum Level 16,', completed: false },
        { step: 'Hol dir Belohnungen!', completed: false },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 29895,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 37371,
    },
    {
      type: 'task',
      lpid: 1412058,
      advertiser_id: 79560,
      account_manager_id: 115512,
      attribution_window: 30,
      comment:
        '* Nur für neue Benutzer! Keine Belohnung, wenn du die andere Aufgabe dieses Spiels bereits erledigt hast! *',
      pid: 1011694,
      title: 'CE-AOS-CPE-3ORANGE',
      mobile_title: 'Clone Evolution',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1412058&ad_format=offer&group=Fyber&rank=12&gdpr_privacy_consent=0&sig=995b0a7605abe79d59d4dae59c0ddd9698e6655e',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 464,
      rewd_amnt_pre_sale: 422,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires: 'https://icons.fyber-static.com/app_icons/93483/big_icon.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/93483/small_icon.png',
      icon: 'https://icons.fyber-static.com/app_icons/93483/big_icon.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.feelingtouch.clonewar',
      size_tag: '81 MB',
      preview: true,
      preview_text:
        'Öffne Clone Evolution und sammle Helden.\nEinige Helden mit niedrigem Rang können in Darwins Labor zu Helden mit hohem Rang aufgewertet werden.\nBringe drei orangefarbenen Helden dazu, diese Aufgabe zu erledigen!',
      is_download: false,
      is_video: false,
      initial_scarcity: 1,
      required_actions: '',
      action_steps: [
        {
          step: 'Öffne Clone Evolution und sammle Helden.',
          completed: false,
        },
        {
          step: 'Einige Helden mit niedrigem Rang können in Darwins Labor zu Helden mit hohem Rang aufgewertet werden.',
          completed: false,
        },
        {
          step: 'Bringe drei orangefarbenen Helden dazu, diese Aufgabe zu erledigen!',
          completed: false,
        },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 8575,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Role Playing',
      non_web_offer: true,
      avg_latency: 10721,
    },
  ],
  tab_1_offers: [
    {
      type: 'task',
      lpid: 1418507,
      advertiser_id: 78997,
      account_manager_id: 114133,
      attribution_window: 30,
      comment: '',
      pid: 996525,
      title: 'KOA_Android_CPE_Level19_T1_Edge',
      mobile_title: 'Free Campaign',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1418507&ad_format=offer&group=Fyber&rank=2&gdpr_privacy_consent=0&sig=ccffd604c916e6f2750f6bcee963bd7b30313a8b',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 488,
      rewd_amnt_pre_sale: 444,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/offercampaign/155/icon/df2ef695381d79851756993de28df34e.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/61565/small_icon.png',
      icon: 'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/offercampaign/155/icon/df2ef695381d79851756993de28df34e.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.funplus.kingofavalon',
      size_tag: '687 MB',
      preview: true,
      preview_text:
        'Offer Valid to New Players Who have Never Installed the Game before\n*Please complete the task within 28 days*\nUpdate Your Stronghold Castle to Level 19 to Collect Reward!',
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        {
          step: 'Offer Valid to New Players Who have Never Installed the Game before',
          completed: false,
        },
        {
          step: '*Please complete the task within 28 days*',
          completed: false,
        },
        {
          step: 'Update Your Stronghold Castle to Level 19 to Collect Reward!',
          completed: false,
        },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 30,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 0,
    },
    {
      type: 'task',
      lpid: 1418563,
      advertiser_id: 74422,
      account_manager_id: 100012,
      attribution_window: 30,
      comment: 'Start trading Bitcoins with our exchange',
      pid: 1014817,
      title: '[ANDROID] [INCENT] Bitcoin Trader',
      mobile_title: 'MultiReward Campaign',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1418563&ad_format=offer&group=Fyber&rank=1&gdpr_privacy_consent=0&sig=caea73f90691192a54b8d727979709c764663004',
      is_free: false,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 594,
      rewd_amnt_pre_sale: 540,
      actn_text: 'Rewards after you register and deposit 200 GBP/Euro.',
      icon_hires:
        'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/3911d910c15bbd53cfbb2b4a9a066d37.png',
      icon_lores:
        'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/3911d910c15bbd53cfbb2b4a9a066d37.png',
      icon: 'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/3911d910c15bbd53cfbb2b4a9a066d37.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68395/icos_ofw_purchase_original.svg',
      user_task: {
        id: 23,
        icon: '//icons.fyber-static.com/assets/68395/icos_ofw_purchase_original.svg',
      },
      is_highlighted: false,
      multi_payout: true,
      instruction_steps: [
        'Instruction step 1',
        'Instruction step 2',
        'Instruction step 3',
      ],
      action_steps: [
        {
          action_id: 'LEVEL10',
          completed: false,
          step: 'Complete level 10',
          payout_distribution: 30,
          rewd_amnt: 30,
        },
        {
          action_id: 'LEVEL20',
          completed: false,
          step: 'Complete level 20',
          payout_distribution: 20,
          rewd_amnt: 20,
        },
        {
          action_id: 'LEVEL30',
          completed: false,
          step: 'Complete level 30',
          payout_distribution: 20,
          rewd_amnt: 20,
        },
        {
          action_id: 'LEVEL40',
          completed: false,
          step: 'Complete level 40',
          payout_distribution: 20,
          rewd_amnt: 20,
        },
        {
          action_id: 'LEVEL50',
          completed: false,
          step: 'Complete level 50',
          payout_distribution: 10,
          rewd_amnt: 10,
        },
      ],
      store_id: '',
      size_tag: false,
      preview: true,
      preview_text:
        'Rewards after you register and deposit 200 GBP/Euro.\r\n\r\n*New users only*',
      is_download: false,
      is_video: false,
      initial_scarcity: 1,
      required_actions: 'Rewards after you register and deposit 200 GBP/Euro.',
      action_description:
        'Rewards after you register and deposit 200 GBP/Euro.',
      billing_relation_id: 0,
      latency: 30,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 1,
      mobile_app_primary_category: null,
      non_web_offer: false,
      avg_latency: 0,
    },
    {
      asset_urls: [
        {
          low: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/f538fc78-a0ea-4af4-a115-6be1239e73d7_low.mp4',
          raw: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/f538fc78-a0ea-4af4-a115-6be1239e73d7_raw.mp4',
          high: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/f538fc78-a0ea-4af4-a115-6be1239e73d7_high.mp4',
          medium:
            'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/f538fc78-a0ea-4af4-a115-6be1239e73d7_medium.mp4',
        },
        {
          low: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/69db997b-2ca0-4518-a8a5-e551a19c61d4_low.jpeg',
          raw: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/69db997b-2ca0-4518-a8a5-e551a19c61d4_raw.jpeg',
          high: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/69db997b-2ca0-4518-a8a5-e551a19c61d4_high.jpeg',
          medium:
            'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/69db997b-2ca0-4518-a8a5-e551a19c61d4_medium.jpeg',
        },
      ],
      type: 'task',
      lpid: 1403071,
      advertiser_id: 59967,
      account_manager_id: 110017,
      attribution_window: 30,
      comment: null,
      pid: 1006459,
      title: 'Platin Casino - DE/AT - 2045924  - 21.8',
      mobile_title: 'Carousel Campaign',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1403071&ad_format=offer&group=Fyber&rank=3&gdpr_privacy_consent=0&sig=dbef6e40b3095ec9a01e4e2acb94a2e0e4b8b1a5',
      is_free: false,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 100,
      rewd_amnt_pre_sale: 178,
      actn_text: 'Platin Casino Bis zu 100€ GESCHENKT!',
      icon_hires:
        'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/6ae5946a56f8950a838de9df07173d2a.png',
      icon_lores:
        'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/6ae5946a56f8950a838de9df07173d2a.png',
      icon: 'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/6ae5946a56f8950a838de9df07173d2a.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68395/icos_ofw_purchase_original.svg',
      user_task: {
        id: 23,
        icon: '//icons.fyber-static.com/assets/68395/icos_ofw_purchase_original.svg',
      },
      is_highlighted: false,
      multi_payout: true,
      instruction_steps: [
        'Instruction step 1',
        'Instruction step 2',
        'Instruction step 3',
      ],
      action_steps: [
        {
          action_id: 'LEVEL10',
          completed: false,
          step: 'Complete level 10',
          payout_distribution: 30,
          rewd_amnt: 30,
        },
        {
          action_id: 'LEVEL20',
          completed: false,
          step: 'Complete level 20',
          payout_distribution: 20,
          rewd_amnt: 20,
        },
        {
          action_id: 'LEVEL30',
          completed: false,
          step: 'Complete level 30',
          payout_distribution: 20,
          rewd_amnt: 20,
        },
        {
          action_id: 'LEVEL40',
          completed: false,
          step: 'Complete level 40',
          payout_distribution: 20,
          rewd_amnt: 20,
        },
        {
          action_id: 'LEVEL50',
          completed: false,
          step: 'Complete level 50',
          payout_distribution: 10,
          rewd_amnt: 10,
        },
      ],
      store_id: '',
      size_tag: false,
      preview: true,
      preview_text:
        '1. Registrieren 2. Einzahlung tätigen 3. Belohnungen erhalten!\r\nMindestzahlung von 25 €\r\n\r\nNur neue Benutzer über 18 Jahre\r\nGlücksspiel kann süchtig machen. Spielen Sie mit Bedacht auf eigenes Risiko.',
      is_download: false,
      is_video: false,
      initial_scarcity: 4,
      required_actions: 'Platin Casino Bis zu 100€ GESCHENKT!',
      action_description: 'Platin Casino Bis zu 100€ GESCHENKT!',
      billing_relation_id: 0,
      latency: 3440,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: null,
      non_web_offer: false,
      avg_latency: 4301,
    },
    {
      type: 'task',
      lpid: 1399746,
      advertiser_id: 78907,
      account_manager_id: 112266,
      attribution_window: 30,
      comment: '',
      pid: 1003763,
      title: 'Fyber_WSOP_CPE_ReachLevel50_T2_OSV8_Android',
      mobile_title: 'Hard Campaign',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1399746&ad_format=offer&group=Fyber&rank=4&gdpr_privacy_consent=0&sig=3c9dd9b94b2522450c5f063b6760e4618c995184',
      is_free: false,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 293,
      rewd_amnt_pre_sale: 266,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires: '/images/placement-preview/icon3.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/44786/small_icon.png',
      icon: 'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/1c3f139a5bcf4d701249462f749cd336.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.playtika.wsop.gp',
      size_tag: '118 MB',
      preview: true,
      preview_text:
        'Reach level 50 to Receive Your Reward!\n Level up fast by winning hands, completing missions, joining higher clubs, and winning tournaments!\nNEW USERS ONLY!\n**Offer is Valid For 21 Days After Installation!',
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        {
          step: 'Reach level 50 to Receive Your Reward!',
          completed: false,
        },
        {
          step: 'Level up fast by winning hands, completing missions, joining higher clubs, and winning tournaments!',
          completed: false,
        },
        { step: 'NEW USERS ONLY!', completed: false },
        {
          step: '**Offer is Valid For 21 Days After Installation!',
          completed: false,
        },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 9915,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Card',
      non_web_offer: true,
      avg_latency: 12397,
    },
    {
      type: 'task',
      lpid: 1407234,
      advertiser_id: 79039,
      account_manager_id: 100012,
      attribution_window: 30,
      comment:
        '* 10 calendar days from the time the game is first opened after download.\n* * Please note that this offer only works for new players *',
      pid: 996519,
      title: 'FyberCPE_DE_2VoidShards_10D_&adcampaign=359293',
      mobile_title: 'Comment in Campaign',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1407234&ad_format=offer&group=Fyber&rank=5&gdpr_privacy_consent=0&sig=8a3d31f8cf2cebf9a2a454ce6db642af31fb600e',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 391,
      rewd_amnt_pre_sale: 355,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/3887ba5a5ec981f40a5bce74cb9a3780.png',
      icon_lores:
        'https://icons.fyber-static.com/assets/69451/RAD_Virb_Shard_Purple_250x250_4816_square_60.gif',
      icon: 'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/3887ba5a5ec981f40a5bce74cb9a3780.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.plarium.raidlegends',
      size_tag: '147 MB',
      preview: true,
      preview_text:
        'Installiere Raid\nÖffne 2 Lila Splitter in 10 Tagen* & Erhalte kurz darauf deine Belohnung!',
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        { step: 'Install RPG videogame', completed: false },
        {
          step: 'Play for 20 levels and get to the last level',
          completed: false,
        },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 5420,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Role Playing',
      non_web_offer: true,
      avg_latency: 6780,
    },
    {
      type: 'task',
      lpid: 1407440,
      advertiser_id: 79324,
      account_manager_id: 100012,
      attribution_window: 30,
      comment:
        'New users only!\nAchieve level 25 within 15 calendar days.\nThe 1st calendar day starts when you download the app.',
      pid: 1006421,
      title: 'AP_FI_Android_WW_CPE_LVL25_22.09.2020',
      mobile_title: 'Complete Level 25 in Platformer Game!',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1407440&ad_format=offer&group=Fyber&rank=6&gdpr_privacy_consent=0&sig=14c63ae3e9a3e5bbbb55ed8575d13ba8d56e36cd',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 268,
      rewd_amnt_pre_sale: 244,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/3911d910c15bbd53cfbb2b4a9a066d37.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/108392/small_icon.png',
      icon: 'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/3911d910c15bbd53cfbb2b4a9a066d37.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.MelsoftGames.FamilyIslandFarm',
      size_tag: '112 MB',
      preview: true,
      preview_text: 'Get Family Island\nPlay the game and complete level 25',
      is_download: false,
      is_video: false,
      initial_scarcity: 3,
      required_actions: '',
      action_steps: [
        { step: 'Get Platformer game', completed: false },
        { step: 'Play the game and complete level 25', completed: false },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 865,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 52,
      mobile_app_primary_category: 'Casual',
      non_web_offer: true,
      avg_latency: 1086,
    },
    {
      type: 'task',
      lpid: 1394507,
      advertiser_id: 79183,
      account_manager_id: 115512,
      attribution_window: 30,
      comment: '',
      pid: 1001135,
      title: 'RoC-And-LVL17',
      mobile_title: 'Lead Generation Campaign',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1394507&ad_format=offer&group=Fyber&rank=7&gdpr_privacy_consent=0&sig=6da1d8a5b62d1005dc986b3d6dd316a193c1c1b0',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 415,
      rewd_amnt_pre_sale: 377,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/4bce883a0f39d1602f4b5c0fe0ad5920.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/102491/small_icon.png',
      icon: 'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/4bce883a0f39d1602f4b5c0fe0ad5920.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.lilithgame.roc.gp',
      size_tag: '101 MB',
      preview: true,
      preview_text:
        'Starten Sie Rise of Kingdoms. \nWerte dein Rathaus zu Level 17 auf\nHol dir Belohnungen!\n*Bitte erledigen Sie die Aufgabe in 19 Tagen\n*Die Registrierung eines NEUEN Kontos ist Voraussetzung für die Teilnahme an dieser Belohnung\n*Die Nutzer erhalten nur Prämien aus EINEM Angebot von Rise of Kingdoms',
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        { step: 'Start the game.', completed: false },
        { step: 'Get to level 17', completed: false },
        { step: 'Get the Magic Knife!', completed: false },
        {
          step: 'Get 19 gems',
          completed: false,
        },
        {
          step: 'Register a new account',
          completed: false,
        },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 16490,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 20615,
    },
    {
      type: 'task',
      lpid: 1417792,
      advertiser_id: 78999,
      account_manager_id: 114133,
      attribution_window: 30,
      comment: '',
      pid: 995705,
      title: 'SOS_Android_CPE_StateLv23_T1_Edge',
      mobile_title: 'State of Survival: Survive the Zomb',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1417792&ad_format=offer&group=Fyber&rank=8&gdpr_privacy_consent=0&sig=4c348f9254dbd9365aacdc92b2a38be853ce3aa8',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 488,
      rewd_amnt_pre_sale: 444,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/app_icons/106533/big_icon.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/106533/small_icon.png',
      icon: 'https://icons.fyber-static.com/app_icons/106533/big_icon.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.kingsgroup.sos',
      size_tag: '142 MB',
      preview: true,
      preview_text:
        'Offer Valid to Users Who Have Never Installed This Game Before!\n*Please complete the task within 30 days*\nUpgrade Settlement to Level 23 to Collect Reward!',
      is_download: false,
      is_video: false,
      initial_scarcity: 1,
      required_actions: '',
      action_steps: [
        {
          step: 'Offer Valid to Users Who Have Never Installed This Game Before!',
          completed: false,
        },
        {
          step: '*Please complete the task within 30 days*',
          completed: false,
        },
        {
          step: 'Upgrade Settlement to Level 23 to Collect Reward!',
          completed: false,
        },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 30,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 0,
    },
    {
      type: 'task',
      lpid: 1417181,
      advertiser_id: 79397,
      account_manager_id: 106955,
      attribution_window: 30,
      comment:
        '**Offer Is Valid ONLY to Users Who Has Never Installed The Game Before.\n**Offer Is Valid For 14 Days After Click and Install Days!',
      pid: 1006957,
      title: 'DT_600144_GP_BJ_L4_1127_LV60',
      mobile_title: 'Bingo Journey - Lucky & Fun Casino Bingo Games',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1417181&ad_format=offer&group=Fyber&rank=9&gdpr_privacy_consent=0&sig=9432d1014139d060a26beecf6f39303778827bd0',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 171,
      rewd_amnt_pre_sale: 155,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/app_icons/108509/big_icon.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/108509/small_icon.png',
      icon: 'https://icons.fyber-static.com/app_icons/108509/big_icon.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.bingo.scape.android.free',
      size_tag: '102 MB',
      preview: true,
      preview_text:
        'Join us for Bingo Journey\nReach up to Level60 in the game\nGet Rewarded!!!',
      is_download: false,
      is_video: false,
      initial_scarcity: 3,
      required_actions: '',
      action_steps: [
        { step: 'Join us for Bingo Journey', completed: false },
        { step: 'Reach up to Level60 in the game', completed: false },
        { step: 'Get Rewarded!!!', completed: false },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 30,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 100,
      mobile_app_primary_category: 'Casino',
      non_web_offer: true,
      avg_latency: 0,
    },
    {
      type: 'task',
      lpid: 1406406,
      advertiser_id: 79324,
      account_manager_id: 100012,
      attribution_window: 30,
      comment:
        'Complete the task in 15 calendar days.\nThe 1st calendar day starts when you download the app.\nFor new users only!',
      pid: 1004807,
      title: 'AP_MC_Android_WW_CPE_LVL15_15.09.2020',
      mobile_title: 'Complete Level 15 in My Cafe!',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1406406&ad_format=offer&group=Fyber&rank=10&gdpr_privacy_consent=0&sig=77b8fb3be2e7612de57d571ed33c09e114f73a96',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 156,
      rewd_amnt_pre_sale: 142,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires: 'https://icons.fyber-static.com/app_icons/59878/big_icon.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/59878/small_icon.png',
      icon: 'https://icons.fyber-static.com/app_icons/59878/big_icon.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.melesta.coffeeshop',
      size_tag: '40 MB',
      preview: true,
      preview_text: 'Get My Cafe\nPlay the game and complete level 15',
      is_download: false,
      is_video: false,
      initial_scarcity: 5,
      required_actions: '',
      action_steps: [
        { step: 'Get My Cafe', completed: false },
        { step: 'Play the game and complete level 15', completed: false },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 8710,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Casual',
      non_web_offer: true,
      avg_latency: 10891,
    },
    {
      type: 'task',
      lpid: 1401463,
      advertiser_id: 79352,
      account_manager_id: 115512,
      attribution_window: 30,
      comment:
        '*Bitte schließen Sie die Aufgabe innerhalb von 30 Tagen nach der Installation ab *Die Registrierung eines NEUEN Kontos ist Voraussetzung für die Teilnahme an dieser Belohnung.',
      pid: 1005493,
      title: 'WG-Android-T2-Centerlvl16',
      mobile_title: 'West Game',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1401463&ad_format=offer&group=Fyber&rank=11&gdpr_privacy_consent=0&sig=0befdb9ac6111da3828747653cc58136fbc2c68c',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 342,
      rewd_amnt_pre_sale: 311,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/app_icons/104601/big_icon.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/104601/small_icon.png',
      icon: 'https://icons.fyber-static.com/app_icons/104601/big_icon.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'leyi.westgame',
      size_tag: '117 MB',
      preview: true,
      preview_text:
        'Starten Sie West Game, \nErreiche Stadtzentrum Level 16,\nHol dir Belohnungen!',
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        { step: 'Starten Sie West Game,', completed: false },
        { step: 'Erreiche Stadtzentrum Level 16,', completed: false },
        { step: 'Hol dir Belohnungen!', completed: false },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 29895,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 37371,
    },
    {
      type: 'task',
      lpid: 1412058,
      advertiser_id: 79560,
      account_manager_id: 115512,
      attribution_window: 30,
      comment:
        '* Nur für neue Benutzer! Keine Belohnung, wenn du die andere Aufgabe dieses Spiels bereits erledigt hast! *',
      pid: 1011694,
      title: 'CE-AOS-CPE-3ORANGE',
      mobile_title: 'Clone Evolution',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1412058&ad_format=offer&group=Fyber&rank=12&gdpr_privacy_consent=0&sig=995b0a7605abe79d59d4dae59c0ddd9698e6655e',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 464,
      rewd_amnt_pre_sale: 422,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires: 'https://icons.fyber-static.com/app_icons/93483/big_icon.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/93483/small_icon.png',
      icon: 'https://icons.fyber-static.com/app_icons/93483/big_icon.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.feelingtouch.clonewar',
      size_tag: '81 MB',
      preview: true,
      preview_text:
        'Öffne Clone Evolution und sammle Helden.\nEinige Helden mit niedrigem Rang können in Darwins Labor zu Helden mit hohem Rang aufgewertet werden.\nBringe drei orangefarbenen Helden dazu, diese Aufgabe zu erledigen!',
      is_download: false,
      is_video: false,
      initial_scarcity: 1,
      required_actions: '',
      action_steps: [
        {
          step: 'Öffne Clone Evolution und sammle Helden.',
          completed: false,
        },
        {
          step: 'Einige Helden mit niedrigem Rang können in Darwins Labor zu Helden mit hohem Rang aufgewertet werden.',
          completed: false,
        },
        {
          step: 'Bringe drei orangefarbenen Helden dazu, diese Aufgabe zu erledigen!',
          completed: false,
        },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 8575,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Role Playing',
      non_web_offer: true,
      avg_latency: 10721,
    },
  ],
  tab_2_offers: [
    {
      type: 'task',
      lpid: 1418563,
      advertiser_id: 74422,
      account_manager_id: 100012,
      attribution_window: 30,
      comment: 'Start trading Bitcoins with our exchange',
      pid: 1014817,
      title: '[ANDROID] [INCENT] Bitcoin Trader',
      mobile_title: 'MultiReward Campaign',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1418563&ad_format=offer&group=Fyber&rank=1&gdpr_privacy_consent=0&sig=caea73f90691192a54b8d727979709c764663004',
      is_free: false,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 594,
      rewd_amnt_pre_sale: 540,
      actn_text: 'Rewards after you register and deposit 200 GBP/Euro.',
      icon_hires:
        'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/3911d910c15bbd53cfbb2b4a9a066d37.png',
      icon_lores:
        'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/3911d910c15bbd53cfbb2b4a9a066d37.png',
      icon: 'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/3911d910c15bbd53cfbb2b4a9a066d37.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68395/icos_ofw_purchase_original.svg',
      user_task: {
        id: 23,
        icon: '//icons.fyber-static.com/assets/68395/icos_ofw_purchase_original.svg',
      },
      is_highlighted: false,
      multi_payout: true,
      instruction_steps: [
        'Instruction step 1',
        'Instruction step 2',
        'Instruction step 3',
      ],
      action_steps: [
        {
          action_id: 'LEVEL10',
          completed: false,
          step: 'Complete level 10',
          payout_distribution: 30,
          rewd_amnt: 30,
        },
        {
          action_id: 'LEVEL20',
          completed: false,
          step: 'Complete level 20',
          payout_distribution: 20,
          rewd_amnt: 20,
        },
        {
          action_id: 'LEVEL30',
          completed: false,
          step: 'Complete level 30',
          payout_distribution: 20,
          rewd_amnt: 20,
        },
        {
          action_id: 'LEVEL40',
          completed: false,
          step: 'Complete level 40',
          payout_distribution: 20,
          rewd_amnt: 20,
        },
        {
          action_id: 'LEVEL50',
          completed: false,
          step: 'Complete level 50',
          payout_distribution: 10,
          rewd_amnt: 10,
        },
      ],
      store_id: '',
      size_tag: false,
      preview: true,
      preview_text:
        'Rewards after you register and deposit 200 GBP/Euro.\r\n\r\n*New users only*',
      is_download: false,
      is_video: false,
      initial_scarcity: 1,
      required_actions: 'Rewards after you register and deposit 200 GBP/Euro.',
      action_description:
        'Rewards after you register and deposit 200 GBP/Euro.',
      billing_relation_id: 0,
      latency: 30,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 1,
      mobile_app_primary_category: null,
      non_web_offer: false,
      avg_latency: 0,
    },
    {
      asset_urls: [
        {
          low: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/f538fc78-a0ea-4af4-a115-6be1239e73d7_low.mp4',
          raw: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/f538fc78-a0ea-4af4-a115-6be1239e73d7_raw.mp4',
          high: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/f538fc78-a0ea-4af4-a115-6be1239e73d7_high.mp4',
          medium:
            'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/f538fc78-a0ea-4af4-a115-6be1239e73d7_medium.mp4',
        },
        {
          low: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/69db997b-2ca0-4518-a8a5-e551a19c61d4_low.jpeg',
          raw: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/69db997b-2ca0-4518-a8a5-e551a19c61d4_raw.jpeg',
          high: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/69db997b-2ca0-4518-a8a5-e551a19c61d4_high.jpeg',
          medium:
            'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/69db997b-2ca0-4518-a8a5-e551a19c61d4_medium.jpeg',
        },
      ],
      type: 'task',
      lpid: 1403071,
      advertiser_id: 59967,
      account_manager_id: 110017,
      attribution_window: 30,
      comment: null,
      pid: 1006459,
      title: 'Platin Casino - DE/AT - 2045924  - 21.8',
      mobile_title: 'Carousel Campaign',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1403071&ad_format=offer&group=Fyber&rank=3&gdpr_privacy_consent=0&sig=dbef6e40b3095ec9a01e4e2acb94a2e0e4b8b1a5',
      is_free: false,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 100,
      rewd_amnt_pre_sale: 178,
      actn_text: 'Platin Casino Bis zu 100€ GESCHENKT!',
      icon_hires:
        'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/6ae5946a56f8950a838de9df07173d2a.png',
      icon_lores:
        'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/6ae5946a56f8950a838de9df07173d2a.png',
      icon: 'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/6ae5946a56f8950a838de9df07173d2a.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68395/icos_ofw_purchase_original.svg',
      user_task: {
        id: 23,
        icon: '//icons.fyber-static.com/assets/68395/icos_ofw_purchase_original.svg',
      },
      is_highlighted: false,
      multi_payout: true,
      instruction_steps: [
        'Instruction step 1',
        'Instruction step 2',
        'Instruction step 3',
      ],
      action_steps: [
        {
          action_id: 'LEVEL10',
          completed: false,
          step: 'Complete level 10',
          payout_distribution: 30,
          rewd_amnt: 30,
        },
        {
          action_id: 'LEVEL20',
          completed: false,
          step: 'Complete level 20',
          payout_distribution: 20,
          rewd_amnt: 20,
        },
        {
          action_id: 'LEVEL30',
          completed: false,
          step: 'Complete level 30',
          payout_distribution: 20,
          rewd_amnt: 20,
        },
        {
          action_id: 'LEVEL40',
          completed: false,
          step: 'Complete level 40',
          payout_distribution: 20,
          rewd_amnt: 20,
        },
        {
          action_id: 'LEVEL50',
          completed: false,
          step: 'Complete level 50',
          payout_distribution: 10,
          rewd_amnt: 10,
        },
      ],
      store_id: '',
      size_tag: false,
      preview: true,
      preview_text:
        '1. Registrieren 2. Einzahlung tätigen 3. Belohnungen erhalten!\r\nMindestzahlung von 25 €\r\n\r\nNur neue Benutzer über 18 Jahre\r\nGlücksspiel kann süchtig machen. Spielen Sie mit Bedacht auf eigenes Risiko.',
      is_download: false,
      is_video: false,
      initial_scarcity: 4,
      required_actions: 'Platin Casino Bis zu 100€ GESCHENKT!',
      action_description: 'Platin Casino Bis zu 100€ GESCHENKT!',
      billing_relation_id: 0,
      latency: 3440,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: null,
      non_web_offer: false,
      avg_latency: 4301,
    },
    {
      type: 'task',
      lpid: 1399746,
      advertiser_id: 78907,
      account_manager_id: 112266,
      attribution_window: 30,
      comment: '',
      pid: 1003763,
      title: 'Fyber_WSOP_CPE_ReachLevel50_T2_OSV8_Android',
      mobile_title: 'Hard Campaign',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1399746&ad_format=offer&group=Fyber&rank=4&gdpr_privacy_consent=0&sig=3c9dd9b94b2522450c5f063b6760e4618c995184',
      is_free: false,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 293,
      rewd_amnt_pre_sale: 266,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires: '/images/placement-preview/icon3.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/44786/small_icon.png',
      icon: 'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/1c3f139a5bcf4d701249462f749cd336.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.playtika.wsop.gp',
      size_tag: '118 MB',
      preview: true,
      preview_text:
        'Reach level 50 to Receive Your Reward!\n Level up fast by winning hands, completing missions, joining higher clubs, and winning tournaments!\nNEW USERS ONLY!\n**Offer is Valid For 21 Days After Installation!',
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        {
          step: 'Reach level 50 to Receive Your Reward!',
          completed: false,
        },
        {
          step: 'Level up fast by winning hands, completing missions, joining higher clubs, and winning tournaments!',
          completed: false,
        },
        { step: 'NEW USERS ONLY!', completed: false },
        {
          step: '**Offer is Valid For 21 Days After Installation!',
          completed: false,
        },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 9915,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Card',
      non_web_offer: true,
      avg_latency: 12397,
    },
    {
      type: 'task',
      lpid: 1407234,
      advertiser_id: 79039,
      account_manager_id: 100012,
      attribution_window: 30,
      comment:
        '* 10 calendar days from the time the game is first opened after download.\n* * Please note that this offer only works for new players *',
      pid: 996519,
      title: 'FyberCPE_DE_2VoidShards_10D_&adcampaign=359293',
      mobile_title: 'Comment in Campaign',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1407234&ad_format=offer&group=Fyber&rank=5&gdpr_privacy_consent=0&sig=8a3d31f8cf2cebf9a2a454ce6db642af31fb600e',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 391,
      rewd_amnt_pre_sale: 355,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/3887ba5a5ec981f40a5bce74cb9a3780.png',
      icon_lores:
        'https://icons.fyber-static.com/assets/69451/RAD_Virb_Shard_Purple_250x250_4816_square_60.gif',
      icon: 'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/3887ba5a5ec981f40a5bce74cb9a3780.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.plarium.raidlegends',
      size_tag: '147 MB',
      preview: true,
      preview_text:
        'Installiere Raid\nÖffne 2 Lila Splitter in 10 Tagen* & Erhalte kurz darauf deine Belohnung!',
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        { step: 'Install RPG videogame', completed: false },
        {
          step: 'Play for 20 levels and get to the last level',
          completed: false,
        },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 5420,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Role Playing',
      non_web_offer: true,
      avg_latency: 6780,
    },
    {
      type: 'task',
      lpid: 1407440,
      advertiser_id: 79324,
      account_manager_id: 100012,
      attribution_window: 30,
      comment:
        'New users only!\nAchieve level 25 within 15 calendar days.\nThe 1st calendar day starts when you download the app.',
      pid: 1006421,
      title: 'AP_FI_Android_WW_CPE_LVL25_22.09.2020',
      mobile_title: 'Complete Level 25 in Platformer Game!',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1407440&ad_format=offer&group=Fyber&rank=6&gdpr_privacy_consent=0&sig=14c63ae3e9a3e5bbbb55ed8575d13ba8d56e36cd',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 268,
      rewd_amnt_pre_sale: 244,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/3911d910c15bbd53cfbb2b4a9a066d37.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/108392/small_icon.png',
      icon: 'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/3911d910c15bbd53cfbb2b4a9a066d37.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.MelsoftGames.FamilyIslandFarm',
      size_tag: '112 MB',
      preview: true,
      preview_text: 'Get Family Island\nPlay the game and complete level 25',
      is_download: false,
      is_video: false,
      initial_scarcity: 3,
      required_actions: '',
      action_steps: [
        { step: 'Get Platformer game', completed: false },
        { step: 'Play the game and complete level 25', completed: false },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 865,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 52,
      mobile_app_primary_category: 'Casual',
      non_web_offer: true,
      avg_latency: 1086,
    },
    {
      type: 'task',
      lpid: 1394507,
      advertiser_id: 79183,
      account_manager_id: 115512,
      attribution_window: 30,
      comment: '',
      pid: 1001135,
      title: 'RoC-And-LVL17',
      mobile_title: 'Lead Generation Campaign',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1394507&ad_format=offer&group=Fyber&rank=7&gdpr_privacy_consent=0&sig=6da1d8a5b62d1005dc986b3d6dd316a193c1c1b0',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 415,
      rewd_amnt_pre_sale: 377,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/4bce883a0f39d1602f4b5c0fe0ad5920.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/102491/small_icon.png',
      icon: 'https://offerwall-acp-edge.s3.eu-west-1.amazonaws.com/cache/4bce883a0f39d1602f4b5c0fe0ad5920.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.lilithgame.roc.gp',
      size_tag: '101 MB',
      preview: true,
      preview_text:
        'Starten Sie Rise of Kingdoms. \nWerte dein Rathaus zu Level 17 auf\nHol dir Belohnungen!\n*Bitte erledigen Sie die Aufgabe in 19 Tagen\n*Die Registrierung eines NEUEN Kontos ist Voraussetzung für die Teilnahme an dieser Belohnung\n*Die Nutzer erhalten nur Prämien aus EINEM Angebot von Rise of Kingdoms',
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        { step: 'Start the game.', completed: false },
        { step: 'Get to level 17', completed: false },
        { step: 'Get the Magic Knife!', completed: false },
        {
          step: 'Get 19 gems',
          completed: false,
        },
        {
          step: 'Register a new account',
          completed: false,
        },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 16490,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 20615,
    },
    {
      type: 'task',
      lpid: 1417792,
      advertiser_id: 78999,
      account_manager_id: 114133,
      attribution_window: 30,
      comment: '',
      pid: 995705,
      title: 'SOS_Android_CPE_StateLv23_T1_Edge',
      mobile_title: 'State of Survival: Survive the Zomb',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1417792&ad_format=offer&group=Fyber&rank=8&gdpr_privacy_consent=0&sig=4c348f9254dbd9365aacdc92b2a38be853ce3aa8',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 488,
      rewd_amnt_pre_sale: 444,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/app_icons/106533/big_icon.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/106533/small_icon.png',
      icon: 'https://icons.fyber-static.com/app_icons/106533/big_icon.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.kingsgroup.sos',
      size_tag: '142 MB',
      preview: true,
      preview_text:
        'Offer Valid to Users Who Have Never Installed This Game Before!\n*Please complete the task within 30 days*\nUpgrade Settlement to Level 23 to Collect Reward!',
      is_download: false,
      is_video: false,
      initial_scarcity: 1,
      required_actions: '',
      action_steps: [
        {
          step: 'Offer Valid to Users Who Have Never Installed This Game Before!',
          completed: false,
        },
        {
          step: '*Please complete the task within 30 days*',
          completed: false,
        },
        {
          step: 'Upgrade Settlement to Level 23 to Collect Reward!',
          completed: false,
        },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 30,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 0,
    },
    {
      type: 'task',
      lpid: 1417181,
      advertiser_id: 79397,
      account_manager_id: 106955,
      attribution_window: 30,
      comment:
        '**Offer Is Valid ONLY to Users Who Has Never Installed The Game Before.\n**Offer Is Valid For 14 Days After Click and Install Days!',
      pid: 1006957,
      title: 'DT_600144_GP_BJ_L4_1127_LV60',
      mobile_title: 'Bingo Journey - Lucky & Fun Casino Bingo Games',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1417181&ad_format=offer&group=Fyber&rank=9&gdpr_privacy_consent=0&sig=9432d1014139d060a26beecf6f39303778827bd0',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 171,
      rewd_amnt_pre_sale: 155,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/app_icons/108509/big_icon.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/108509/small_icon.png',
      icon: 'https://icons.fyber-static.com/app_icons/108509/big_icon.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.bingo.scape.android.free',
      size_tag: '102 MB',
      preview: true,
      preview_text:
        'Join us for Bingo Journey\nReach up to Level60 in the game\nGet Rewarded!!!',
      is_download: false,
      is_video: false,
      initial_scarcity: 3,
      required_actions: '',
      action_steps: [
        { step: 'Join us for Bingo Journey', completed: false },
        { step: 'Reach up to Level60 in the game', completed: false },
        { step: 'Get Rewarded!!!', completed: false },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 30,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 100,
      mobile_app_primary_category: 'Casino',
      non_web_offer: true,
      avg_latency: 0,
    },
    {
      type: 'task',
      lpid: 1406406,
      advertiser_id: 79324,
      account_manager_id: 100012,
      attribution_window: 30,
      comment:
        'Complete the task in 15 calendar days.\nThe 1st calendar day starts when you download the app.\nFor new users only!',
      pid: 1004807,
      title: 'AP_MC_Android_WW_CPE_LVL15_15.09.2020',
      mobile_title: 'Complete Level 15 in My Cafe!',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1406406&ad_format=offer&group=Fyber&rank=10&gdpr_privacy_consent=0&sig=77b8fb3be2e7612de57d571ed33c09e114f73a96',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 156,
      rewd_amnt_pre_sale: 142,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires: 'https://icons.fyber-static.com/app_icons/59878/big_icon.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/59878/small_icon.png',
      icon: 'https://icons.fyber-static.com/app_icons/59878/big_icon.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.melesta.coffeeshop',
      size_tag: '40 MB',
      preview: true,
      preview_text: 'Get My Cafe\nPlay the game and complete level 15',
      is_download: false,
      is_video: false,
      initial_scarcity: 5,
      required_actions: '',
      action_steps: [
        { step: 'Get My Cafe', completed: false },
        { step: 'Play the game and complete level 15', completed: false },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 8710,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Casual',
      non_web_offer: true,
      avg_latency: 10891,
    },
    {
      type: 'task',
      lpid: 1401463,
      advertiser_id: 79352,
      account_manager_id: 115512,
      attribution_window: 30,
      comment:
        '*Bitte schließen Sie die Aufgabe innerhalb von 30 Tagen nach der Installation ab *Die Registrierung eines NEUEN Kontos ist Voraussetzung für die Teilnahme an dieser Belohnung.',
      pid: 1005493,
      title: 'WG-Android-T2-Centerlvl16',
      mobile_title: 'West Game',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1401463&ad_format=offer&group=Fyber&rank=11&gdpr_privacy_consent=0&sig=0befdb9ac6111da3828747653cc58136fbc2c68c',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 342,
      rewd_amnt_pre_sale: 311,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/app_icons/104601/big_icon.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/104601/small_icon.png',
      icon: 'https://icons.fyber-static.com/app_icons/104601/big_icon.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'leyi.westgame',
      size_tag: '117 MB',
      preview: true,
      preview_text:
        'Starten Sie West Game, \nErreiche Stadtzentrum Level 16,\nHol dir Belohnungen!',
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        { step: 'Starten Sie West Game,', completed: false },
        { step: 'Erreiche Stadtzentrum Level 16,', completed: false },
        { step: 'Hol dir Belohnungen!', completed: false },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 29895,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 37371,
    },
    {
      type: 'task',
      lpid: 1412058,
      advertiser_id: 79560,
      account_manager_id: 115512,
      attribution_window: 30,
      comment:
        '* Nur für neue Benutzer! Keine Belohnung, wenn du die andere Aufgabe dieses Spiels bereits erledigt hast! *',
      pid: 1011694,
      title: 'CE-AOS-CPE-3ORANGE',
      mobile_title: 'Clone Evolution',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1412058&ad_format=offer&group=Fyber&rank=12&gdpr_privacy_consent=0&sig=995b0a7605abe79d59d4dae59c0ddd9698e6655e',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 464,
      rewd_amnt_pre_sale: 422,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires: 'https://icons.fyber-static.com/app_icons/93483/big_icon.png',
      icon_lores:
        'https://icons.fyber-static.com/app_icons/93483/small_icon.png',
      icon: 'https://icons.fyber-static.com/app_icons/93483/big_icon.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.feelingtouch.clonewar',
      size_tag: '81 MB',
      preview: true,
      preview_text:
        'Öffne Clone Evolution und sammle Helden.\nEinige Helden mit niedrigem Rang können in Darwins Labor zu Helden mit hohem Rang aufgewertet werden.\nBringe drei orangefarbenen Helden dazu, diese Aufgabe zu erledigen!',
      is_download: false,
      is_video: false,
      initial_scarcity: 1,
      required_actions: '',
      action_steps: [
        {
          step: 'Öffne Clone Evolution und sammle Helden.',
          completed: false,
        },
        {
          step: 'Einige Helden mit niedrigem Rang können in Darwins Labor zu Helden mit hohem Rang aufgewertet werden.',
          completed: false,
        },
        {
          step: 'Bringe drei orangefarbenen Helden dazu, diese Aufgabe zu erledigen!',
          completed: false,
        },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 8575,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Role Playing',
      non_web_offer: true,
      avg_latency: 10721,
    },
  ],
}

export const offerResponsePageOne = {
  data_attrs: {
    'data-request_timestamp': 1642164816686,
    'data-userid': '10410106',
    'data-user-life-stage': 'has_conversion',
    'data-user-creation-timestamp': 1642158324,
    'data-appid': '45208',
    'data-placement_id': '',
    'data-country': 'RO',
    'data-language': 'en',
    'data-integration': 10,
    'data-filter': null,
    'data-external-ofw': false,
    'data-app-category': 'Lifestyle',
    'data-publisher-id': 65741,
    'data-user-has-ignite': true,
  },
  page: 1,
  pages: 8,
  is_last: false,
  virtual_currency: 'credits',
  virtual_currency_sale_enabled: false,
  mobile_scarcity: {
    '1431866': {
      original_quantity: 26,
      lifespan: 24126,
      timestamp: 1642158495,
      expire_at: 1642225821,
    },
    '1456863': {
      original_quantity: 57,
      lifespan: 16495,
      timestamp: 1642158495,
      expire_at: 1642218190,
    },
    '1459368': {
      original_quantity: 52,
      lifespan: 15527,
      timestamp: 1642158495,
      expire_at: 1642217222,
    },
  },
  in_progress_offers: false,
  in_progress_reward_amount: 0,
  offers: [
    {
      attribution_window: 60,
      type: 'task',
      lpid: 1448411,
      advertiser_id: 80092,
      account_manager_id: 115426,
      comment:
        'Read 5,000+ magazines and newspapers! New customers only. Canceled purchases will not be rewarded. ',
      pid: 1027441,
      title: 'Magzter GOLD 2 Year Offer',
      mobile_title: 'Subscribe to 2-Years of Magzter GOLD',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642164816686&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1448411&ad_format=offer&group=Fyber&rank=1&gdpr_privacy_consent=1&sig=b7b329f42ffa3b4f6dca8866a4eed15aeabfef77',
      is_free: false,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 20128,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/icon/large-ca0ca570f6068b6734cc569afe90c6a5.png',
      icon_lores:
        'https://icons.fyber-static.com/product/icon/small-03e3f4911906dfcd87cc07de9120b5f5.png',
      icon: 'https://icons.fyber-static.com/product/13791/icon/5a5e3c50e812cf267503ba1d347c0c0a.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68395/icos_ofw_purchase_original.svg',
      user_task: {
        id: 23,
        icon: 'https://icons.fyber-static.com/assets/68395/icos_ofw_purchase_original.svg',
      },
      is_highlighted: false,
      store_id: '',
      size_tag: false,
      preview: true,
      preview_text:
        'Visit the offer page and click on “Claim Now”\nEnter your email address and verify it using a one time password\nPurchase 2 Years of Magzter GOLD access to get your reward ',
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        {
          step: 'Visit the offer page and click on “Claim Now”',
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Enter your email address and verify it using a one time password',
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Purchase 2 Years of Magzter GOLD access to get your reward ',
          payout_distribution: 100,
          action_id: '2YMG',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 0,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_action',
      complexity: 2,
      mobile_app_primary_category: null,
      non_web_offer: false,
      avg_latency: 2,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 45,
      type: 'task',
      lpid: 1446177,
      advertiser_id: 79781,
      account_manager_id: 114133,
      comment:
        '**Offer Is Valid ONLY to Users Who Has Never Installed The Game Before.\n**Offer Is Valid For 30 Days After Click and Install Days!',
      pid: 1015108,
      title:
        'Fyber_CPE_Android_Evony_4Star_AE/BR/IL/IN/IT/MY/NO/PH/RO/SA/SE/TW_0803',
      mobile_title: "Evony: The king's Return, the real-time strategy Game",
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642164816686&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1446177&ad_format=offer&group=Fyber&rank=2&gdpr_privacy_consent=1&sig=8ecde29e0462af8962a1bd56b710b8a00d2b88f0',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 21786,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/29581/icon/large-056f098b77d773ddff980fb1c9dce374.gif',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/29581/icon/small-3fc7cc84c08e454392697bbd963937f5.gif',
      icon: 'https://icons.fyber-static.com/offercampaign/29581/icon/b3dfd94aa202051a949023f9aff6ead7.gif',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.topgamesinc.evony',
      size_tag: false,
      preview: true,
      preview_text:
        "Begin to play Evony: The King's Return\nGo to your General page\nUpgrade one General to  4 STARS \nGet Rewarded!!!",
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        {
          step: "Begin to play Evony: The King's Return",
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Go to your General page',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Upgrade one General to  4 STARS ',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Get Rewarded!!!',
          payout_distribution: null,
          action_id: '4STARS',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 17325,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 21662,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 35,
      type: 'task',
      lpid: 1436421,
      advertiser_id: 78997,
      account_manager_id: 114133,
      comment:
        'Tips:Build a mighty city, raise a greater army, master war strategy skills for an intelligent strategy to go to battle, train your magic dragon and wage war against your medieval enemy! ',
      pid: 996525,
      title: 'KOA_Android_CPE_Level23_T2_Edge',
      mobile_title: 'King of Avalon: Dominion',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642164816686&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1436421&ad_format=offer&group=Fyber&rank=3&gdpr_privacy_consent=1&sig=6aee04c4849fe61e65a4090a5021873a17b6361a',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 23680,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/19878/icon/large-abc4b9e149e3b6c8adb02bbb21460370.gif',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/19878/icon/small-8c31823cbae8e2fc879f20d9df64cfcf.gif',
      icon: 'https://icons.fyber-static.com/offercampaign/19878/icon/5f1f4d0178903b62110ba6ebbdedc389.gif',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.funplus.kingofavalon',
      size_tag: false,
      preview: true,
      preview_text:
        'Offer Valid to New Players Who have Never Installed the Game before\n*Please complete the task within 23 days*\n*User can only obtain reward from ONE King of Avalon offer*\nUpdate Your Stronghold Castle to Level 23 to Collect Reward! ',
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        {
          step: 'Offer Valid to New Players Who have Never Installed the Game before',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: '*Please complete the task within 23 days*',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: '*User can only obtain reward from ONE King of Avalon offer*',
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Update Your Stronghold Castle to Level 23 to Collect Reward! ',
          payout_distribution: null,
          action_id: 'CASTLELV23',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 18980,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 23726,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 45,
      type: 'task',
      lpid: 1444191,
      advertiser_id: 79781,
      account_manager_id: 114133,
      comment:
        "Enjoy the game Evony: The King's Return, Be the King of 7 kingdoms!\n\n**Offer Is Valid ONLY to Users Who Has Never Installed The Game Before.\n**Offer Is Valid For 20 Days After Click and Install Days!",
      pid: 1015108,
      title:
        'Fyber_CPE_Android_Evony_GoldenEquipment_AE/BR/IL/IN/IT/MY/NO/PH/RO/SA/SE/TW_0717',
      mobile_title: "Evony: The king's Return, the real-time strategy Game",
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642164816686&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1444191&ad_format=offer&group=Fyber&rank=4&gdpr_privacy_consent=1&sig=09f14d52f6641b87251d56bf0514d5cc8c99894b',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 21786,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/27606/icon/large-9f343ea7712af9b34dfd9dae0dfc522e.gif',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/27606/icon/small-112ee532ec01fcfff2f29c4ea748b62a.gif',
      icon: 'https://icons.fyber-static.com/offercampaign/27606/icon/5af606d9f43f3df392899390dab04daf.gif',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.topgamesinc.evony',
      size_tag: false,
      preview: true,
      preview_text:
        "Begin to play Evony: The King's Return\nOwn any gold or above equipment with 4 basic attributes \nGet Rewarded!!!",
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        {
          step: "Begin to play Evony: The King's Return",
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Own any gold or above equipment with 4 basic attributes ',
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Get Rewarded!!!',
          payout_distribution: 100,
          action_id: 'GOLDENEQUIPMENT',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 12375,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 15472,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 30,
      type: 'task',
      lpid: 1448264,
      advertiser_id: 79781,
      account_manager_id: 114133,
      comment:
        '**Offer Is Valid ONLY to Users Who Has Never Installed The Game Before.\n**Offer Is Valid For 25 Days After Click and Install Days!',
      pid: 1015108,
      title: 'Fyber_CPE_Android_Evony_PurpleCity_RO/TW/SE/PH_0817',
      mobile_title: "Evony: The king's Return, the real-time strategy Game",
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642164816686&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1448264&ad_format=offer&group=Fyber&rank=5&gdpr_privacy_consent=1&sig=c8179fb86fb01d470e0e2f26a4b805333fefb524',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 26143,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/31610/icon/large-edad0157026d52cd2f2039d7dc5bfedf.gif',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/31610/icon/small-09f5550afecc658bb1a8cdd8829e2cd1.gif',
      icon: 'https://icons.fyber-static.com/offercampaign/31610/icon/cc5c374c3f744217aabcb8133a02227d.gif',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.topgamesinc.evony',
      size_tag: false,
      preview: true,
      preview_text:
        "Begin to play Evony: The King's Return\nOccupy a legendary historical subordinate city\nGet Rewarded!!!",
      is_download: false,
      is_video: false,
      initial_scarcity: 1,
      required_actions: '',
      action_steps: [
        {
          step: "Begin to play Evony: The King's Return",
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Occupy a legendary historical subordinate city',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Get Rewarded!!!',
          payout_distribution: null,
          action_id: 'PURPLESUBCITY',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 30,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 100,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 0,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 35,
      type: 'task',
      lpid: 1464583,
      advertiser_id: 78997,
      account_manager_id: 114133,
      comment:
        'Secret: Participate in mission completions and New Guards themed events to advance easier and faster.\n*User can only obtain reward from ONE Guns of Glory offer*',
      pid: 996527,
      title: 'GOG_Android_CPE_CastleLV23_T3_Edge',
      mobile_title: 'Guns of Glory: Build an Epic Army for the Kingdom',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642164816686&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1464583&ad_format=offer&group=Fyber&rank=6&gdpr_privacy_consent=1&sig=afa13d9519a713ce8510b204471955303133d863',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 18944,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/icon/large-f64f6d41417ab4165fdcf06e9e217dc9.gif',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/icon/small-c237c3cdbbbe6440eb4d6fe7c9e5d1a6.gif',
      icon: 'https://icons.fyber-static.com/offercampaign/icon/ee0e76e67e61d78485faa7c49c642eca.gif',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.diandian.gog',
      size_tag: false,
      preview: true,
      preview_text:
        'Get ready for the next era of MMO war with the strategy classic Guns of Glory! \nOffer Valid to New Players Who have Never Installed the Game before\nReach Castle level 23 within 28 days to win total reward amount',
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        {
          step: 'Get ready for the next era of MMO war with the strategy classic Guns of Glory! ',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Offer Valid to New Players Who have Never Installed the Game before',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Reach Castle level 23 within 28 days to win total reward amount',
          payout_distribution: null,
          action_id: 'CTLEVEL23',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 27405,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 34261,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 60,
      type: 'task',
      lpid: 1459337,
      advertiser_id: 79977,
      account_manager_id: 115512,
      comment:
        '*NOTE: No reward if you installed this game before. Users will only obtain a reward from ONE Project Makeover offer',
      pid: 1030329,
      title: 'PM-AND-CPE-$19.99',
      mobile_title: 'Project Makeover',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642164816686&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1459337&ad_format=offer&group=Fyber&rank=7&gdpr_privacy_consent=1&sig=3ecb40ae445b22c4ea6cabcafcf8f83321edca6b',
      is_free: false,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 8288,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/16682/icon/large-ef9cce442da28567496561291bc69e00.jpg',
      icon_lores:
        'https://icons.fyber-static.com/product/16682/icon/small-0eea0f5ad0a3f74605b598e7239321d6.jpg',
      icon: 'https://icons.fyber-static.com/product/16682/icon/5b5fa5f1a90aa77594fe61c24edfe43e.jpg',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.bgg.jump',
      size_tag: false,
      preview: true,
      preview_text:
        'Play Project Makeover\nBuy High Heel Package in the Gem Store(19.99$)\nGet reward!',
      is_download: false,
      is_video: false,
      initial_scarcity: 4,
      required_actions: '',
      action_steps: [
        {
          step: 'Play Project Makeover',
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Buy High Heel Package in the Gem Store(19.99$)',
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Get reward!',
          payout_distribution: 100,
          action_id: '1999PACK',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 25,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 2,
      mobile_app_primary_category: 'Casual',
      non_web_offer: true,
      avg_latency: 35,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 30,
      type: 'task',
      lpid: 1433737,
      advertiser_id: 78997,
      account_manager_id: 114133,
      comment:
        'Secret: Participate in mission completions and New Guards themed events to advance easier and faster.\n*User can only obtain reward from ONE Guns of Glory offer*',
      pid: 996527,
      title: 'GOG_Android_CPE_CastleLV20_T3_Edge',
      mobile_title: 'Guns of Glory: Build an Epic Army for the Kingdom',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642164816686&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1433737&ad_format=offer&group=Fyber&rank=8&gdpr_privacy_consent=1&sig=edc4cbc075e999afd5e60e8b1340174aa449931a',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 13024,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/17200/icon/large-7068654a500d46b8502cd8a26c4da834.gif',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/17200/icon/small-bb1290925eb01ae57841c8361d103edc.gif',
      icon: 'https://icons.fyber-static.com/offercampaign/17200/icon/fd3a7282b3a3cdcb53546fbbf1803187.gif',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.diandian.gog',
      size_tag: false,
      preview: true,
      preview_text:
        'Offer Valid to New Players Who have Never Installed the Game before\n*Please complete the task within 28 days*\nUpdate your Castle to Level 20 to Collect Reward! ',
      is_download: false,
      is_video: false,
      initial_scarcity: 3,
      required_actions: '',
      action_steps: [
        {
          step: 'Offer Valid to New Players Who have Never Installed the Game before',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: '*Please complete the task within 28 days*',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Update your Castle to Level 20 to Collect Reward! ',
          payout_distribution: null,
          action_id: 'CTLEVEL20',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 20120,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 25151,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 30,
      type: 'task',
      lpid: 1436633,
      advertiser_id: 79346,
      account_manager_id: 112266,
      comment:
        '1. Download Solitaire Play\n2. Collect 7 decks\n3. Receive your reward!\n*New Users Only',
      pid: 1013412,
      title: '[g|Solitaire][p|android][id|11252]_ww_incent_7decks',
      mobile_title: 'Solitaire Play – Classic Klondike Patience Game',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642164816686&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1436633&ad_format=offer&group=Fyber&rank=9&gdpr_privacy_consent=1&sig=d55c84eeb44a1127a82e9a1e3406757d3e0e3084',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 73,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/icon/large-964921b5f273ee240a19d054a9641c19.png',
      icon_lores:
        'https://icons.fyber-static.com/product/icon/small-1ee78488d133719d9bc184ecc1fe1b15.png',
      icon: 'https://icons.fyber-static.com/product/1238/icon/eb4fc6fbe08e987debe9c469730dfdd0.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.murka.solitaire.play.klondike',
      size_tag: false,
      preview: true,
      preview_text:
        'Collect 7 decks of cards in Solitaire Play and get your Reward! ',
      is_download: false,
      is_video: false,
      initial_scarcity: 450,
      required_actions: '',
      action_steps: [
        {
          step: 'Collect 7 decks of cards in Solitaire Play and get your Reward! ',
          payout_distribution: 100,
          action_id: '7_DECKS',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 105,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 52,
      mobile_app_primary_category: 'Card',
      non_web_offer: true,
      avg_latency: 137,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 28,
      type: 'task',
      lpid: 1465511,
      advertiser_id: 79183,
      account_manager_id: 115512,
      comment:
        '*Please complete the task within 28 days**No reward if you installed this game before',
      pid: 1001135,
      title: ' RoC-And-ROW-MultiReward-1208',
      mobile_title: 'Rise of Kingdoms: Lost Crusade',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642164816686&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1465511&ad_format=offer&group=Fyber&rank=10&gdpr_privacy_consent=1&sig=665c1f74133fa5ece853c3d33cb516d8dd54b3bc',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 8759,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/43/icon/large-c43a38ccf08663c817b1e0e3f11a46b2.png',
      icon_lores:
        'https://icons.fyber-static.com/product/43/icon/small-017598c35f456d225513c97baccc5c28.png',
      icon: 'https://icons.fyber-static.com/product/43/icon/96e492c44519a7c24f62463c86aeef1d.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.lilithgame.roc.gp',
      size_tag: false,
      preview: true,
      preview_text:
        'Finish tutorial\nUpgrade your city to level 5\nUpgrade your city to level 10\nUpgrade your city to level 12\nUpgrade your city to level 14\nUpgrade your city to level 17\nUpgrade your city to level 20\nMake your first purchase in the game\nBonus - Purchuse 30-Day Gem supply (4.99$) in the Supply depot',
      is_download: false,
      is_video: false,
      initial_scarcity: 3,
      required_actions: '',
      action_steps: [
        {
          action_id: 'TUTORIAL',
          step: 'Finish tutorial',
          payout_distribution: 0.06,
          rewd_amnt: 5,
        },
        {
          action_id: 'LVL5',
          step: 'Upgrade your city to level 5',
          payout_distribution: 0.54,
          rewd_amnt: 47,
        },
        {
          action_id: 'LVL10',
          step: 'Upgrade your city to level 10',
          payout_distribution: 1.89,
          rewd_amnt: 166,
        },
        {
          action_id: 'LVL12',
          step: 'Upgrade your city to level 12',
          payout_distribution: 3.51,
          rewd_amnt: 307,
        },
        {
          action_id: 'LVL14',
          step: 'Upgrade your city to level 14',
          payout_distribution: 6.76,
          rewd_amnt: 592,
        },
        {
          action_id: 'LVL17',
          step: 'Upgrade your city to level 17',
          payout_distribution: 22.36,
          rewd_amnt: 1959,
        },
        {
          action_id: 'LVL20',
          step: 'Upgrade your city to level 20',
          payout_distribution: 32.44,
          rewd_amnt: 2841,
        },
        {
          action_id: 'FIRSTPURCHASE',
          step: 'Make your first purchase in the game',
          payout_distribution: 5.41,
          rewd_amnt: 474,
        },
        {
          action_id: '30DAY499PACK',
          step: 'Bonus - Purchuse 30-Day Gem supply (4.99$) in the Supply depot',
          payout_distribution: 27.03,
          rewd_amnt: 2368,
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 70,
      dynamic_payout_enabled: true,
      cost_model: 'cost_per_engagement',
      complexity: 52,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 92,
      multi_payout: true,
      instruction_steps: [
        'Play Rise of Kingdoms. ',
        'Finish listed tasks and earn multiple rewards along the way',
      ],
      asset_urls: [null],
    },
    {
      attribution_window: 60,
      type: 'task',
      lpid: 1412425,
      advertiser_id: 79385,
      account_manager_id: 116530,
      comment: 'This offer is available for NEW users only!',
      pid: 1007377,
      title: 'DE-Tier1-Android-PPE-30',
      mobile_title: 'Play the game and construct a Colony on Mars!',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642164816686&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1412425&ad_format=offer&group=Fyber&rank=11&gdpr_privacy_consent=1&sig=13717dca1a0ccd9e848e2361f50b518861f01c59',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 95,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/icon/large-2688aec2cf6c6b0c5e1e2aadafcb0b2a.png',
      icon_lores:
        'https://icons.fyber-static.com/product/icon/small-dc23898fc31ed093722ed07a5c647549.png',
      icon: 'https://icons.fyber-static.com/product/115/icon/b3b430bd0f811799c12c243cdb542c08.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.mysterytag.donaldsempire',
      size_tag: false,
      preview: true,
      preview_text:
        'Download and open the app;\nBuild a Colony on Mars in the Construction section.',
      is_download: false,
      is_video: false,
      initial_scarcity: 227,
      required_actions: '',
      action_steps: [
        {
          step: 'Download and open the app;',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Build a Colony on Mars in the Construction section.',
          payout_distribution: null,
          action_id: 'COLONYONMARS',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 1275,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Simulation',
      non_web_offer: true,
      avg_latency: 1595,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 60,
      type: 'task',
      lpid: 1466291,
      advertiser_id: 79385,
      account_manager_id: 116530,
      comment: 'This offer is available for NEW users only!',
      pid: 1007377,
      title: 'DE-WW-Android-PPE-YSub',
      mobile_title: 'Get a Year Subscription!',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642164816686&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1466291&ad_format=offer&group=Fyber&rank=12&gdpr_privacy_consent=1&sig=b4120db9df34f26c6e6a80ff26312bf2b6b4422f',
      is_free: false,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 1184,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/icon/large-2688aec2cf6c6b0c5e1e2aadafcb0b2a.png',
      icon_lores:
        'https://icons.fyber-static.com/product/icon/small-dc23898fc31ed093722ed07a5c647549.png',
      icon: 'https://icons.fyber-static.com/product/115/icon/b3b430bd0f811799c12c243cdb542c08.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.mysterytag.donaldsempire',
      size_tag: false,
      preview: true,
      preview_text:
        "Get Trump's Empire from the Google Play\nPlay the game and get a Year Subscription",
      is_download: false,
      is_video: false,
      initial_scarcity: 29,
      required_actions: '',
      action_steps: [
        {
          step: "Get Trump's Empire from the Google Play",
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Play the game and get a Year Subscription',
          payout_distribution: 100,
          action_id: 'YEAR_SUBSCRIPTION',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 30,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 2,
      mobile_app_primary_category: 'Simulation',
      non_web_offer: true,
      avg_latency: 0,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 60,
      type: 'task',
      lpid: 1454896,
      advertiser_id: 79488,
      account_manager_id: 116530,
      comment:
        'Această ofertă este destinată numai utilizatorilor noi!\nCosturi utilizator: 0,06EUR + TVA.',
      pid: 1010565,
      title: '11661 RO - CPA - Funtoonz - Download Content Teal',
      mobile_title: 'Obțineți cel mai recent conținut pentru telefon!',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642164816686&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1454896&ad_format=offer&group=Fyber&rank=13&gdpr_privacy_consent=1&sig=77a15efa9f5c4fb255c3eb20adcf4c535ec8ace1',
      is_free: false,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 1421,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/icon/large-a847c4f13e6b6b8dcc45c0212203d15a.jpg',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/icon/small-eba3ec1e9b3be432769d7b41a859eae5.jpg',
      icon: 'https://icons.fyber-static.com/offercampaign/38189/icon/fb353bc71d3332400aba52c49c2dd703.jpg',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68395/icos_ofw_purchase_original.svg',
      user_task: {
        id: 23,
        icon: 'https://icons.fyber-static.com/assets/68395/icos_ofw_purchase_original.svg',
      },
      is_highlighted: false,
      store_id: '',
      size_tag: false,
      preview: true,
      preview_text:
        'Trimiteți OK la 1255\nUtilizatorul trebuie să confirme cu „da”\nObțineți cel mai bun conținut pentru telefonul dvs.\nFii recompensat',
      is_download: false,
      is_video: false,
      initial_scarcity: 21,
      required_actions: '',
      action_steps: [
        {
          step: 'Trimiteți OK la 1255',
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Utilizatorul trebuie să confirme cu „da”',
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Obțineți cel mai bun conținut pentru telefonul dvs.',
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Fii recompensat',
          payout_distribution: 100,
          action_id: '11661',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 0,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_action',
      complexity: 2,
      mobile_app_primary_category: null,
      non_web_offer: false,
      avg_latency: 2,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 14,
      type: 'task',
      lpid: 1435322,
      advertiser_id: 79564,
      account_manager_id: 112266,
      comment:
        '**Offer is valid for new users only!\n**Offer is valid for 14 days after the installation date!',
      pid: 1012159,
      title: '0|dream-gym-14622200|And|WW|offer|fy|lvl22|1008',
      mobile_title: 'My Gym: Fitness Studio Manager',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642164816686&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1435322&ad_format=offer&group=Fyber&rank=14&gdpr_privacy_consent=1&sig=0fa1356dc0c22dfae02068e10a38be293add9415',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 829,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/18782/icon/large-88526d5142cc85e806f3086adb30ac21.gif',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/18782/icon/small-c6c7bc51f0c25a15b9145307418d213f.gif',
      icon: 'https://icons.fyber-static.com/offercampaign/18782/icon/8f561e4443ea2d524faa022f2776cfca.gif',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.tatemgames.dreamgym',
      size_tag: false,
      preview: true,
      preview_text:
        'Install My Gym: Fitness Studio Manager\nAchieve level 22 in the game!\nClaim Your Reward!',
      is_download: false,
      is_video: false,
      initial_scarcity: 38,
      required_actions: '',
      action_steps: [
        {
          step: 'Install My Gym: Fitness Studio Manager',
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Achieve level 22 in the game!',
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Claim Your Reward!',
          payout_distribution: 100,
          action_id: 'LEVEL22',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 10945,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Simulation',
      non_web_offer: true,
      avg_latency: 13683,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 4,
      type: 'task',
      lpid: 1473947,
      advertiser_id: 80069,
      account_manager_id: 115512,
      comment:
        '**Valabil DOAR pentru utilizatorii noi ai jocului\n**Oferta este valabilă timp de 3 zile după zilele de click și instalare!',
      pid: 1036767,
      title: 'EMOD-Android-CPE-RO-0112',
      mobile_title: 'Emoji Dodge',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642164816686&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1473947&ad_format=offer&group=Fyber&rank=15&gdpr_privacy_consent=1&sig=1107fd2b037e185c3ccbd2c452569acc92c05e1c',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 474,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/icon/large-ce06ceb86e4a1457ef3b1ecef80f6e34.gif',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/icon/small-6e0c6a0480e7fea06c9a01806bb6bc19.gif',
      icon: 'https://icons.fyber-static.com/offercampaign/icon/5b231e2f994632a0d1e12c6980cf9f22.gif',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.reddotme.emoji.dogde.master',
      size_tag: false,
      preview: true,
      preview_text:
        'Completați 50 de niveluri\nCompletați 100 de niveluri\nCompletați 200 de niveluri',
      is_download: false,
      is_video: false,
      initial_scarcity: 65,
      required_actions: '',
      action_steps: [
        {
          action_id: '50LEVELS',
          step: 'Completați 50 de niveluri',
          payout_distribution: 3.5,
          rewd_amnt: 17,
        },
        {
          action_id: '100LEVELS',
          step: 'Completați 100 de niveluri',
          payout_distribution: 7,
          rewd_amnt: 33,
        },
        {
          action_id: '200LEVELS',
          step: 'Completați 200 de niveluri',
          payout_distribution: 89.5,
          rewd_amnt: 424,
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 30,
      dynamic_payout_enabled: true,
      cost_model: 'cost_per_engagement',
      complexity: 50,
      mobile_app_primary_category: 'Casual',
      non_web_offer: true,
      avg_latency: 0,
      multi_payout: true,
      instruction_steps: [
        'Joacă Emoji Dodge (te rugăm să rămâi conectat la internet în timp ce joci pentru a asigura trimiterea semnalului de finalizare)',
        'Completați toți pașii și câștigați recompense pe parcurs',
        'Completați 200 de niveluri pentru a câștiga suma totală a recompensei!',
      ],
      asset_urls: [null],
    },
    {
      attribution_window: 21,
      type: 'task',
      lpid: 1393299,
      advertiser_id: 79183,
      account_manager_id: 115512,
      comment: '',
      pid: 1001135,
      title: 'RoC-And-FP',
      mobile_title: 'Rise of Kingdoms: Lost Crusade',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642164816686&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1393299&ad_format=offer&group=Fyber&rank=16&gdpr_privacy_consent=1&sig=7c4a26b48bf5d0c33e8c27038a67a6095b93d3a1',
      is_free: false,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 2368,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/214/icon/large-9aa174e66d2bd474ea9ccb4e00e6db17.gif',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/214/icon/small-8f9f81ba85d4651a74e08444fc5f7dc2.gif',
      icon: 'https://icons.fyber-static.com/offercampaign/214/icon/cc0e925c5cc5c7da95a3ba0c0d8194fa.gif',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.lilithgame.roc.gp',
      size_tag: false,
      preview: true,
      preview_text:
        'Play Rise of Kingdoms\nMake your first purchase in game\nHere comes your reward! \n*Please complete the task within 20 days*\n*No reward if you installed this game before*\n*User can only obtain reward from ONE Rise of Kingdoms offer*',
      is_download: false,
      is_video: false,
      initial_scarcity: 13,
      required_actions: '',
      action_steps: [
        {
          step: 'Play Rise of Kingdoms',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Make your first purchase in game',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Here comes your reward! ',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: '*Please complete the task within 20 days*',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: '*No reward if you installed this game before*',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: '*User can only obtain reward from ONE Rise of Kingdoms offer*',
          payout_distribution: null,
          action_id: 'FIRSTPURCHASE',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 20,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 2,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 29,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 5,
      type: 'task',
      lpid: 1462938,
      advertiser_id: 80069,
      account_manager_id: 115512,
      comment:
        '**Valabil DOAR pentru utilizatorii noi ai jocului\n**Oferta este valabilă timp de 4 zile după zilele de click și instalare!',
      pid: 1026302,
      title: 'BJ-Android-CPE-RO-2311',
      mobile_title: 'Blockscapes Jewel - Bloc de Joc Puzzle ',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642164816686&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1462938&ad_format=offer&group=Fyber&rank=17&gdpr_privacy_consent=1&sig=801232c05deb88d6aad648e44126643681cee49b',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 1008,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/icon/large-749f81b63cb31d3cf59473da4b473a3d.gif',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/icon/small-1531c41dd5744f814a9e307821eef5f0.gif',
      icon: 'https://icons.fyber-static.com/offercampaign/icon/99b3bf8765c2007fe5c6e94e44bcd55a.gif',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.reddotme.blockscapes.jewel',
      size_tag: false,
      preview: true,
      preview_text:
        'Completează nivelul 15\nCompletează nivelul 20\nCompletează nivelul 25',
      is_download: false,
      is_video: false,
      initial_scarcity: 34,
      required_actions: '',
      action_steps: [
        {
          action_id: 'LEVEL15',
          step: 'Completează nivelul 15',
          payout_distribution: 6.58,
          rewd_amnt: 66,
        },
        {
          action_id: 'LEVEL20',
          step: 'Completează nivelul 20',
          payout_distribution: 11.24,
          rewd_amnt: 113,
        },
        {
          action_id: 'LEVEL25',
          step: 'Completează nivelul 25',
          payout_distribution: 82.18,
          rewd_amnt: 829,
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 4070,
      dynamic_payout_enabled: true,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Puzzle',
      non_web_offer: true,
      avg_latency: 5090,
      multi_payout: true,
      instruction_steps: [
        'Joacă Blockscapes Jewel (te rugăm să rămâi conectat la internet în timp ce joci pentru a asigura trimiterea semnalului de finalizare)',
        'Completați toți pașii și câștigați recompense pe parcurs',
        'Completează nivelul 25 pentru a câștiga suma totală de recompensă!',
      ],
      asset_urls: [null],
    },
    {
      attribution_window: 60,
      type: 'task',
      lpid: 1466623,
      advertiser_id: 79385,
      account_manager_id: 116530,
      comment: 'This offer is available for NEW users only!',
      pid: 1007377,
      title: 'DE-WW-Android-PPE-40',
      mobile_title: 'Buy Your own cryptocurrency 3 times!',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642164816686&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1466623&ad_format=offer&group=Fyber&rank=18&gdpr_privacy_consent=1&sig=cda1cfb3fcb74250253e04781ade52f711ee888e',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 118,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/icon/large-2688aec2cf6c6b0c5e1e2aadafcb0b2a.png',
      icon_lores:
        'https://icons.fyber-static.com/product/icon/small-dc23898fc31ed093722ed07a5c647549.png',
      icon: 'https://icons.fyber-static.com/product/115/icon/b3b430bd0f811799c12c243cdb542c08.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.mysterytag.donaldsempire',
      size_tag: false,
      preview: true,
      preview_text:
        'Download and open the app;\nPlay the game and buy 3 times Your own cryptocurrency in the Business section ',
      is_download: false,
      is_video: false,
      initial_scarcity: 233,
      required_actions: '',
      action_steps: [
        {
          step: 'Download and open the app;',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Play the game and buy 3 times Your own cryptocurrency in the Business section ',
          payout_distribution: null,
          action_id: 'YOUR_OWN_CRYPTOCURRENCY_3T',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 990,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 52,
      mobile_app_primary_category: 'Simulation',
      non_web_offer: true,
      avg_latency: 1242,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 30,
      type: 'task',
      lpid: 1452424,
      advertiser_id: 79346,
      account_manager_id: 112266,
      comment: '*New users only ',
      pid: 1028873,
      title: '[g|Mdxspider][p|android][id|12724]_ww_incent_10lvl',
      mobile_title: ' Spider Solitaire Deluxe 2 ',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642164816686&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1452424&ad_format=offer&group=Fyber&rank=19&gdpr_privacy_consent=1&sig=24850f4900306d8218cd4f5e20a8e7c3e8b30afc',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 59,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/icon/large-7b3dd4514309cfbc1c7ffd28059894f0.png',
      icon_lores:
        'https://icons.fyber-static.com/product/icon/small-137673dd05a6e1d76eecf585aeb48595.png',
      icon: 'https://icons.fyber-static.com/product/15225/icon/9a815ed1bc87a3fd3fb42894bc3bbb40.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.gosub60.sol5spdr2',
      size_tag: false,
      preview: true,
      preview_text:
        'Play Spider Solitaire Deluxe 2, Reach Level 10 and Get Your Reward! ',
      is_download: false,
      is_video: false,
      initial_scarcity: 411,
      required_actions: '',
      action_steps: [
        {
          step: 'Play Spider Solitaire Deluxe 2, Reach Level 10 and Get Your Reward! ',
          payout_distribution: 100,
          action_id: 'LVL10',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 4640,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Card',
      non_web_offer: true,
      avg_latency: 5803,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 14,
      type: 'task',
      lpid: 1474133,
      advertiser_id: 80280,
      account_manager_id: 112266,
      comment:
        '**Offer is valid for new users only!\n** Offer must be completed within 14 days!',
      pid: 1035945,
      title: 'FYBER_MM_900_13.01',
      mobile_title: 'Download, install, and reach a depth of 900',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642164816686&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1474133&ad_format=offer&group=Fyber&rank=20&gdpr_privacy_consent=1&sig=24fa9d2ec46bb503382510a3f7f64bfb2bfdbe61',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 308,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/21160/icon/large-0d334dafa2e8f022ca245f0751dece2e.png',
      icon_lores:
        'https://icons.fyber-static.com/product/21160/icon/small-f1ae787b38474f9d78da66cdb17a5bcc.png',
      icon: 'https://icons.fyber-static.com/product/21160/icon/00c9bb51b41a2ad8b7f39e1138df8f24.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'games.raymobile.magnetminer',
      size_tag: false,
      preview: true,
      preview_text: 'Open Magnet Miner\nDig up to 900 Meter\nGet Rewarded!',
      is_download: false,
      is_video: false,
      initial_scarcity: 77,
      required_actions: '',
      action_steps: [
        {
          step: 'Open Magnet Miner',
          payout_distribution: 0,
          action_id: '',
        },
        {
          step: 'Dig up to 900 Meter',
          payout_distribution: 0,
          action_id: '',
        },
        {
          step: 'Get Rewarded!',
          payout_distribution: 0,
          action_id: '900METER',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 30,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 100,
      mobile_app_primary_category: 'Casual',
      non_web_offer: true,
      avg_latency: 0,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
  ],
  reengagement: {},
}

export const OfferResponsePageTwo = {
  data_attrs: {
    'data-request_timestamp': 1642165123166,
    'data-userid': '10410106',
    'data-user-life-stage': 'has_conversion',
    'data-user-creation-timestamp': 1642158324,
    'data-appid': '45208',
    'data-placement_id': '',
    'data-country': 'RO',
    'data-language': 'en',
    'data-integration': 10,
    'data-filter': null,
    'data-external-ofw': false,
    'data-app-category': 'Lifestyle',
    'data-publisher-id': 65741,
    'data-user-has-ignite': true,
  },
  page: 2,
  pages: 8,
  is_last: false,
  virtual_currency: 'credits',
  virtual_currency_sale_enabled: false,
  mobile_scarcity: {
    '1431866': {
      original_quantity: 26,
      lifespan: 24126,
      timestamp: 1642158495,
      expire_at: 1642225821,
    },
    '1456863': {
      original_quantity: 57,
      lifespan: 16495,
      timestamp: 1642158495,
      expire_at: 1642218190,
    },
    '1459368': {
      original_quantity: 52,
      lifespan: 15527,
      timestamp: 1642158495,
      expire_at: 1642217222,
    },
  },
  in_progress_offers: false,
  in_progress_reward_amount: 0,
  offers: [
    {
      attribution_window: 15,
      type: 'task',
      lpid: 1424071,
      advertiser_id: 79781,
      account_manager_id: 114133,
      comment:
        '**Offer Is Valid ONLY to Users Who Has Never Installed The Game Before.\n**Offer Is Valid For 10 Days After Click and Install Days!',
      pid: 1015108,
      title:
        'Fyber_CPE_Android_Evony_3GoldenGenerals_FR_IL_RO_IT_TH_NL_SE_0127',
      mobile_title: "Evony: The king's Return, the real-time strategy Game",
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642165123166&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1424071&ad_format=offer&group=Fyber&rank=21&gdpr_privacy_consent=1&sig=768a062691c0f6cb29182bcba558987788c62608',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 22,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/7739/icon/large-09442d4432ccdc2fbe46a8c35a506dca.gif',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/7739/icon/small-17bb3e2521058d1b734650f02382f6b2.gif',
      icon: 'https://icons.fyber-static.com/offercampaign/7739/icon/bc29a48ed8db16ae3ebfe44895d99e94.gif',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.topgamesinc.evony',
      size_tag: false,
      preview: true,
      preview_text:
        'Begin to play Evony: The King\'s Return\nGet 3 Generals with "Golden Frame" \nGet Rewarded!!!',
      is_download: false,
      is_video: false,
      initial_scarcity: 1134,
      required_actions: '',
      action_steps: [
        {
          step: "Begin to play Evony: The King's Return",
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Get 3 Generals with "Golden Frame" ',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Get Rewarded!!!',
          payout_distribution: null,
          action_id: '3GOLDENGENERALS',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 490,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 52,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 613,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 60,
      type: 'task',
      lpid: 1437532,
      advertiser_id: 79522,
      account_manager_id: 112266,
      comment:
        'Welcome to Quiz Daily! Answer 12 short multiple-choice questions in this fun and entertaining quiz. Score 80% to obtain your reward.',
      pid: 1023409,
      title: 'gfy_ww_pho-tab_12q_quiz-daily',
      mobile_title: '3 Minute Daily Quiz!',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642165123166&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1437532&ad_format=offer&group=Fyber&rank=22&gdpr_privacy_consent=1&sig=d5444a568dbc391b8df4f74f107644e60526c1d6',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 118,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/icon/large-eec80b69dd111b9a5b657f1475eae234.gif',
      icon_lores:
        'https://icons.fyber-static.com/product/icon/small-8f2048833a8b8ce2fab80d625c9be782.gif',
      icon: 'https://icons.fyber-static.com/product/9768/icon/4ea92ecd0c6574cf5d444aba7ddf233e.gif',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68393/icos_ofw_survey_original.svg',
      user_task: {
        id: 28,
        icon: 'https://icons.fyber-static.com/assets/68393/icos_ofw_survey_original.svg',
      },
      is_highlighted: false,
      store_id: '',
      size_tag: false,
      preview: true,
      preview_text:
        'Click the link below to start a game\nAnswer all questions and score at least 80%\nClaim your reward',
      is_download: false,
      is_video: false,
      initial_scarcity: 220,
      required_actions: '',
      action_steps: [
        {
          step: 'Click the link below to start a game',
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Answer all questions and score at least 80%',
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Claim your reward',
          payout_distribution: 100,
          action_id: 'QUIZ',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 5,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_action',
      complexity: 2,
      mobile_app_primary_category: null,
      non_web_offer: false,
      avg_latency: 11,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 35,
      type: 'task',
      lpid: 1418797,
      advertiser_id: 78997,
      account_manager_id: 114133,
      comment:
        'Tips:Build a mighty city, raise a greater army, master war strategy skills for an intelligent strategy to go to battle, train your magic dragon and wage war against your medieval enemy! ',
      pid: 996525,
      title: 'KOA_Android_CPE_Level19_T2_Edge',
      mobile_title: 'King of Avalon: Dominion',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642165123166&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1418797&ad_format=offer&group=Fyber&rank=23&gdpr_privacy_consent=1&sig=28cfb423c32dd13953ac791ba1ac20cb433fe08f',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 5920,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/3546/icon/large-e22bd03496a5e08fa234f7216ff6d0ad.gif',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/3546/icon/small-70244ef9e51db869bcaee61e533eaf62.gif',
      icon: 'https://icons.fyber-static.com/offercampaign/3546/icon/77b97282d6685c915bcfce713d9c02a3.gif',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.funplus.kingofavalon',
      size_tag: false,
      preview: true,
      preview_text:
        'Offer Valid to New Players Who have Never Installed the Game before\n*Please complete the task within 28 days*\n*User can only obtain reward from ONE King of Avalon offer*\nUpdate Your Stronghold Castle to Level 19 to Collect Reward! ',
      is_download: false,
      is_video: false,
      initial_scarcity: 5,
      required_actions: '',
      action_steps: [
        {
          step: 'Offer Valid to New Players Who have Never Installed the Game before',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: '*Please complete the task within 28 days*',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: '*User can only obtain reward from ONE King of Avalon offer*',
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Update Your Stronghold Castle to Level 19 to Collect Reward! ',
          payout_distribution: null,
          action_id: 'CASTLELV19',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 14430,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 18043,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 60,
      type: 'task',
      lpid: 1457853,
      advertiser_id: 79385,
      account_manager_id: 116530,
      comment: 'This offer is available for NEW users only!',
      pid: 1030683,
      title: 'MDA-WW-Android-PPE-70',
      mobile_title: 'Reach level 70 in the game!',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642165123166&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1457853&ad_format=offer&group=Fyber&rank=24&gdpr_privacy_consent=1&sig=515a4447febc37741ce26ac159e52dbd88aaa330',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 142,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/icon/large-a3af70456ef6d377681b87ba2da56905.jpg',
      icon_lores:
        'https://icons.fyber-static.com/product/icon/small-b9d506cc413527a06ce4bbf0fefa9ac2.jpg',
      icon: 'https://icons.fyber-static.com/product/17036/icon/0d745a96481e203e65b48361ff5c824c.jpg',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.mysterytag.md3adventures',
      size_tag: false,
      preview: true,
      preview_text: 'Download and open the app;\nSuccessfully reach level 70.',
      is_download: false,
      is_video: false,
      initial_scarcity: 171,
      required_actions: '',
      action_steps: [
        {
          step: 'Download and open the app;',
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Successfully reach level 70.',
          payout_distribution: 100,
          action_id: 'LEVEL70',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 5,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 2,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 7,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 35,
      type: 'task',
      lpid: 1474123,
      advertiser_id: 78997,
      account_manager_id: 114133,
      comment:
        'Tips:Build a mighty city, raise a greater army, master war strategy skills for an intelligent strategy to go to battle, train your magic dragon and wage war against your medieval enemy! ',
      pid: 996525,
      title: 'KOA_Android_CPE_DragonLevel26_T2_Edge',
      mobile_title: 'King of Avalon - Level up your dragon',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642165123166&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1474123&ad_format=offer&group=Fyber&rank=25&gdpr_privacy_consent=1&sig=343dd7ccbe0881a05977d3e555262a792e423183',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 18944,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/icon/large-b4967492aeeaac640d72307a1b347569.gif',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/icon/small-32d6ee77b352bfcee4dc77a065950339.gif',
      icon: 'https://icons.fyber-static.com/offercampaign/icon/791dd9b664f4473d3dea87343e4b8a79.gif',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.funplus.kingofavalon',
      size_tag: false,
      preview: true,
      preview_text:
        'Offer Valid to New Players Who have Never Installed the Game before\n*User can only obtain reward from ONE King of Avalon offer*\nTrain and level up your dragon to Level 26 within 28 days.',
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        {
          step: 'Offer Valid to New Players Who have Never Installed the Game before',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: '*User can only obtain reward from ONE King of Avalon offer*',
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Train and level up your dragon to Level 26 within 28 days.',
          payout_distribution: null,
          action_id: 'DRAGON26',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 30,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 0,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 60,
      type: 'task',
      lpid: 1458617,
      advertiser_id: 79994,
      account_manager_id: 115426,
      comment:
        'Luați câte doriți și primiți recompensa! Sunt sondaje noi în fiecare zi!',
      pid: 1029108,
      title: '(Duplicate of #1454770) Opinion Labs',
      mobile_title: 'Laboratoare de opinie: Sondaje',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642165123166&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1458617&ad_format=offer&group=Fyber&rank=26&gdpr_privacy_consent=1&sig=06ff5c1a42489b644e2baff5d04815f1018ba97a',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 11840,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/icon/large-a89aaafedb301e5c117a0e7e766e1fe5.gif',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/icon/small-8db24f3a777fc83acb885594ef5cf954.gif',
      icon: 'https://icons.fyber-static.com/offercampaign/icon/70208b124faf85fc1c1d8cef6df4e4fb.gif',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68393/icos_ofw_survey_original.svg',
      user_task: {
        id: 8,
        icon: 'https://icons.fyber-static.com/assets/68393/icos_ofw_survey_original.svg',
      },
      is_highlighted: false,
      store_id: '',
      size_tag: false,
      preview: true,
      preview_text:
        'Câștigați recompense GRATUITE după ce participați la un sondaj!\nIntroduceți informațiile de profil pentru a începe.\nPuteți câștiga chiar dacă sondajul nu este complet.',
      is_download: false,
      is_video: false,
      initial_scarcity: 3,
      required_actions: '',
      action_steps: [
        {
          step: 'Câștigați recompense GRATUITE după ce participați la un sondaj!',
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Introduceți informațiile de profil pentru a începe.',
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Puteți câștiga chiar dacă sondajul nu este complet.',
          payout_distribution: 100,
          action_id: 'SURVEY',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 0,
      dynamic_payout_enabled: true,
      cost_model: 'cost_per_action',
      complexity: 2,
      mobile_app_primary_category: null,
      non_web_offer: false,
      avg_latency: 5,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 24,
      type: 'task',
      lpid: 1422858,
      advertiser_id: 79324,
      account_manager_id: 116530,
      comment:
        'Complete the task in 24 calendar days.\nThe 1st calendar day starts when you download the app.\nFor new users only!',
      pid: 1004807,
      title: 'AP_MC_Android_WW_CPE_LVL20_13.01.2021',
      mobile_title: 'Achieve Level 20 in My Cafe!',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642165123166&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1422858&ad_format=offer&group=Fyber&rank=27&gdpr_privacy_consent=1&sig=b93562ee6318a5d9dc4dfc9ae7885af9ec3a528e',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 1302,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/6630/icon/large-13c6746f0e2570aca5c388e0d935acd8.jpg',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/6630/icon/small-ec1aea7c8231e8b395db0bea0133cce5.jpg',
      icon: 'https://icons.fyber-static.com/offercampaign/6630/icon/9cf17e13af05d141dce15d92e957fcaf.jpg',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.melesta.coffeeshop',
      size_tag: false,
      preview: true,
      preview_text: 'Get My Cafe\nPlay the game and achieve level 20',
      is_download: false,
      is_video: false,
      initial_scarcity: 21,
      required_actions: '',
      action_steps: [
        {
          step: 'Get My Cafe',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Play the game and achieve level 20',
          payout_distribution: null,
          action_id: 'LVL20',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 9655,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Casual',
      non_web_offer: true,
      avg_latency: 12069,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 60,
      type: 'task',
      lpid: 1458279,
      advertiser_id: 79773,
      account_manager_id: 116530,
      comment: 'Reach Level 20. New Players only.',
      pid: 1025937,
      title: '889 WW',
      mobile_title: 'Install Mafia City and Reach Level 20!',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642165123166&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1458279&ad_format=offer&group=Fyber&rank=28&gdpr_privacy_consent=1&sig=cac3b8f8a73bf40093c8ab68cab6c285a1054a67',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 9472,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/12287/icon/large-f355f45deb0aebcb0c1806ad9efb7316.png',
      icon_lores:
        'https://icons.fyber-static.com/product/12287/icon/small-638aca035a3a10d2c240af3aad09ed42.png',
      icon: 'https://icons.fyber-static.com/product/12287/icon/2621d01453d6baeba394596577a02898.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.yottagames.mafiawar',
      size_tag: false,
      preview: true,
      preview_text: 'Install Mafia City\nReach level 20',
      is_download: false,
      is_video: false,
      initial_scarcity: 3,
      required_actions: '',
      action_steps: [
        {
          step: 'Install Mafia City',
          payout_distribution: 100,
          action_id: 'INSTALL',
        },
        {
          step: 'Reach level 20',
          payout_distribution: 100,
          action_id: 'LEVEL20',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 30395,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 37997,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 60,
      type: 'task',
      lpid: 1398809,
      advertiser_id: 79281,
      account_manager_id: 112266,
      comment:
        '**Offer Is Valid For New Users Only!!!\n**Offer Is Valid For 7 Days After Installation Date!!',
      pid: 1004119,
      title: 'Fyber_WW_PPP_0_HC_And_Abrosimova_07/2020_[[!idAd=3071771!]]',
      mobile_title: 'Hustle Castle - Make A Purchase!',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642165123166&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1398809&ad_format=offer&group=Fyber&rank=29&gdpr_privacy_consent=1&sig=caf41ef33164a94a907e65ff46b4a4d6040547dc',
      is_free: false,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 474,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/67/icon/large-fe9fce7aad0369419bf0eef046042656.png',
      icon_lores:
        'https://icons.fyber-static.com/product/67/icon/small-e78ff7c27acf0ba62f8f2e3a64b3e5d8.png',
      icon: 'https://icons.fyber-static.com/product/67/icon/c25ab08d4ac1589901ab09f8a6a9a9ca.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.my.hc.rpg.kingdom.simulator',
      size_tag: false,
      preview: true,
      preview_text: 'Open Hustle Castle\nMake A Purchase!\nGet Rewarded!!',
      is_download: false,
      is_video: false,
      initial_scarcity: 71,
      required_actions: '',
      action_steps: [
        {
          step: 'Open Hustle Castle',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Make A Purchase!',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Get Rewarded!!',
          payout_distribution: null,
          action_id: 'PURCHASE',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 150,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 52,
      mobile_app_primary_category: 'Role Playing',
      non_web_offer: true,
      avg_latency: 191,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 60,
      type: 'task',
      lpid: 1446587,
      advertiser_id: 79994,
      account_manager_id: 115426,
      comment: 'Answer the questions correctly.',
      pid: 1026847,
      title: 'Last of Us Quiz!',
      mobile_title: 'Last of Us Quiz!',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642165123166&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1446587&ad_format=offer&group=Fyber&rank=30&gdpr_privacy_consent=1&sig=3eb482516211fdefe8e043297c8a6240a7738005',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 28,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/icon/large-e7c212c23d3830fa4499a0bcc008502e.png',
      icon_lores:
        'https://icons.fyber-static.com/product/icon/small-1944385ec27787dc545e3bbbfb2f93aa.png',
      icon: 'https://icons.fyber-static.com/product/13197/icon/f29baaf6b0aacd8af3c5d68a86504e20.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68393/icos_ofw_survey_original.svg',
      user_task: {
        id: 28,
        icon: 'https://icons.fyber-static.com/assets/68393/icos_ofw_survey_original.svg',
      },
      is_highlighted: false,
      store_id: '',
      size_tag: false,
      preview: true,
      preview_text: 'Find out how much you know about this hit game!',
      is_download: false,
      is_video: false,
      initial_scarcity: 813,
      required_actions: '',
      action_steps: [
        {
          step: 'Find out how much you know about this hit game!',
          payout_distribution: 100,
          action_id: 'FINISH',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 10,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_action',
      complexity: 2,
      mobile_app_primary_category: null,
      non_web_offer: false,
      avg_latency: 14,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 60,
      type: 'task',
      lpid: 1457863,
      advertiser_id: 79385,
      account_manager_id: 116530,
      comment: 'This offer is available for NEW users only!',
      pid: 1030683,
      title: 'MDA-WW-Android-PPE-Purchase',
      mobile_title: 'Make a purchase in the game!',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642165123166&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1457863&ad_format=offer&group=Fyber&rank=31&gdpr_privacy_consent=1&sig=c632a4a6c7db69e81d72866d1375e5aec591740c',
      is_free: false,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 118,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/icon/large-a3af70456ef6d377681b87ba2da56905.jpg',
      icon_lores:
        'https://icons.fyber-static.com/product/icon/small-b9d506cc413527a06ce4bbf0fefa9ac2.jpg',
      icon: 'https://icons.fyber-static.com/product/17036/icon/0d745a96481e203e65b48361ff5c824c.jpg',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.mysterytag.md3adventures',
      size_tag: false,
      preview: true,
      preview_text:
        'Download and open the app;\nPlay the game and make a purchase!',
      is_download: false,
      is_video: false,
      initial_scarcity: 221,
      required_actions: '',
      action_steps: [
        {
          step: 'Download and open the app;',
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Play the game and make a purchase!',
          payout_distribution: 100,
          action_id: 'PURCHASE',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 5,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 2,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 8,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 60,
      type: 'task',
      lpid: 1433481,
      advertiser_id: 79713,
      account_manager_id: 112266,
      comment: 'Complete 15 levels',
      pid: 1014434,
      title: 'level_15_n4',
      mobile_title: 'Annotate images for AI',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642165123166&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1433481&ad_format=offer&group=Fyber&rank=32&gdpr_privacy_consent=1&sig=f83e615f1e853d22fbddb263a417905ff5cdaa79',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 237,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/icon/large-37bc28a59ee4d9bd0d11184b9d6c57c0.png',
      icon_lores:
        'https://icons.fyber-static.com/product/icon/small-b264c6a45be26f603b43f098774d8ead.png',
      icon: 'https://icons.fyber-static.com/product/1754/icon/311a4e7d52c531e07594e73be06971c4.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68393/icos_ofw_survey_original.svg',
      user_task: {
        id: 8,
        icon: 'https://icons.fyber-static.com/assets/68393/icos_ofw_survey_original.svg',
      },
      is_highlighted: false,
      store_id: '',
      size_tag: false,
      preview: true,
      preview_text:
        'Follow instructions to correctly tag images \nComplete 15 levels\nAfter 15 levels, YOU WIN!!! Go get your reward.\nYou can complete this offer and receive the reward AGAIN and AGAIN!',
      is_download: false,
      is_video: false,
      initial_scarcity: 107,
      required_actions: '',
      action_steps: [
        {
          step: 'Follow instructions to correctly tag images ',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Complete 15 levels',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'After 15 levels, YOU WIN!!! Go get your reward.',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'You can complete this offer and receive the reward AGAIN and AGAIN!',
          payout_distribution: null,
          action_id: 'COMPLETE_15_LEVELS_N4',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 25,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_action',
      complexity: 2,
      mobile_app_primary_category: null,
      non_web_offer: false,
      avg_latency: 37,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 60,
      type: 'task',
      lpid: 1461913,
      advertiser_id: 79349,
      account_manager_id: 116530,
      comment: 'New Users Only',
      pid: 1032502,
      title: 'Fyber_WW_incent_playtime_Scratch4Cash_screentime60',
      mobile_title: 'Play 60 min to win your rewards',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642165123166&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1461913&ad_format=offer&group=Fyber&rank=33&gdpr_privacy_consent=1&sig=2dd575e3c699d01a5abff203c0e807831ae48255',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 237,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/18855/icon/large-22be10a7a4b6ff877b4a28c25e92a1f0.jpg',
      icon_lores:
        'https://icons.fyber-static.com/product/18855/icon/small-41bfec96e09070738a04a8af345b2912.jpg',
      icon: 'https://icons.fyber-static.com/product/18855/icon/a4ec476f3e82d3d220a7f0d34528babe.jpg',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.megastudio.scratch4cash',
      size_tag: false,
      preview: true,
      preview_text:
        'Install Scratch4Cash\nPlay at least for 60 minutes and get your reward!',
      is_download: false,
      is_video: false,
      initial_scarcity: 133,
      required_actions: '',
      action_steps: [
        {
          step: 'Install Scratch4Cash',
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Play at least for 60 minutes and get your reward!',
          payout_distribution: 100,
          action_id: 'SCREENTIME60',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 110,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 52,
      mobile_app_primary_category: 'Casual',
      non_web_offer: true,
      avg_latency: 143,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 60,
      type: 'task',
      lpid: 1465325,
      advertiser_id: 79385,
      account_manager_id: 116530,
      comment:
        'This offer is available for NEW users only! To get the reward, you have to make a purchase with the exact Case of crystals name.',
      pid: 1007377,
      title: 'DE-WW-Android-PPE-PurchaseCaseC',
      mobile_title: "Make a Case of crystals purchase in Trump's Empire!",
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642165123166&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1465325&ad_format=offer&group=Fyber&rank=34&gdpr_privacy_consent=1&sig=4201f30b122719989f0c932d026ca1729085d15f',
      is_free: false,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 237,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/icon/large-2688aec2cf6c6b0c5e1e2aadafcb0b2a.png',
      icon_lores:
        'https://icons.fyber-static.com/product/icon/small-dc23898fc31ed093722ed07a5c647549.png',
      icon: 'https://icons.fyber-static.com/product/115/icon/b3b430bd0f811799c12c243cdb542c08.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.mysterytag.donaldsempire',
      size_tag: false,
      preview: true,
      preview_text:
        "Get Trump's Empire from the Google Play\nPlay the game and make a Case of crystals purchase",
      is_download: false,
      is_video: false,
      initial_scarcity: 93,
      required_actions: '',
      action_steps: [
        {
          step: "Get Trump's Empire from the Google Play",
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Play the game and make a Case of crystals purchase',
          payout_distribution: 100,
          action_id: 'CASE_OF_CRYSTALS',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 30,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 2,
      mobile_app_primary_category: 'Simulation',
      non_web_offer: true,
      avg_latency: 0,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 15,
      type: 'task',
      lpid: 1424154,
      advertiser_id: 79781,
      account_manager_id: 114133,
      comment:
        '**Offer Is Valid ONLY to Users Who Has Never Installed The Game Before.\n**Offer Is Valid For 10 Days After Click and Install Days!',
      pid: 1015108,
      title: 'Fyber_CPE_Android_Evony_CityLevel21_ES_IL_RO_IT_TH_NL_SE_0127',
      mobile_title: "Evony: The king's Return, the real-time strategy Game",
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642165123166&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1424154&ad_format=offer&group=Fyber&rank=35&gdpr_privacy_consent=1&sig=038b3f059707957f875d6e609c338296a04d376b',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 22,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/7808/icon/large-1aca5ff37940b99db48973bd930b9395.gif',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/7808/icon/small-d7a69abad55234442fa077e72662c3cb.gif',
      icon: 'https://icons.fyber-static.com/offercampaign/7808/icon/78ef466424a1eef3b4efe9cf9cbf1ba0.gif',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.topgamesinc.evony',
      size_tag: false,
      preview: true,
      preview_text:
        "Begin to play Evony: The King's Return\nUpgrade your “Keep”（Main Castle） to level 21\nGet Rewarded!!!",
      is_download: false,
      is_video: false,
      initial_scarcity: 1402,
      required_actions: '',
      action_steps: [
        {
          step: "Begin to play Evony: The King's Return",
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Upgrade your “Keep”（Main Castle） to level 21',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Get Rewarded!!!',
          payout_distribution: null,
          action_id: 'CITYLEVEL21',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 10080,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 12606,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 15,
      type: 'task',
      lpid: 1461407,
      advertiser_id: 79781,
      account_manager_id: 114133,
      comment:
        '**Offer Is Valid ONLY to Users Who Has Never Installed The Game Before.\n**Offer Is Valid For 10 Days After Click and Install Days!',
      pid: 1016008,
      title: 'Fyber_CPE_Android_POZ_CityLevel16_ES_IL_RO_IT_TH_NL_SE_0127',
      mobile_title: 'Plague Of Z, the real-time strategy Game',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642165123166&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1461407&ad_format=offer&group=Fyber&rank=36&gdpr_privacy_consent=1&sig=7ab5079a86f37fde66ba3f9845ebb981a981cdc8',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 22,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/44667/icon/large-828d95cd633c606d3d9a8b4a3ba88e2a.gif',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/44667/icon/small-7d45c18e2578227384a8ce598a6a21d1.gif',
      icon: 'https://icons.fyber-static.com/offercampaign/44667/icon/da39710b334c41f2d57cd34f1d53c39e.gif',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.topgamesinc.zombies',
      size_tag: false,
      preview: true,
      preview_text:
        'Begin to play Plague Of Z\nUpgrade the Command Center to level 16\nGet Rewarded!!!',
      is_download: false,
      is_video: false,
      initial_scarcity: 1338,
      required_actions: '',
      action_steps: [
        {
          step: 'Begin to play Plague Of Z',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Upgrade the Command Center to level 16',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Get Rewarded!!!',
          payout_distribution: null,
          action_id: 'MIANCITYTO16',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 5000,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 6252,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 7,
      type: 'task',
      lpid: 1470429,
      advertiser_id: 80069,
      account_manager_id: 115512,
      comment:
        '**Valabil DOAR pentru utilizatorii noi ai jocului\n**Oferta este valabilă timp de 6 zile după zilele de click și instalare!',
      pid: 1035453,
      title: 'JC-Android-CPE-RO-1227',
      mobile_title: 'Junglă Conectează - Onet Link',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642165123166&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1470429&ad_format=offer&group=Fyber&rank=37&gdpr_privacy_consent=1&sig=b5d044f73080ceaf87af599bbed33effea0fb299',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 355,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/icon/large-1cc5347e4169f13d1d3903c0274980cb.gif',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/icon/small-fc90d6ce66843558cf159d77da0d3a2c.gif',
      icon: 'https://icons.fyber-static.com/offercampaign/icon/913601099a4deea456fb49f50fd0b474.gif',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.reddotme.jungle.onet.connect.puzzle',
      size_tag: false,
      preview: true,
      preview_text:
        'Completați modul Classic\nCompletați modul Extend \nCompletați modul Expert \nCompletați modul Master\nCompletați modul Super\nCompletați modul Hero\nCompletați modul Mania',
      is_download: false,
      is_video: false,
      initial_scarcity: 71,
      required_actions: '',
      action_steps: [
        {
          action_id: 'CLASSIC8',
          step: 'Completați modul Classic',
          payout_distribution: 2,
          rewd_amnt: 7,
        },
        {
          action_id: 'EXTEND70',
          step: 'Completați modul Extend ',
          payout_distribution: 2,
          rewd_amnt: 7,
        },
        {
          action_id: 'EXPERT56',
          step: 'Completați modul Expert ',
          payout_distribution: 5,
          rewd_amnt: 18,
        },
        {
          action_id: 'MASTER105',
          step: 'Completați modul Master',
          payout_distribution: 8,
          rewd_amnt: 28,
        },
        {
          action_id: 'SUPER49',
          step: 'Completați modul Super',
          payout_distribution: 9,
          rewd_amnt: 32,
        },
        {
          action_id: 'HERO91',
          step: 'Completați modul Hero',
          payout_distribution: 13,
          rewd_amnt: 46,
        },
        {
          action_id: 'MANIA42',
          step: 'Completați modul Mania',
          payout_distribution: 61,
          rewd_amnt: 217,
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 30,
      dynamic_payout_enabled: true,
      cost_model: 'cost_per_engagement',
      complexity: 50,
      mobile_app_primary_category: 'Puzzle',
      non_web_offer: true,
      avg_latency: 0,
      multi_payout: true,
      instruction_steps: [
        'Joacă Junglă Conectează (te rugăm să rămâi conectat la internet în timp ce joci pentru a asigura trimiterea semnalului de finalizare)',
        'Completați toți pașii și câștigați recompense pe parcurs',
        'Completați modul Mania pentru a câștiga suma totală a recompenselor!',
      ],
      asset_urls: [null],
    },
    {
      attribution_window: 60,
      type: 'task',
      lpid: 1474101,
      advertiser_id: 79773,
      account_manager_id: 116530,
      comment: 'New Players Only.',
      pid: 1032293,
      title: '999 WW',
      mobile_title: 'Install King of Avalon and complete the events!',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642165123166&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1474101&ad_format=offer&group=Fyber&rank=38&gdpr_privacy_consent=1&sig=d61c23e60ef92e4fe21eef93b004f73cf7eadc69',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 16623,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/icon/large-fb2ba488fcbeb3b410e06f5fd6e53d74.png',
      icon_lores:
        'https://icons.fyber-static.com/product/icon/small-3feb7ecf2609bf60a492dd9d2610beae.png',
      icon: 'https://icons.fyber-static.com/product/18646/icon/fa1e68375bfd94b78ab5d73f543e76b7.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.funplus.kingofavalon',
      size_tag: false,
      preview: true,
      preview_text:
        'Tutorial Complete\t\nDragon Level 10\nPurchase in-app package $4.99\nReach Dragon Level 16\nPurchase in-app package $9.99\nReach Dragon Level 20\nReach Dragon Level 24\nReach Dragon Level 28',
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        {
          action_id: 'TUTORIAL',
          step: 'Tutorial Complete\t',
          payout_distribution: 0.29,
          rewd_amnt: 48,
        },
        {
          action_id: 'LVL10',
          step: 'Dragon Level 10',
          payout_distribution: 0.71,
          rewd_amnt: 118,
        },
        {
          action_id: '499',
          step: 'Purchase in-app package $4.99',
          payout_distribution: 11.4,
          rewd_amnt: 1895,
        },
        {
          action_id: 'LVL16',
          step: 'Reach Dragon Level 16',
          payout_distribution: 2.14,
          rewd_amnt: 356,
        },
        {
          action_id: '999',
          step: 'Purchase in-app package $9.99',
          payout_distribution: 17.09,
          rewd_amnt: 2841,
        },
        {
          action_id: 'LVL20',
          step: 'Reach Dragon Level 20',
          payout_distribution: 11.39,
          rewd_amnt: 1893,
        },
        {
          action_id: 'LVL24',
          step: 'Reach Dragon Level 24',
          payout_distribution: 14.24,
          rewd_amnt: 2367,
        },
        {
          action_id: 'LVL28',
          step: 'Reach Dragon Level 28',
          payout_distribution: 42.74,
          rewd_amnt: 7105,
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 20,
      dynamic_payout_enabled: true,
      cost_model: 'cost_per_engagement',
      complexity: 2,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 31,
      multi_payout: true,
      instruction_steps: [
        'Tutorial Complete',
        'Reach Dragon Level 10',
        'Purchase in-app package $4.99',
        'Reach Dragon Level 16',
        'Purchase in-app package $9.99',
        'Reach Dragon Level 20',
        'Reach Dragon Level 24',
        'Reach Dragon Level 28',
      ],
      asset_urls: [null],
    },
    {
      attribution_window: 60,
      type: 'task',
      lpid: 1465247,
      advertiser_id: 79956,
      account_manager_id: 115426,
      comment: '',
      pid: 1033761,
      title: 'SRL - 3244680 - Web WW - 0712',
      mobile_title: 'Dating Survey Love Test',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642165123166&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1465247&ad_format=offer&group=Fyber&rank=39&gdpr_privacy_consent=1&sig=e53e08120943c632ba8ef418d4704a7eb50fe90d',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 28,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/icon/large-6bef86898c6abf6693ace8fbedd88e49.png',
      icon_lores:
        'https://icons.fyber-static.com/product/icon/small-55ea7091c077b42dfcb60d93d545cedb.png',
      icon: 'https://icons.fyber-static.com/product/20068/icon/4950856ff5c75d995202eb370adec3e0.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68393/icos_ofw_survey_original.svg',
      user_task: {
        id: 8,
        icon: 'https://icons.fyber-static.com/assets/68393/icos_ofw_survey_original.svg',
      },
      is_highlighted: false,
      store_id: '',
      size_tag: false,
      preview: true,
      preview_text: 'Complete the survey (free action)',
      is_download: false,
      is_video: false,
      initial_scarcity: 955,
      required_actions: '',
      action_steps: [
        {
          step: 'Complete the survey (free action)',
          payout_distribution: 0,
          action_id: 'REG',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 0,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_action',
      complexity: 2,
      mobile_app_primary_category: null,
      non_web_offer: false,
      avg_latency: 4,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 4,
      type: 'task',
      lpid: 1463116,
      advertiser_id: 80069,
      account_manager_id: 115512,
      comment:
        '*Valabil DOAR pentru utilizatorii noi ai jocului\n**NU poți urmări video pentru a sări peste nivel, altfel nu vei fi recompensat\n**Oferta este valabilă timp de 3 zile după zilele de click și instalare!',
      pid: 1027912,
      title: 'FS-Android-CPE-RO-1123',
      mobile_title: 'Puzzle de Sortare a Fructelor',
      url: '//aws.engine.fyber.com/show/fyber?appid=45208&uid=10410106&client=browser&device_model=&platform=android&appname=CashApp+Android+API&traffic_source=offer_api&country_code=RO&pubid=65741&ip=82.76.211.183&request_timestamp=1642165123166&pub0=1433562&google_ad_id=37a851b69c4545fa9ba75e81ec83d002&request_id=047ff1ac-f27b-4c27-9b69-14d47f18cb60&timestamp=1642158309&os_version=8.1&ad_id=1463116&ad_format=offer&group=Fyber&rank=40&gdpr_privacy_consent=1&sig=576a4175e9cbcfeda7f19b3daeba2daac71daf3c',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'credits',
      rewd_amnt: 592,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/icon/large-077352675a3599efc20deff10d651990.gif',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/icon/small-688ba03dea8d1b285ee4321d83a6e720.gif',
      icon: 'https://icons.fyber-static.com/offercampaign/icon/aa23cdf06bd80389e41dd855d584840c.gif',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.reddotme.fruit.sort',
      size_tag: false,
      preview: true,
      preview_text:
        'Completați modul Beginner fără a sări peste niciun nivel\nCompletați modul Advance fără a sări peste niciun nivel\nCompletați modul Master fără a sări peste niciun nivel\nCompletați modul Expert fără a sări peste niciun nivel',
      is_download: false,
      is_video: false,
      initial_scarcity: 38,
      required_actions: '',
      action_steps: [
        {
          action_id: 'BEGIN100',
          step: 'Completați modul Beginner fără a sări peste niciun nivel',
          payout_distribution: 3,
          rewd_amnt: 18,
        },
        {
          action_id: 'ADVAN100',
          step: 'Completați modul Advance fără a sări peste niciun nivel',
          payout_distribution: 5,
          rewd_amnt: 30,
        },
        {
          action_id: 'MASTER100',
          step: 'Completați modul Master fără a sări peste niciun nivel',
          payout_distribution: 8,
          rewd_amnt: 47,
        },
        {
          action_id: 'EXPERT100',
          step: 'Completați modul Expert fără a sări peste niciun nivel',
          payout_distribution: 84,
          rewd_amnt: 497,
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 925,
      dynamic_payout_enabled: true,
      cost_model: 'cost_per_engagement',
      complexity: 52,
      mobile_app_primary_category: 'Puzzle',
      non_web_offer: true,
      avg_latency: 1161,
      multi_payout: true,
      instruction_steps: [
        'Joacă Puzzle de Sortare a Fructelor (te rugăm să rămâi conectat la internet în timp ce joci pentru a asigura trimiterea semnalului de finalizare)',
        'Completați toți pașii și câștigați recompense pe parcurs',
        'Completați modul Expert fără a sări peste niciun nivel pentru a câștiga suma totală a recompenselor!',
      ],
      asset_urls: [null],
    },
  ],
  reengagement: {},
}

export const OfferResponseVideo = {
  data_attrs: {
    'data-request_timestamp': 1645175233070,
    'data-userid': 'pitis',
    'data-user-life-stage': 'no_clicks',
    'data-user-creation-timestamp': 0,
    'data-appid': '110226',
    'data-placement_id': '',
    'data-country': 'DE',
    'data-language': 'en',
    'data-integration': 7,
    'data-filter': null,
    'data-external-ofw': false,
    'data-app-category': 'Social',
    'data-publisher-id': 6477,
    'data-user-has-ignite': true,
  },
  page: 1,
  pages: 29,
  is_last: false,
  virtual_currency: 'Credits',
  virtual_currency_sale_enabled: false,
  mobile_scarcity: {
    '1435713': {
      original_quantity: 95,
      lifespan: 27261,
      timestamp: 1645106118,
      expire_at: 1645176579,
    },
    '1438287': {
      original_quantity: 62,
      lifespan: 11804,
      timestamp: 1645174495,
      expire_at: 1645229499,
    },
  },
  in_progress_offers: false,
  in_progress_reward_amount: 0,
  offers: [
    {
      attribution_window: 7,
      type: 'app',
      lpid: 1438287,
      advertiser_id: 79548,
      account_manager_id: 115512,
      comment: 'Nur für neue Benutzer.',
      pid: 1011349,
      title: 'tiktok_de',
      mobile_title: 'Schau dir lustige Videos auf TikTok an!',
      url: '//aws.engine.fyber.com/show/fyber?appid=110226&uid=pitis&client=sdk&device_model=&platform=android&appname=IMVU+Google+Play&traffic_source=offer_sdk&country_code=DE&pubid=6477&ip=79.210.186.0&request_timestamp=1645175233070&request_id=ad267dcd-de78-4521-9d5b-2c5ad0d1b85e&os_version=11&ad_id=1438287&ad_format=offer&group=Fyber&rank=1&gdpr_privacy_consent=0&sig=54f2d142a698d41ba26191c08adcd31cf56d8fd5',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Credits',
      rewd_amnt: 1769,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/346/icon/large-6f0301e20818eb5842e5a43195131dda.png',
      icon_lores:
        'https://icons.fyber-static.com/product/346/icon/small-ba54c21385ef8221ccd731b519c06d75.png',
      icon: 'https://icons.fyber-static.com/product/346/icon/769cde10e16463ed568b4e24e4380303.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68391/icos_ofw_install_original.svg',
      user_task: {
        id: 5,
        icon: 'https://icons.fyber-static.com/assets/68391/icos_ofw_install_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.zhiliaoapp.musically',
      size_tag: false,
      preview: true,
      preview_text: 'mobile_required_actions.download_and_start',
      is_download: true,
      is_video: false,
      initial_scarcity: 39,
      required_actions: '',
      action_steps: [
        { step: 'Download and open application', completed: false },
      ],
      action_description: null,
      billing_relation_id: 0,
      latency: 0,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_install',
      complexity: 2,
      mobile_app_primary_category: 'Social',
      non_web_offer: true,
      avg_latency: 2,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 6,
      type: 'task',
      lpid: 1454791,
      advertiser_id: 80069,
      account_manager_id: 115512,
      comment:
        '**Angebot gilt NUR für neue Benutzer des Spiels\n**Kann kein Level überspringen, indem man Videos ansieht, sonst wird man nicht belohnt.\n**Gültig für 5 Tage nach dem Klick und installieren Tage!',
      pid: 1027555,
      title: 'BB-Android-CPE-DE-AT-CH-1001',
      mobile_title: 'Ziegelbrecher - Kugelbrecher',
      url: '//aws.engine.fyber.com/show/fyber?appid=110226&uid=pitis&client=sdk&device_model=&platform=android&appname=IMVU+Google+Play&traffic_source=offer_sdk&country_code=DE&pubid=6477&ip=79.210.186.0&request_timestamp=1645175233070&request_id=ad267dcd-de78-4521-9d5b-2c5ad0d1b85e&os_version=11&ad_id=1454791&ad_format=offer&group=Fyber&rank=2&gdpr_privacy_consent=0&sig=4a141ecf891f33cd4008f240a92e5f23f7cb99b2',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Credits',
      rewd_amnt: 17849,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/38084/icon/large-0fc020e5fb84cc51a27980659d478447.gif',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/38084/icon/small-b1127ddaf35a7d062d77e4677d759021.gif',
      icon: 'https://icons.fyber-static.com/offercampaign/38084/icon/cff4fb732fd2bd490eb08af6e030a117.gif',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.reddotme.brickbreaker',
      size_tag: false,
      preview: true,
      preview_text:
        'Schließe 100 Level ab, ohne ein Level zu überspringen\nSchließe 200 Level ab, ohne ein Level zu überspringen\nSchließe 300 Level ab, ohne ein Level zu überspringen\nSchließe 400 Level ab, ohne ein Level zu überspringen\nSchließe 500 Level ab, ohne ein Level zu überspringen',
      is_download: false,
      is_video: false,
      initial_scarcity: 4,
      required_actions: '',
      action_steps: [
        {
          action_id: '100LEVELS',
          step: 'Schließe 100 Level ab, ohne ein Level zu überspringen',
          payout_distribution: 4.0,
          rewd_amnt: 714,
        },
        {
          action_id: '200LEVELS',
          step: 'Schließe 200 Level ab, ohne ein Level zu überspringen',
          payout_distribution: 6.0,
          rewd_amnt: 1071,
        },
        {
          action_id: '300LEVELS',
          step: 'Schließe 300 Level ab, ohne ein Level zu überspringen',
          payout_distribution: 12.0,
          rewd_amnt: 2142,
        },
        {
          action_id: '400LEVELS',
          step: 'Schließe 400 Level ab, ohne ein Level zu überspringen',
          payout_distribution: 19.0,
          rewd_amnt: 3391,
        },
        {
          action_id: '500LEVELS',
          step: 'Schließe 500 Level ab, ohne ein Level zu überspringen',
          payout_distribution: 59.0,
          rewd_amnt: 10531,
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 2625,
      dynamic_payout_enabled: true,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Puzzle',
      non_web_offer: true,
      avg_latency: 3285,
      multi_payout: true,
      instruction_steps: [
        'Spielen Ziegelbrecher - Kugelbrecher (bitte bleiben Sie während des Spielens mit dem Internet verbunden, um sicherzustellen, dass das Abschlusssignal gesendet WIRD)',
        'Schließe alle Schritte ab und verdiene dir dabei Belohnungen.',
        'Schließe 500 Levels ab, ohne eines zu überspringen, um die gesamte Belohnung zu gewinnen!',
      ],
      asset_urls: [null],
    },
    {
      attribution_window: 20,
      type: 'task',
      lpid: 1439980,
      advertiser_id: 78849,
      account_manager_id: 116530,
      comment:
        '**New Users ONLY!!\n**Offer is Valid For 20 Days After Installation!',
      pid: 991987,
      title: 'Fyber_SGH_CPE_MR_ReachCrop12_DE_OSV10_Android',
      mobile_title: 'Solitaire - Grand Harvest',
      url: '//aws.engine.fyber.com/show/fyber?appid=110226&uid=pitis&client=sdk&device_model=&platform=android&appname=IMVU+Google+Play&traffic_source=offer_sdk&country_code=DE&pubid=6477&ip=79.210.186.0&request_timestamp=1645175233070&request_id=ad267dcd-de78-4521-9d5b-2c5ad0d1b85e&os_version=11&ad_id=1439980&ad_format=offer&group=Fyber&rank=3&gdpr_privacy_consent=0&sig=f20f6e00a5e1981b88bc0b13aa5c0b3c8cb148d7',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Credits',
      rewd_amnt: 13100,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/3/icon/large-62ff1e3a28dcb21fa8db3b45573712dc.png',
      icon_lores:
        'https://icons.fyber-static.com/product/3/icon/small-ee084fe78fa29467d4a4faf655348707.png',
      icon: 'https://icons.fyber-static.com/product/3/icon/b4272ae9f4d7c061c93028f12ee0a9f6.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'net.supertreat.solitaire',
      size_tag: false,
      preview: true,
      preview_text:
        'Open Solitaire Grand Harvest \nFinish Crop 4\nFinish Crop 8\nFinish Crop 10\nFinish Crop 12',
      is_download: false,
      is_video: false,
      initial_scarcity: 7,
      required_actions: '',
      action_steps: [
        {
          action_id: 'OPEN',
          step: 'Open Solitaire Grand Harvest ',
          payout_distribution: 0.25,
          rewd_amnt: 33,
        },
        {
          action_id: 'CROP4',
          step: 'Finish Crop 4',
          payout_distribution: 10.0,
          rewd_amnt: 1310,
        },
        {
          action_id: 'CROP8',
          step: 'Finish Crop 8',
          payout_distribution: 20.0,
          rewd_amnt: 2620,
        },
        {
          action_id: 'CROP10',
          step: 'Finish Crop 10',
          payout_distribution: 30.0,
          rewd_amnt: 3930,
        },
        {
          action_id: 'CROP12',
          step: 'Finish Crop 12',
          payout_distribution: 39.75,
          rewd_amnt: 5207,
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 0,
      dynamic_payout_enabled: true,
      cost_model: 'cost_per_engagement',
      complexity: 2,
      mobile_app_primary_category: 'Card',
      non_web_offer: true,
      avg_latency: 4,
      multi_payout: true,
      instruction_steps: [
        'Play Solitaire Grand Harvest',
        'Finish all the levels listed below and receive your rewards!',
      ],
      asset_urls: [null],
    },
    {
      attribution_window: 5,
      type: 'task',
      lpid: 1470469,
      advertiser_id: 80475,
      account_manager_id: 116530,
      comment: '',
      pid: 1034575,
      title: 'Fyber_RDR_CPE_FTD_T2_OS9+_Android',
      mobile_title: 'Become a designer and decorate your dream house ',
      url: '//aws.engine.fyber.com/show/fyber?appid=110226&uid=pitis&client=sdk&device_model=&platform=android&appname=IMVU+Google+Play&traffic_source=offer_sdk&country_code=DE&pubid=6477&ip=79.210.186.0&request_timestamp=1645175233070&request_id=ad267dcd-de78-4521-9d5b-2c5ad0d1b85e&os_version=11&ad_id=1470469&ad_format=offer&group=Fyber&rank=4&gdpr_privacy_consent=0&sig=cee9474244c735ab7f7f14306a1e70cb0cbbd7c8',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Credits',
      rewd_amnt: 13100,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/20475/icon/large-07af3c6971bbffd0daf6d1a106403aea.jpg',
      icon_lores:
        'https://icons.fyber-static.com/product/20475/icon/small-4a6a12a44a253fdc760e60ed8326faad.jpg',
      icon: 'https://icons.fyber-static.com/product/20475/icon/71d63b4191aa747c74514a62bbbec378.jpg',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'fi.reworks.redecor',
      size_tag: false,
      preview: true,
      preview_text:
        'Open the App\nDecorate& Designed your house \nMake your purchases in the App\nOffer is valid for new users only!!\n**Offer is Valid For 5 Days After Installation!',
      is_download: false,
      is_video: false,
      initial_scarcity: 7,
      required_actions: '',
      action_steps: [
        { step: 'Open the App', payout_distribution: 0.0, action_id: '' },
        {
          step: 'Decorate& Designed your house ',
          payout_distribution: 0.0,
          action_id: '',
        },
        {
          step: 'Make your purchases in the App',
          payout_distribution: 0.0,
          action_id: '',
        },
        {
          step: 'Offer is valid for new users only!!',
          payout_distribution: 0.0,
          action_id: '',
        },
        {
          step: '**Offer is Valid For 5 Days After Installation!',
          payout_distribution: 0.0,
          action_id: 'FTD',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 5,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 2,
      mobile_app_primary_category: 'Simulation',
      non_web_offer: true,
      avg_latency: 11,
      multi_payout: false,
      instruction_steps: [],
      // asset_urls: [null],
      asset_urls: [
        {
          low: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/f538fc78-a0ea-4af4-a115-6be1239e73d7_low.mp4',
          raw: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/f538fc78-a0ea-4af4-a115-6be1239e73d7_raw.mp4',
          high: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/f538fc78-a0ea-4af4-a115-6be1239e73d7_high.mp4',
          medium:
            'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/f538fc78-a0ea-4af4-a115-6be1239e73d7_medium.mp4',
        },
        {
          low: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/5fc39d4a-a135-4025-93dc-0b325e6930a4_low.jpeg',
          raw: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/5fc39d4a-a135-4025-93dc-0b325e6930a4_raw.jpeg',
          high: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/5fc39d4a-a135-4025-93dc-0b325e6930a4_high.jpeg',
          medium:
            'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/5fc39d4a-a135-4025-93dc-0b325e6930a4_medium.jpeg',
        },
        {
          low: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/69db997b-2ca0-4518-a8a5-e551a19c61d4_low.jpeg',
          raw: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/69db997b-2ca0-4518-a8a5-e551a19c61d4_raw.jpeg',
          high: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/69db997b-2ca0-4518-a8a5-e551a19c61d4_high.jpeg',
          medium:
            'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/offer_campaign/1/10/69db997b-2ca0-4518-a8a5-e551a19c61d4_medium.jpeg',
        },
      ],
    },
    {
      attribution_window: 15,
      type: 'task',
      lpid: 1443019,
      advertiser_id: 79039,
      account_manager_id: 116530,
      comment: 'NUR FÜR NEUE USER',
      pid: 996519,
      title: 'FyberCPE_DE_2SacradShards_15D_&adcampaign=476015',
      mobile_title: 'Spiele Raid und verdiene Belohnungen',
      url: '//aws.engine.fyber.com/show/fyber?appid=110226&uid=pitis&client=sdk&device_model=&platform=android&appname=IMVU+Google+Play&traffic_source=offer_sdk&country_code=DE&pubid=6477&ip=79.210.186.0&request_timestamp=1645175233070&request_id=ad267dcd-de78-4521-9d5b-2c5ad0d1b85e&os_version=11&ad_id=1443019&ad_format=offer&group=Fyber&rank=5&gdpr_privacy_consent=0&sig=51f1ee41c3e6bcf0ffa172052ee60b2ee5165f2f',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Credits',
      rewd_amnt: 147635,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/26447/icon/large-714af7f144c57b89c9c3d3ca9b5a301c.gif',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/26447/icon/small-428b6fe9b98e418cccdcbd3d7520e1a2.gif',
      icon: 'https://icons.fyber-static.com/offercampaign/26447/icon/d38c7bf4696f4027f0c0a66fa00b5518.gif',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.plarium.raidlegends',
      size_tag: false,
      preview: true,
      preview_text:
        'Installiere Raid\nÖffne 2 Orange Splitter in 15 Tagen * & Erhalte kurz darauf deine Belohnung!',
      is_download: false,
      is_video: false,
      initial_scarcity: 1,
      required_actions: '',
      action_steps: [
        {
          step: 'Installiere Raid',
          payout_distribution: null,
          action_id: 'DOWNLOAD',
        },
        {
          step: 'Öffne 2 Orange Splitter in 15 Tagen * & Erhalte kurz darauf deine Belohnung!',
          payout_distribution: null,
          action_id: '2SACRADSHARDS15D',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 550,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 52,
      mobile_app_primary_category: 'Role Playing',
      non_web_offer: true,
      avg_latency: 692,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [
        {
          low: 'https://ofw-assets.fyber.com/offer_campaign/17/22/0bf4972b-75b4-437d-a5c5-e111ed101b20_low.mp4',
          raw: 'https://ofw-assets.fyber.com/offer_campaign/17/22/0bf4972b-75b4-437d-a5c5-e111ed101b20_raw.mp4',
          high: 'https://ofw-assets.fyber.com/offer_campaign/17/22/0bf4972b-75b4-437d-a5c5-e111ed101b20_high.mp4',
          medium:
            'https://ofw-assets.fyber.com/offer_campaign/17/22/0bf4972b-75b4-437d-a5c5-e111ed101b20_medium.mp4',
        },
        {
          low: 'https://ofw-assets.fyber.com/offer_campaign/17/22/2fcf4539-cd51-42bb-813a-22bd4f0d298f_low.jpg',
          raw: 'https://ofw-assets.fyber.com/offer_campaign/17/22/2fcf4539-cd51-42bb-813a-22bd4f0d298f_raw.jpg',
          high: 'https://ofw-assets.fyber.com/offer_campaign/17/22/2fcf4539-cd51-42bb-813a-22bd4f0d298f_high.jpg',
          medium:
            'https://ofw-assets.fyber.com/offer_campaign/17/22/2fcf4539-cd51-42bb-813a-22bd4f0d298f_medium.jpg',
        },
        {
          low: 'https://ofw-assets.fyber.com/offer_campaign/17/22/7bcd64af-5375-4a8d-b28a-a1227dcc5285_low.jpg',
          raw: 'https://ofw-assets.fyber.com/offer_campaign/17/22/7bcd64af-5375-4a8d-b28a-a1227dcc5285_raw.jpg',
          high: 'https://ofw-assets.fyber.com/offer_campaign/17/22/7bcd64af-5375-4a8d-b28a-a1227dcc5285_high.jpg',
          medium:
            'https://ofw-assets.fyber.com/offer_campaign/17/22/7bcd64af-5375-4a8d-b28a-a1227dcc5285_medium.jpg',
        },
      ],
    },
    {
      attribution_window: 14,
      type: 'task',
      lpid: 1467479,
      advertiser_id: 78907,
      account_manager_id: 116530,
      comment:
        '**New Users ONLY!!\n**Offer is Valid For 14 Days After Installation!',
      pid: 996663,
      title: 'Fyber_CC_CPE_MR_ReachLevel400_DE_OSV11+_Android',
      mobile_title: 'Caesars Slots: Free Slot Machines',
      url: '//aws.engine.fyber.com/show/fyber?appid=110226&uid=pitis&client=sdk&device_model=&platform=android&appname=IMVU+Google+Play&traffic_source=offer_sdk&country_code=DE&pubid=6477&ip=79.210.186.0&request_timestamp=1645175233070&request_id=ad267dcd-de78-4521-9d5b-2c5ad0d1b85e&os_version=11&ad_id=1467479&ad_format=offer&group=Fyber&rank=6&gdpr_privacy_consent=0&sig=5f1768871500d0416c4fb934d88a88ec3a38dcae',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Credits',
      rewd_amnt: 75324,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/25/icon/large-7358c8de7e29ec581fcaff0611db0fe8.png',
      icon_lores:
        'https://icons.fyber-static.com/product/25/icon/small-75e7131c7725e98ee9294aca66998bae.png',
      icon: 'https://icons.fyber-static.com/product/25/icon/f3bbc7a47ecbb745c619f619a35733b2.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.playtika.caesarscasino',
      size_tag: false,
      preview: true,
      preview_text:
        'Reach Level 30\nReach Level 100\nReach Level 200\nReach Level 300\nReach Level 400',
      is_download: false,
      is_video: false,
      initial_scarcity: 1,
      required_actions: '',
      action_steps: [
        {
          action_id: 'LVL30REACH',
          step: 'Reach Level 30',
          payout_distribution: 2.5,
          rewd_amnt: 1883,
        },
        {
          action_id: 'LVL100REACH',
          step: 'Reach Level 100',
          payout_distribution: 7.0,
          rewd_amnt: 5273,
        },
        {
          action_id: 'LVL200REACH',
          step: 'Reach Level 200',
          payout_distribution: 22.0,
          rewd_amnt: 16571,
        },
        {
          action_id: 'LVL300REACH',
          step: 'Reach Level 300',
          payout_distribution: 29.5,
          rewd_amnt: 22221,
        },
        {
          action_id: 'LVL400REACH',
          step: 'Reach Level 400',
          payout_distribution: 39.0,
          rewd_amnt: 29376,
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 545,
      dynamic_payout_enabled: true,
      cost_model: 'cost_per_engagement',
      complexity: 52,
      mobile_app_primary_category: 'Casino',
      non_web_offer: true,
      avg_latency: 684,
      multi_payout: true,
      instruction_steps: [
        'Play Caesars Casino Slots!',
        'Finish all the levels listed below and receive your rewards!',
      ],
      asset_urls: [null],
    },
    {
      attribution_window: 30,
      type: 'task',
      lpid: 1394927,
      advertiser_id: 79179,
      account_manager_id: 115426,
      comment:
        'You have 30 days to complete this offer from the time of install\nNew users only!',
      pid: 1002015,
      title: 'CPA_LVL3',
      mobile_title: 'Reach level 3 in Mistplay!',
      url: '//aws.engine.fyber.com/show/fyber?appid=110226&uid=pitis&client=sdk&device_model=&platform=android&appname=IMVU+Google+Play&traffic_source=offer_sdk&country_code=DE&pubid=6477&ip=79.210.186.0&request_timestamp=1645175233070&request_id=ad267dcd-de78-4521-9d5b-2c5ad0d1b85e&os_version=11&ad_id=1394927&ad_format=offer&group=Fyber&rank=7&gdpr_privacy_consent=0&sig=fec1506f5445da0492c962b68f81ecc408a4f4be',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Credits',
      rewd_amnt: 9366,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/52/icon/large-71a34f3d541734737095b58a0ab72eab.png',
      icon_lores:
        'https://icons.fyber-static.com/product/52/icon/small-6b3bebdb59cfd4cbe2ea55d0715e309d.png',
      icon: 'https://icons.fyber-static.com/product/52/icon/0de822aaaeffcae0d4372a2cd6b7ba5d.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.mistplay.mistplay',
      size_tag: false,
      preview: true,
      preview_text:
        "Create a profile in the Mistplay app and play any game\nPlay games until you've reached profile Level 3 within the Mistplay app",
      is_download: false,
      is_video: false,
      initial_scarcity: 9,
      required_actions: '',
      action_steps: [
        {
          step: 'Create a profile in the Mistplay app and play any game',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: "Play games until you've reached profile Level 3 within the Mistplay app",
          payout_distribution: null,
          action_id: 'LEVELUP',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 265,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 52,
      mobile_app_primary_category: 'Entertainment',
      non_web_offer: true,
      avg_latency: 336,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [
        {
          low: 'https://ofw-assets.fyber.com/offer_campaign/21/52/059e38ae-40e0-41c7-bcd9-ad566db443fc_low.mp4',
          raw: 'https://ofw-assets.fyber.com/offer_campaign/21/52/059e38ae-40e0-41c7-bcd9-ad566db443fc_raw.mp4',
          high: 'https://ofw-assets.fyber.com/offer_campaign/21/52/059e38ae-40e0-41c7-bcd9-ad566db443fc_high.mp4',
          medium:
            'https://ofw-assets.fyber.com/offer_campaign/21/52/059e38ae-40e0-41c7-bcd9-ad566db443fc_medium.mp4',
        },
      ],
    },
    {
      attribution_window: 60,
      type: 'task',
      lpid: 1441788,
      advertiser_id: 79324,
      account_manager_id: 116530,
      comment:
        'der User wird nur für die Beendigung des zuletzt geklickten Angebots belohnt ***Nutzer müssen Level 22 innerhalb von 20 Tagen nach Installation erreichen',
      pid: 1006421,
      title: 'FI_Fyber_Android_os-8+_WW_CPE_LVL22_RON_0121',
      mobile_title: 'Erreiche 22 in Family Island!',
      url: '//aws.engine.fyber.com/show/fyber?appid=110226&uid=pitis&client=sdk&device_model=&platform=android&appname=IMVU+Google+Play&traffic_source=offer_sdk&country_code=DE&pubid=6477&ip=79.210.186.0&request_timestamp=1645175233070&request_id=ad267dcd-de78-4521-9d5b-2c5ad0d1b85e&os_version=11&ad_id=1441788&ad_format=offer&group=Fyber&rank=8&gdpr_privacy_consent=0&sig=200ceef5f4e2937c5eb286db93327686d50bf610',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Credits',
      rewd_amnt: 26200,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/icon/large-e7801e380b4868dbbca89a21594508fe.jpg',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/icon/small-f23539432e1bca4476677c461162d0f7.jpg',
      icon: 'https://icons.fyber-static.com/offercampaign/icon/a73e16a24a03fed51337a8a50b408b56.jpg',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.MelsoftGames.FamilyIslandFarm',
      size_tag: false,
      preview: true,
      preview_text: 'Installiere das Spiel Family Island\nErreiche Level 22',
      is_download: false,
      is_video: false,
      initial_scarcity: 3,
      required_actions: '',
      action_steps: [
        {
          step: 'Installiere das Spiel Family Island',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Erreiche Level 22',
          payout_distribution: null,
          action_id: 'LVL22',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 20010,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Casual',
      non_web_offer: true,
      avg_latency: 25017,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 21,
      type: 'task',
      lpid: 1471763,
      advertiser_id: 78949,
      account_manager_id: 116530,
      comment:
        '*Only for NEW USERS! \n**Offer is Valid For 21 Days After Installation!',
      pid: 995761,
      title: 'BC_GP_NTW_T1_CPE_MultiReward_20220104',
      mobile_title: 'Billionaire Casino Slots & Mini Games',
      url: '//aws.engine.fyber.com/show/fyber?appid=110226&uid=pitis&client=sdk&device_model=&platform=android&appname=IMVU+Google+Play&traffic_source=offer_sdk&country_code=DE&pubid=6477&ip=79.210.186.0&request_timestamp=1645175233070&request_id=ad267dcd-de78-4521-9d5b-2c5ad0d1b85e&os_version=11&ad_id=1471763&ad_format=offer&group=Fyber&rank=9&gdpr_privacy_consent=0&sig=3eb33c8b5f36c926831c8f499c250a97009aec18',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Credits',
      rewd_amnt: 91698,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/icon/large-4ad9d3f5875b9f8f7fb923f012751932.png',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/icon/small-96e072a31da9d6e79936146d84c3cde7.png',
      icon: 'https://icons.fyber-static.com/offercampaign/icon/acce4f535194b10e23118d4d233e6e30.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.huuuge.casino.texas',
      size_tag: false,
      preview: true,
      preview_text:
        'Join a Club & Send 5 Gifts (Optional)\nLevel100\nLevel150\nLevel200\nLevel250',
      is_download: false,
      is_video: false,
      initial_scarcity: 1,
      required_actions: '',
      action_steps: [
        {
          action_id: '5THGIFT',
          step: 'Join a Club & Send 5 Gifts (Optional)',
          payout_distribution: 1.0,
          rewd_amnt: 917,
        },
        {
          action_id: 'LEVEL100',
          step: 'Level100',
          payout_distribution: 8.5,
          rewd_amnt: 7794,
        },
        {
          action_id: 'LEVEL150',
          step: 'Level150',
          payout_distribution: 19.5,
          rewd_amnt: 17881,
        },
        {
          action_id: 'LEVEL200',
          step: 'Level200',
          payout_distribution: 21.0,
          rewd_amnt: 19257,
        },
        {
          action_id: 'LEVEL250',
          step: 'Level250',
          payout_distribution: 50.0,
          rewd_amnt: 45849,
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 1385,
      dynamic_payout_enabled: true,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Casino',
      non_web_offer: true,
      avg_latency: 1737,
      multi_payout: true,
      instruction_steps: [
        'Install Billionaire Casino Slots App',
        'Reach Levels To Win Rewards',
        'Reach Level 250 To Win It All. Jackpot!',
      ],
      asset_urls: [null],
    },
    {
      attribution_window: 21,
      type: 'task',
      lpid: 1436821,
      advertiser_id: 78949,
      account_manager_id: 116530,
      comment:
        '"Nur für NEUE BENUTZER  **Das Angebot gilt ab Installation für 30 Tage!"',
      pid: 994893,
      title: 'HC_GP_NTW_T1_CPE_MultiReward_20210524',
      mobile_title: 'Huuuge Casino Slots-Spiel',
      url: '//aws.engine.fyber.com/show/fyber?appid=110226&uid=pitis&client=sdk&device_model=&platform=android&appname=IMVU+Google+Play&traffic_source=offer_sdk&country_code=DE&pubid=6477&ip=79.210.186.0&request_timestamp=1645175233070&request_id=ad267dcd-de78-4521-9d5b-2c5ad0d1b85e&os_version=11&ad_id=1436821&ad_format=offer&group=Fyber&rank=10&gdpr_privacy_consent=0&sig=afea1100c30f2d7eeab88a93c8f18398d4b679da',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Credits',
      rewd_amnt: 13100,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/20268/icon/large-f7e8ad0aa03a0c5661ff99757bc6a66e.png',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/20268/icon/small-8df5932124d653aec02e63f6d9b5d75c.png',
      icon: 'https://icons.fyber-static.com/offercampaign/20268/icon/99849e71447e53a7a6c3a002de606d82.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.huuuge.casino.slots',
      size_tag: false,
      preview: true,
      preview_text:
        'Level beenden 10\nLevel beenden 100\nLevel beenden 150\nLevel beenden 200\nLevel beenden 250',
      is_download: false,
      is_video: false,
      initial_scarcity: 5,
      required_actions: '',
      action_steps: [
        {
          action_id: 'LEVEL10',
          step: 'Level beenden 10',
          payout_distribution: 0.3,
          rewd_amnt: 39,
        },
        {
          action_id: 'LEVEL100',
          step: 'Level beenden 100',
          payout_distribution: 10.7,
          rewd_amnt: 1402,
        },
        {
          action_id: 'LEVEL150',
          step: 'Level beenden 150',
          payout_distribution: 17.0,
          rewd_amnt: 2227,
        },
        {
          action_id: 'LEVEL200',
          step: 'Level beenden 200',
          payout_distribution: 22.0,
          rewd_amnt: 2882,
        },
        {
          action_id: 'LEVEL250',
          step: 'Level beenden 250',
          payout_distribution: 50.0,
          rewd_amnt: 6550,
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 25,
      dynamic_payout_enabled: true,
      cost_model: 'cost_per_engagement',
      complexity: 2,
      mobile_app_primary_category: 'Casino',
      non_web_offer: true,
      avg_latency: 36,
      multi_payout: true,
      instruction_steps: [
        'Installiere das Huuuge Casino Slots-Spiel',
        'Erreiche Level und schließe Aufgaben ab, um Belohnungen zu gewinnen',
        'Erreiche Level 250 für den ganz großen Gewinn. Jackpot!',
      ],
      asset_urls: [null],
    },
    {
      attribution_window: 60,
      type: 'task',
      lpid: 1447483,
      advertiser_id: 79349,
      account_manager_id: 116530,
      comment: 'Das Angebot gilt NUR für Erstnutzer von AppFlame!',
      pid: 1005414,
      title: 'Fyber_DE_incent_playtime_AppFlame_8+NEW',
      mobile_title: 'Spiele 35 Minuten lang Spiele von Appflame',
      url: '//aws.engine.fyber.com/show/fyber?appid=110226&uid=pitis&client=sdk&device_model=&platform=android&appname=IMVU+Google+Play&traffic_source=offer_sdk&country_code=DE&pubid=6477&ip=79.210.186.0&request_timestamp=1645175233070&request_id=ad267dcd-de78-4521-9d5b-2c5ad0d1b85e&os_version=11&ad_id=1447483&ad_format=offer&group=Fyber&rank=11&gdpr_privacy_consent=0&sig=24db75e637f6a65e06199a8b605bb999be4aaf21',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Credits',
      rewd_amnt: 3602,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/icon/large-959e4ccc0363ac780e937028e1e3a7c2.png',
      icon_lores:
        'https://icons.fyber-static.com/product/icon/small-bff2318449f60bc584f0c16f70599e59.png',
      icon: 'https://icons.fyber-static.com/product/96/icon/ac5f453fcb617077c4bca5ac18cb7ce5.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'online.appflame.app',
      size_tag: false,
      preview: true,
      preview_text:
        'öffne AppFlame\nHolen Sie sich zwei der vorgeschlagenen Spiele und spielen Sie insgesamt mindestens 35 Minuten, um die Belohnung zu erhalten.\nDeine Spielzeit wird unter dem jeweiligen Spiel angezeigt.',
      is_download: false,
      is_video: false,
      initial_scarcity: 21,
      required_actions: '',
      action_steps: [
        { step: 'öffne AppFlame', payout_distribution: 100.0, action_id: '' },
        {
          step: 'Holen Sie sich zwei der vorgeschlagenen Spiele und spielen Sie insgesamt mindestens 35 Minuten, um die Belohnung zu erhalten.',
          payout_distribution: 100.0,
          action_id: '',
        },
        {
          step: 'Deine Spielzeit wird unter dem jeweiligen Spiel angezeigt.',
          payout_distribution: 100.0,
          action_id: 'TIME_30',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 60,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 52,
      mobile_app_primary_category: 'Entertainment',
      non_web_offer: true,
      avg_latency: 77,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [
        {
          low: 'https://ofw-assets.fyber.com/offer_campaign/50/96/0cf3cd74-843a-4a5e-9286-6ff924310399_low.mp4',
          raw: 'https://ofw-assets.fyber.com/offer_campaign/50/96/0cf3cd74-843a-4a5e-9286-6ff924310399_raw.mp4',
          high: 'https://ofw-assets.fyber.com/offer_campaign/50/96/0cf3cd74-843a-4a5e-9286-6ff924310399_high.mp4',
          medium:
            'https://ofw-assets.fyber.com/offer_campaign/50/96/0cf3cd74-843a-4a5e-9286-6ff924310399_medium.mp4',
        },
      ],
    },
    {
      attribution_window: 15,
      type: 'task',
      lpid: 1421717,
      advertiser_id: 79781,
      account_manager_id: 114133,
      comment:
        '**Offer Is Valid ONLY to Users Who Has Never Installed The Game Before.\n**Offer Is Valid For 10 Days After Click and Install Days!',
      pid: 1015108,
      title: 'Fyber_CPE_Android_Evony_3GoldenGenerals_1231',
      mobile_title: "Evony: The king's Return, the real-time strategy Game",
      url: '//aws.engine.fyber.com/show/fyber?appid=110226&uid=pitis&client=sdk&device_model=&platform=android&appname=IMVU+Google+Play&traffic_source=offer_sdk&country_code=DE&pubid=6477&ip=79.210.186.0&request_timestamp=1645175233070&request_id=ad267dcd-de78-4521-9d5b-2c5ad0d1b85e&os_version=11&ad_id=1421717&ad_format=offer&group=Fyber&rank=12&gdpr_privacy_consent=0&sig=a55ff5c55dd78c441a87d9b62e15feea2c20d26e',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Credits',
      rewd_amnt: 90389,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/5647/icon/large-f72752102e1ee19d3ba63bacb952e091.gif',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/5647/icon/small-63f9bac3c90c3245a87b83cc08bd8396.gif',
      icon: 'https://icons.fyber-static.com/offercampaign/5647/icon/d6aa01d686f0068f1e6178dec791f824.gif',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.topgamesinc.evony',
      size_tag: false,
      preview: true,
      preview_text:
        'Begin to play Evony: The King\'s Return\nGet 3 Generals with "Golden Frame" \nGet Rewarded!!!',
      is_download: false,
      is_video: false,
      initial_scarcity: 1,
      required_actions: '',
      action_steps: [
        {
          step: "Begin to play Evony: The King's Return",
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Get 3 Generals with "Golden Frame" ',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Get Rewarded!!!',
          payout_distribution: null,
          action_id: '3GOLDENGENERALS',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 560,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 52,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 701,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [
        {
          low: 'https://ofw-assets.fyber.com/offer_campaign/164/2106/1c81730e-7043-470f-bef0-122a8eec65a1_low.mp4',
          raw: 'https://ofw-assets.fyber.com/offer_campaign/164/2106/1c81730e-7043-470f-bef0-122a8eec65a1_raw.mp4',
          high: 'https://ofw-assets.fyber.com/offer_campaign/164/2106/1c81730e-7043-470f-bef0-122a8eec65a1_high.mp4',
          medium:
            'https://ofw-assets.fyber.com/offer_campaign/164/2106/1c81730e-7043-470f-bef0-122a8eec65a1_medium.mp4',
        },
        {
          low: 'https://ofw-assets.fyber.com/offer_campaign/164/2106/554f6d23-9a97-4572-8819-c60e645935f0_low.jpg',
          raw: 'https://ofw-assets.fyber.com/offer_campaign/164/2106/554f6d23-9a97-4572-8819-c60e645935f0_raw.jpg',
          high: 'https://ofw-assets.fyber.com/offer_campaign/164/2106/554f6d23-9a97-4572-8819-c60e645935f0_high.jpg',
          medium:
            'https://ofw-assets.fyber.com/offer_campaign/164/2106/554f6d23-9a97-4572-8819-c60e645935f0_medium.jpg',
        },
        {
          low: 'https://ofw-assets.fyber.com/offer_campaign/164/2106/1b2a245b-2d85-4135-aeb0-81b46842b58b_low.jpg',
          raw: 'https://ofw-assets.fyber.com/offer_campaign/164/2106/1b2a245b-2d85-4135-aeb0-81b46842b58b_raw.jpg',
          high: 'https://ofw-assets.fyber.com/offer_campaign/164/2106/1b2a245b-2d85-4135-aeb0-81b46842b58b_high.jpg',
          medium:
            'https://ofw-assets.fyber.com/offer_campaign/164/2106/1b2a245b-2d85-4135-aeb0-81b46842b58b_medium.jpg',
        },
      ],
    },
    {
      attribution_window: 25,
      type: 'task',
      lpid: 1433551,
      advertiser_id: 79189,
      account_manager_id: 115512,
      comment:
        '*The offer is valid within 25 days after you click and install the game\n*No reward if you installed this game before.\n*Users will only obtain reward from ONE Bingo Holiday offer.',
      pid: 1021018,
      title: 'Bingo Holiday-T1-LVL40',
      mobile_title: 'Bingo Holiday',
      url: '//aws.engine.fyber.com/show/fyber?appid=110226&uid=pitis&client=sdk&device_model=&platform=android&appname=IMVU+Google+Play&traffic_source=offer_sdk&country_code=DE&pubid=6477&ip=79.210.186.0&request_timestamp=1645175233070&request_id=ad267dcd-de78-4521-9d5b-2c5ad0d1b85e&os_version=11&ad_id=1433551&ad_format=offer&group=Fyber&rank=13&gdpr_privacy_consent=0&sig=11a1ba7e214555f4ead91d864dfadb81b2caaf3b',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Credits',
      rewd_amnt: 94974,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/7383/icon/large-14d7103031c76d5bdc14200e14deb886.png',
      icon_lores:
        'https://icons.fyber-static.com/product/7383/icon/small-f23d6ee01834ca5b009ceec12fb8386b.png',
      icon: 'https://icons.fyber-static.com/product/7383/icon/2b0d37ee8747044a94c1ab51bd83f02f.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.arcadegame.games.bingo.holiday.free.slots.bash',
      size_tag: false,
      preview: true,
      preview_text:
        'Sign in and play Bingo Holiday\nComplete level 40\n*The offer is valid within 25 days after you click and install the game\nGet reward!',
      is_download: false,
      is_video: false,
      initial_scarcity: 1,
      required_actions: '',
      action_steps: [
        {
          step: 'Sign in and play Bingo Holiday',
          payout_distribution: 100.0,
          action_id: '',
        },
        {
          step: 'Complete level 40',
          payout_distribution: 100.0,
          action_id: '',
        },
        {
          step: '*The offer is valid within 25 days after you click and install the game',
          payout_distribution: 0.0,
          action_id: '',
        },
        { step: 'Get reward!', payout_distribution: 100.0, action_id: 'LVL40' },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 19900,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Casino',
      non_web_offer: true,
      avg_latency: 24875,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 30,
      type: 'task',
      lpid: 1457045,
      advertiser_id: 79179,
      account_manager_id: 115426,
      comment:
        'You have 30 days to complete this offer from the time of install\nNew users only!',
      pid: 1002015,
      title: 'CPA_DOWNLOAD_DE',
      mobile_title: 'Start a new game in Mistplay!',
      url: '//aws.engine.fyber.com/show/fyber?appid=110226&uid=pitis&client=sdk&device_model=&platform=android&appname=IMVU+Google+Play&traffic_source=offer_sdk&country_code=DE&pubid=6477&ip=79.210.186.0&request_timestamp=1645175233070&request_id=ad267dcd-de78-4521-9d5b-2c5ad0d1b85e&os_version=11&ad_id=1457045&ad_format=offer&group=Fyber&rank=14&gdpr_privacy_consent=0&sig=ad18f06960ae14999c177d68e84881b253fe1936',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Credits',
      rewd_amnt: 3832,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/52/icon/large-71a34f3d541734737095b58a0ab72eab.png',
      icon_lores:
        'https://icons.fyber-static.com/product/52/icon/small-6b3bebdb59cfd4cbe2ea55d0715e309d.png',
      icon: 'https://icons.fyber-static.com/product/52/icon/0de822aaaeffcae0d4372a2cd6b7ba5d.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.mistplay.mistplay',
      size_tag: false,
      preview: true,
      preview_text:
        'Create a profile in the Mistplay app and play any game\nGet Rewarded!',
      is_download: false,
      is_video: false,
      initial_scarcity: 19,
      required_actions: '',
      action_steps: [
        {
          step: 'Create a profile in the Mistplay app and play any game',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Get Rewarded!',
          payout_distribution: null,
          action_id: 'DOWNLOAD',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 5,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 2,
      mobile_app_primary_category: 'Entertainment',
      non_web_offer: true,
      avg_latency: 10,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [
        {
          low: 'https://ofw-assets.fyber.com/offer_campaign/21/52/1d8586d2-fb61-4c47-aa1f-9cbb84f9f8ba_low.mp4',
          raw: 'https://ofw-assets.fyber.com/offer_campaign/21/52/1d8586d2-fb61-4c47-aa1f-9cbb84f9f8ba_raw.mp4',
          high: 'https://ofw-assets.fyber.com/offer_campaign/21/52/1d8586d2-fb61-4c47-aa1f-9cbb84f9f8ba_high.mp4',
          medium:
            'https://ofw-assets.fyber.com/offer_campaign/21/52/1d8586d2-fb61-4c47-aa1f-9cbb84f9f8ba_medium.mp4',
        },
      ],
    },
    {
      attribution_window: 60,
      type: 'task',
      lpid: 1484119,
      advertiser_id: 80338,
      account_manager_id: 115426,
      comment: '*Fake-Informationen werden nicht gutgeschrieben.',
      pid: 1031197,
      title: 'InBrain_Survey_DE',
      mobile_title: 'An einer Umfrage teilnehmen!',
      url: '//aws.engine.fyber.com/show/fyber?appid=110226&uid=pitis&client=sdk&device_model=&platform=android&appname=IMVU+Google+Play&traffic_source=offer_sdk&country_code=DE&pubid=6477&ip=79.210.186.0&request_timestamp=1645175233070&request_id=ad267dcd-de78-4521-9d5b-2c5ad0d1b85e&os_version=11&ad_id=1484119&ad_format=offer&group=Fyber&rank=15&gdpr_privacy_consent=0&sig=c8faf7de87682b18bb1453d5a08cf3b5635dc890',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Credits',
      rewd_amnt: 6550,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/offercampaign/icon/large-665229858e93745569b09ed6f16d88b2.gif',
      icon_lores:
        'https://icons.fyber-static.com/offercampaign/icon/small-e2768244ec97d1e9746acc9a9398770e.gif',
      icon: 'https://icons.fyber-static.com/offercampaign/icon/086fc828218744abf992d7ad3084bc51.gif',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68393/icos_ofw_survey_original.svg',
      user_task: {
        id: 8,
        icon: 'https://icons.fyber-static.com/assets/68393/icos_ofw_survey_original.svg',
      },
      is_highlighted: false,
      store_id: '',
      size_tag: false,
      preview: true,
      preview_text:
        'Nehmen Sie an schnellen Umfragen teil und verdienen Sie RIESIGE Belohnungen! Sie können mehrere Umfragen ausfüllen, um mehr zu verdienen.',
      is_download: false,
      is_video: false,
      initial_scarcity: 14,
      required_actions: '',
      action_steps: [
        {
          step: 'Nehmen Sie an schnellen Umfragen teil und verdienen Sie RIESIGE Belohnungen! Sie können mehrere Umfragen ausfüllen, um mehr zu verdienen.',
          payout_distribution: 100.0,
          action_id: 'SURVEY',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 5,
      dynamic_payout_enabled: true,
      cost_model: 'cost_per_action',
      complexity: 2,
      mobile_app_primary_category: null,
      non_web_offer: false,
      avg_latency: 8,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 21,
      type: 'task',
      lpid: 1414844,
      advertiser_id: 79189,
      account_manager_id: 115512,
      comment:
        '*Bitte erledigen Sie die Aufgabe in 20 Tagen. *Die Registrierung eines NEUEN Kontos ist Voraussetzung für die Teilnahme an dieser Belohnung. ',
      pid: 1004177,
      title: 'SOV-T1',
      mobile_title: 'Slots of Vegas',
      url: '//aws.engine.fyber.com/show/fyber?appid=110226&uid=pitis&client=sdk&device_model=&platform=android&appname=IMVU+Google+Play&traffic_source=offer_sdk&country_code=DE&pubid=6477&ip=79.210.186.0&request_timestamp=1645175233070&request_id=ad267dcd-de78-4521-9d5b-2c5ad0d1b85e&os_version=11&ad_id=1414844&ad_format=offer&group=Fyber&rank=16&gdpr_privacy_consent=0&sig=b0e9f32a8ed13e0c0611fdb86fa04bd1fe50e41d',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Credits',
      rewd_amnt: 49124,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/68/icon/large-1dbb5b4a10fd914d1e9efc948bc22f89.png',
      icon_lores:
        'https://icons.fyber-static.com/product/68/icon/small-35e430eeb92bc5f98f623686b12c5a70.png',
      icon: 'https://icons.fyber-static.com/product/68/icon/9c3c6106ce2b009c623d73906a1f85bc.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.banditmovie.slotsofvegas',
      size_tag: false,
      preview: true,
      preview_text:
        ' Melde dich an und spiele Slots of Vegas\nErreiche Charakter Level 40\nHol dir Belohnungen!',
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        {
          step: ' Melde dich an und spiele Slots of Vegas',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Erreiche Charakter Level 40',
          payout_distribution: null,
          action_id: '',
        },
        {
          step: 'Hol dir Belohnungen!',
          payout_distribution: null,
          action_id: 'LVL40',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 9005,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Casino',
      non_web_offer: true,
      avg_latency: 11261,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 60,
      type: 'task',
      lpid: 1461278,
      advertiser_id: 79773,
      account_manager_id: 116530,
      comment: 'New Players Only.',
      pid: 1032293,
      title: '925 MR US DACH ',
      mobile_title: 'Install King of Avalon and complete the events!',
      url: '//aws.engine.fyber.com/show/fyber?appid=110226&uid=pitis&client=sdk&device_model=&platform=android&appname=IMVU+Google+Play&traffic_source=offer_sdk&country_code=DE&pubid=6477&ip=79.210.186.0&request_timestamp=1645175233070&request_id=ad267dcd-de78-4521-9d5b-2c5ad0d1b85e&os_version=11&ad_id=1461278&ad_format=offer&group=Fyber&rank=17&gdpr_privacy_consent=0&sig=7c39672590465dc3473c48b97442658bc4879376',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Credits',
      rewd_amnt: 181106,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/icon/large-fb2ba488fcbeb3b410e06f5fd6e53d74.png',
      icon_lores:
        'https://icons.fyber-static.com/product/icon/small-3feb7ecf2609bf60a492dd9d2610beae.png',
      icon: 'https://icons.fyber-static.com/product/18646/icon/fa1e68375bfd94b78ab5d73f543e76b7.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.funplus.kingofavalon',
      size_tag: false,
      preview: true,
      preview_text:
        'Tutorial\nReach City Level 8\nIn-app Package $4.99\nReach City Level 12\nIn-app Package $9.99\nReach City Level 16\nReach City Level 18\nReach City Level 20\nReach City Level 22',
      is_download: false,
      is_video: false,
      initial_scarcity: 1,
      required_actions: '',
      action_steps: [
        {
          action_id: 'TUTORIAL',
          step: 'Tutorial',
          payout_distribution: 0.18,
          rewd_amnt: 326,
        },
        {
          action_id: 'LEVEL8',
          step: 'Reach City Level 8',
          payout_distribution: 1.45,
          rewd_amnt: 2626,
        },
        {
          action_id: 'P499',
          step: 'In-app Package $4.99',
          payout_distribution: 3.62,
          rewd_amnt: 6556,
        },
        {
          action_id: 'LEVEL12',
          step: 'Reach City Level 12',
          payout_distribution: 2.53,
          rewd_amnt: 4582,
        },
        {
          action_id: 'P999',
          step: 'In-app Package $9.99',
          payout_distribution: 5.42,
          rewd_amnt: 9816,
        },
        {
          action_id: 'LEVEL16',
          step: 'Reach City Level 16',
          payout_distribution: 9.04,
          rewd_amnt: 16372,
        },
        {
          action_id: 'LEVEL18',
          step: 'Reach City Level 18',
          payout_distribution: 10.85,
          rewd_amnt: 19650,
        },
        {
          action_id: 'LEVEL20',
          step: 'Reach City Level 20',
          payout_distribution: 12.66,
          rewd_amnt: 22928,
        },
        {
          action_id: 'LEVEL22',
          step: 'Reach City Level 22',
          payout_distribution: 54.25,
          rewd_amnt: 98250,
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 215,
      dynamic_payout_enabled: true,
      cost_model: 'cost_per_engagement',
      complexity: 52,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 272,
      multi_payout: true,
      instruction_steps: [
        'Install King of Avalon',
        'Tutorial',
        'Reach City Level 8',
        'Reach City Level 10',
        'In-app Package $4.99',
        'Reach City Level 12',
        'In-app Package $9.99',
        'Reach City Level 16',
        'Reach City Level 17',
        'Reach City Level 18',
        'Reach City Level 20',
        'Reach City Level 22',
      ],
      asset_urls: [null],
    },
    {
      attribution_window: 7,
      type: 'task',
      lpid: 1432069,
      advertiser_id: 79977,
      account_manager_id: 115512,
      comment:
        '*HINWEIS: Keine Belohnung, wenn du dieses Spiel vorher installiert hast. Benutzer erhalten nur die Belohnung von EINEM Matchington Mansion-Angebot*',
      pid: 1020970,
      title: 'MM-Android-CPE-$24.99',
      mobile_title: 'Matchington Mansion: Match-3 Home D',
      url: '//aws.engine.fyber.com/show/fyber?appid=110226&uid=pitis&client=sdk&device_model=&platform=android&appname=IMVU+Google+Play&traffic_source=offer_sdk&country_code=DE&pubid=6477&ip=79.210.186.0&request_timestamp=1645175233070&request_id=ad267dcd-de78-4521-9d5b-2c5ad0d1b85e&os_version=11&ad_id=1432069&ad_format=offer&group=Fyber&rank=18&gdpr_privacy_consent=0&sig=9040ef792622a6f262074c004bf6c55ead4e1882',
      is_free: false,
      is_trackable: true,
      rewd_curr: 'Credits',
      rewd_amnt: 39300,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/7336/icon/large-ecc0d2f71b2dd850070a7fa105152c16.png',
      icon_lores:
        'https://icons.fyber-static.com/product/7336/icon/small-afb6ea4c5b45724277289e5935f5820b.png',
      icon: 'https://icons.fyber-static.com/product/7336/icon/641d165fa20e0a2d7f34b67d2e80770e.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.matchington.mansion',
      size_tag: false,
      preview: true,
      preview_text:
        'Spiele Matchington Mansion: Match-3 Home Decor Adventure\nBesuche den Shop und kaufe zum ersten Mal das Sachverständiger Restaurator-Paket ($24.99)\nErhalte eine Belohnung!',
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        {
          step: 'Spiele Matchington Mansion: Match-3 Home Decor Adventure',
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Besuche den Shop und kaufe zum ersten Mal das Sachverständiger Restaurator-Paket ($24.99)',
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Erhalte eine Belohnung!',
          payout_distribution: 100,
          action_id: 'EXPERT',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 15,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 2,
      mobile_app_primary_category: 'Puzzle',
      non_web_offer: true,
      avg_latency: 23,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 60,
      type: 'task',
      lpid: 1429030,
      advertiser_id: 79349,
      account_manager_id: 116530,
      comment: 'Das Angebot gilt NUR für Erstnutzer von Money Well!',
      pid: 1019807,
      title: 'Fyber_android_DE_incent_playtime_MoneyWell_30',
      mobile_title: 'Money Well - Earn Money Playing Games',
      url: '//aws.engine.fyber.com/show/fyber?appid=110226&uid=pitis&client=sdk&device_model=&platform=android&appname=IMVU+Google+Play&traffic_source=offer_sdk&country_code=DE&pubid=6477&ip=79.210.186.0&request_timestamp=1645175233070&request_id=ad267dcd-de78-4521-9d5b-2c5ad0d1b85e&os_version=11&ad_id=1429030&ad_format=offer&group=Fyber&rank=19&gdpr_privacy_consent=0&sig=4f410c1f86123c4a5dab7c34759925ffbcd4f7b8',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Credits',
      rewd_amnt: 4912,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/6173/icon/large-9f726b0de7cde3e85742b9025bb831e0.png',
      icon_lores:
        'https://icons.fyber-static.com/product/6173/icon/small-dc3474c749892e0917d28ff542bbd462.png',
      icon: 'https://icons.fyber-static.com/product/6173/icon/fb5aaddc54a5d0449efc7aef27c39f56.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'online.moneywell.app',
      size_tag: false,
      preview: true,
      preview_text:
        'öffne Money Well\nHolen Sie sich zwei der vorgeschlagenen Spiele und spielen Sie insgesamt mindestens 35 Minuten, um die Belohnung zu erhalten.\nDeine Spielzeit wird unter dem jeweiligen Spiel angezeigt.',
      is_download: false,
      is_video: false,
      initial_scarcity: 14,
      required_actions: '',
      action_steps: [
        { step: 'öffne Money Well', payout_distribution: 100, action_id: '' },
        {
          step: 'Holen Sie sich zwei der vorgeschlagenen Spiele und spielen Sie insgesamt mindestens 35 Minuten, um die Belohnung zu erhalten.',
          payout_distribution: 100,
          action_id: '',
        },
        {
          step: 'Deine Spielzeit wird unter dem jeweiligen Spiel angezeigt.',
          payout_distribution: 100,
          action_id: 'TIME_30',
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 60,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 52,
      mobile_app_primary_category: 'Lifestyle',
      non_web_offer: true,
      avg_latency: 81,
      multi_payout: false,
      instruction_steps: [],
      asset_urls: [null],
    },
    {
      attribution_window: 30,
      type: 'task',
      lpid: 1483591,
      advertiser_id: 79519,
      account_manager_id: 115426,
      comment: 'Nur für neue Benutzer.',
      pid: 1012398,
      title: 'Solitaire_Android_DE_Incent_MRGC_Fyber',
      mobile_title: 'Spielen Sie Solitaire',
      url: '//aws.engine.fyber.com/show/fyber?appid=110226&uid=pitis&client=sdk&device_model=&platform=android&appname=IMVU+Google+Play&traffic_source=offer_sdk&country_code=DE&pubid=6477&ip=79.210.186.0&request_timestamp=1645175233070&request_id=ad267dcd-de78-4521-9d5b-2c5ad0d1b85e&os_version=11&ad_id=1483591&ad_format=offer&group=Fyber&rank=20&gdpr_privacy_consent=0&sig=2b1bd6e392edac13d6de4b9a58c2f99b463c8a02',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Credits',
      rewd_amnt: 1474,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires:
        'https://icons.fyber-static.com/product/676/icon/large-0629f266af62067992481cc949a4d991.png',
      icon_lores:
        'https://icons.fyber-static.com/product/676/icon/small-09f43ad23dbec2926a7ce203d114b74b.png',
      icon: 'https://icons.fyber-static.com/product/676/icon/1f5d14196d64e0c1e4e087e5a4539948.png',
      user_task_icon:
        'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: 'https://icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      store_id: 'com.tripledot.solitaire',
      size_tag: false,
      preview: true,
      preview_text:
        'Gewinne 5 Spiele\nGewinne 10 Spiele\nGewinne 25 Spiele\nGewinne 50 Spiele',
      is_download: false,
      is_video: false,
      initial_scarcity: 53,
      required_actions: '',
      action_steps: [
        {
          action_id: 'COMPLETE5',
          step: 'Gewinne 5 Spiele',
          payout_distribution: 6.5,
          rewd_amnt: 96,
        },
        {
          action_id: 'COMPLETE10',
          step: 'Gewinne 10 Spiele',
          payout_distribution: 13.5,
          rewd_amnt: 199,
        },
        {
          action_id: 'COMPLETE25',
          step: 'Gewinne 25 Spiele',
          payout_distribution: 26.5,
          rewd_amnt: 391,
        },
        {
          action_id: 'COMPLETE50',
          step: 'Gewinne 50 Spiele',
          payout_distribution: 53.5,
          rewd_amnt: 788,
        },
      ],
      action_description: 'ACP-EDGE action description',
      billing_relation_id: 0,
      latency: 30,
      dynamic_payout_enabled: true,
      cost_model: 'cost_per_engagement',
      complexity: 50,
      mobile_app_primary_category: 'Card',
      non_web_offer: true,
      avg_latency: 0,
      multi_payout: true,
      instruction_steps: [
        'Laden Sie die Anwendung herunter.',
        'Gewinne Spiele, um Belohnungen zu verdienen. Je mehr Sie gewinnen, desto mehr verdienen Sie!',
      ],
      asset_urls: [null],
    },
  ],
  reengagement: {
    transactions: [],
    total_reward: 0,
    all_transactions_count: 0,
  },
}

export const offerResponsePlacementPreview: OfferResponseDto = {
  data_attrs: {
    'data-request_timestamp': 1606992960180,
    'data-userid': 'therandomuser',
    'data-user-life-stage': 'no_conversions',
    'data-user-creation-timestamp': 1602859669,
    'data-appid': '1246',
    'data-placement_id': '',
    'data-country': 'DE',
    'data-language': 'en',
    'data-integration': 7,
    'data-filter': null,
    'data-external-ofw': false,
    'data-app-category': 'Racing',
    'data-publisher-id': 249,
    'data-user-has-ignite': true,
  },
  page: 1,
  pages: 1,
  is_last: false,
  virtual_currency: 'Tokens',
  virtual_currency_sale_enabled: true,
  virtual_currency_sale_reward_multiplier: 1.1,
  vcs_time_left: 47040,
  mobile_scarcity: {
    '1393431': {
      original_quantity: 32,
      lifespan: 21256,
      timestamp: 1606992957,
      expire_at: 1607057413,
    },
    '1418563': {
      original_quantity: 76,
      lifespan: 21435,
      timestamp: 1606992960,
      expire_at: 1607057595,
    },
    '1418507': {
      original_quantity: 79,
      lifespan: 13115,
      timestamp: 1606992960,
      expire_at: 1607049275,
    },
  },
  in_progress_offers: true,
  in_progress_reward_amount: 2276,
  reengagement: {
    all_transactions_count: 7,
    total_reward: 120,
    transactions: transactionResponse.transactions.map((t) => ({
      ...t,
      expires_in: 1231231,
    })),
  },
  offers: [
    {
      type: 'task',
      lpid: 1418563,
      action_description: '',
      advertiser_id: 74422,
      account_manager_id: 100012,
      attribution_window: 30,
      comment: 'New User Only',
      pid: 1014817,
      title: 'Multi Reward Campaign',
      mobile_title: 'Multi Reward Campaign',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1418563&ad_format=offer&group=Fyber&rank=1&gdpr_privacy_consent=0&sig=caea73f90691192a54b8d727979709c764663004',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 600,
      rewd_amnt_pre_sale: 580,
      actn_text: 'Rewards after you register and deposit 200 GBP/Euro.',
      icon_hires: '/images/placement-preview/icon1.png',
      icon_lores: '/images/placement-preview/icon1.png',
      icon: '/images/placement-preview/icon1.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68395/icos_ofw_purchase_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68395/icos_ofw_purchase_original.svg',
      },
      is_highlighted: false,
      multi_payout: true,
      instruction_steps: [
        'Install the app',
        'Reach level 5',
        'Reach level 10',
        'Reach level 15',
        'Reach level 20',
      ],
      action_steps: [
        {
          action_id: 'LEVEL5',
          completed: true,
          step: 'Reach level 5',
          payout_distribution: 25,
          rewd_amnt: 150,
        },
        {
          action_id: 'LEVEL10',
          completed: true,
          step: 'Reach level 10',
          payout_distribution: 25,
          rewd_amnt: 150,
        },
        {
          action_id: 'LEVEL15',
          completed: false,
          step: 'Reach level 15',
          payout_distribution: 25,
          rewd_amnt: 150,
        },
        {
          action_id: 'LEVEL20',
          completed: false,
          step: 'Complete level 40',
          payout_distribution: 25,
          rewd_amnt: 150,
        },
      ],
      store_id: '',
      size_tag: false,
      preview: true,
      preview_text: 'New users only*',
      is_download: false,
      is_video: false,
      initial_scarcity: 1,
      required_actions: 'Install game and complete the levels!',
      billing_relation_id: 0,
      latency: 30,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: null,
      non_web_offer: false,
      avg_latency: 0,
    },
    {
      type: 'task',
      lpid: 1403071,
      action_steps: [
        {
          step: 'Offer Valid to New Players Who have Never Installed the Game before',
          completed: false,
        },
        {
          step: '*Please complete the task within 28 days*',
          completed: false,
        },
        {
          step: 'Update Your Stronghold Castle to Level 19 to Collect Reward!',
          completed: false,
        },
      ],
      advertiser_id: 59967,
      account_manager_id: 110017,
      attribution_window: 30,
      comment: null,
      pid: 1006459,
      title: 'Survey Campaign',
      mobile_title: 'Survey Campaign',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1403071&ad_format=offer&group=Fyber&rank=3&gdpr_privacy_consent=0&sig=dbef6e40b3095ec9a01e4e2acb94a2e0e4b8b1a5',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 100,
      rewd_amnt_pre_sale: 178,
      actn_text: 'Answer a quick survey!',
      icon_hires: '/images/placement-preview/icon2.png',
      icon_lores: '/images/placement-preview/icon2.png',
      icon: '/images/placement-preview/icon2.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68395/icos_ofw_purchase_original.svg',
      user_task: {
        id: 8,
        icon: '//icons.fyber-static.com/assets/68395/icos_ofw_purchase_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: ['Answer a quick survey', 'Get a rewards!'],
      store_id: '',
      size_tag: false,
      preview: true,
      preview_text: 'Answer a quick survey and get rewards!',
      is_download: false,
      is_video: false,
      initial_scarcity: 4,
      required_actions: 'Platin Casino Bis zu 100â‚¬ GESCHENKT!',
      action_description: 'Platin Casino Bis zu 100â‚¬ GESCHENKT!',
      billing_relation_id: 0,
      latency: 3440,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: null,
      non_web_offer: false,
      avg_latency: 4301,
    },
    {
      type: 'task',
      lpid: 1399746,
      action_steps: [
        {
          step: 'Offer Valid to New Players Who have Never Installed the Game before',
          completed: false,
        },
        {
          step: '*Please complete the task within 28 days*',
          completed: false,
        },
        {
          step: 'Update Your Stronghold Castle to Level 19 to Collect Reward!',
          completed: false,
        },
      ],
      advertiser_id: 78907,
      account_manager_id: 112266,
      attribution_window: 30,
      comment: '',
      pid: 1003763,
      title: 'Donation Campaign',
      mobile_title: 'Donation Campaign',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1399746&ad_format=offer&group=Fyber&rank=4&gdpr_privacy_consent=0&sig=3c9dd9b94b2522450c5f063b6760e4618c995184',
      is_free: false,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 293,
      rewd_amnt_pre_sale: 266,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires: '/images/placement-preview/icon3.png',
      icon_lores: '/images/placement-preview/icon3.png',
      icon: '/images/placement-preview/icon3.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 29,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.playtika.wsop.gp',
      size_tag: '118 MB',
      preview: true,
      preview_text:
        'Enter our website, donate 5$ to save children in Africa and ger rewards!',
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 9915,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Card',
      non_web_offer: true,
      avg_latency: 12397,
    },
    {
      type: 'task',
      lpid: 1407234,
      advertiser_id: 79039,
      account_manager_id: 100012,
      attribution_window: 30,
      comment: 'New Users Only!',
      pid: 996519,
      title: 'Install Campaign',
      mobile_title: 'Install Campaign',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1407234&ad_format=offer&group=Fyber&rank=5&gdpr_privacy_consent=0&sig=8a3d31f8cf2cebf9a2a454ce6db642af31fb600e',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 300,
      rewd_amnt_pre_sale: 255,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires: '/images/placement-preview/icon4.png',
      icon_lores: '/images/placement-preview/icon4.png',
      icon: '/images/placement-preview/icon4.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 27,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.plarium.raidlegends',
      size_tag: '147 MB',
      preview: true,
      preview_text: 'Install the Game and get rewards!',
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        { step: 'Install the game', completed: false },
        {
          step: 'Get rewards!',
          completed: false,
        },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 5420,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Role Playing',
      non_web_offer: true,
      avg_latency: 6780,
    },
    {
      type: 'task',
      lpid: 1407440,
      advertiser_id: 79324,
      account_manager_id: 100012,
      attribution_window: 30,
      pid: 1006421,
      title: 'Purchase Campaign',
      mobile_title: 'Purchase Campaign',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1407440&ad_format=offer&group=Fyber&rank=6&gdpr_privacy_consent=0&sig=14c63ae3e9a3e5bbbb55ed8575d13ba8d56e36cd',
      is_free: false,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 100,
      rewd_amnt_pre_sale: 130,
      actn_text: 'Make a purchase',
      icon_hires: '/images/placement-preview/icon5.png',
      icon_lores: '/images/placement-preview/icon5.png',
      icon: '/images/placement-preview/icon5.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 23,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.MelsoftGames.FamilyIslandFarm',
      size_tag: '112 MB',
      preview: true,
      preview_text: 'Make a purchase',
      is_download: false,
      is_video: false,
      initial_scarcity: 3,
      required_actions: '',
      action_steps: [
        { step: 'Follow the link and make a purchase', completed: false },
        { step: 'Get Rewards!', completed: false },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 865,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 52,
      mobile_app_primary_category: 'Casual',
      non_web_offer: false,
      avg_latency: 1086,
    },
    {
      type: 'task',
      lpid: 1394507,
      advertiser_id: 79183,
      account_manager_id: 115512,
      attribution_window: 30,
      comment: '',
      pid: 1001135,
      title: 'Lead Generation Campaign',
      mobile_title: 'Lead Generation Campaign',
      url: '//aws.engine.fyber.com/show/fyber?appid=1246&uid=therandomuser&client=sdk&device_model=&platform=android&appname=Product+Android+Test&traffic_source=offer_sdk&country_code=DE&pubid=249&ip=62.96.69.0&request_timestamp=1606992960180&request_id=63e90fdc-b15f-43eb-b9ef-80187aa5402e&session_id=x6cwm7tw4kwgk3ianiiw0bczerlcfhif&os_version=10&expname=new_user_engage&expgroup=b&experiments=%5B%7B%22id%22%3A207%2C%22name%22%3A%22tiramisu_unbl%22%2C%22group%22%3A%22a%22%7D%2C%7B%22id%22%3A213%2C%22name%22%3A%22new_user_engage%22%2C%22group%22%3A%22b%22%7D%5D&ad_id=1394507&ad_format=offer&group=Fyber&rank=7&gdpr_privacy_consent=0&sig=6da1d8a5b62d1005dc986b3d6dd316a193c1c1b0',
      is_free: true,
      is_trackable: true,
      rewd_curr: 'Tokens',
      rewd_amnt: 55,
      rewd_amnt_pre_sale: 60,
      actn_text: 'mobile_required_actions.download_and_start',
      icon_hires: '/images/placement-preview/icon6.png',
      icon_lores: '/images/placement-preview/icon6.png',
      icon: '/images/placement-preview/icon6.png',
      user_task_icon:
        '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      user_task: {
        id: 17,
        icon: '//icons.fyber-static.com/assets/68418/icos_ofw_mobile_original.svg',
      },
      is_highlighted: false,
      multi_payout: false,
      instruction_steps: [],
      store_id: 'com.lilithgame.roc.gp',
      size_tag: '101 MB',
      preview: true,
      preview_text: 'Make a simple registration',
      is_download: false,
      is_video: false,
      initial_scarcity: 2,
      required_actions: '',
      action_steps: [
        { step: 'Fill in your personal information', completed: false },
      ],
      action_description: 'ACP-EDGE action step',
      billing_relation_id: 0,
      latency: 16490,
      dynamic_payout_enabled: false,
      cost_model: 'cost_per_engagement',
      complexity: 92,
      mobile_app_primary_category: 'Strategy',
      non_web_offer: true,
      avg_latency: 20615,
    },
  ],
}
