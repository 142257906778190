export function secondsToHours(seconds: number): number {
  const minute = 60 // seconds
  const hour = 60 // minutes
  return Math.ceil(seconds / minute / hour)
}

export function secondsToDays(seconds: number): number {
  const day = 24 // hours
  return Math.ceil(secondsToHours(seconds) / day)
}
