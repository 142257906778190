import { useGetConfig } from 'api/config/hooks'
import Banner from 'components/banner'
import { Menu } from 'components/menu'
import ApiErrorFallbackUI from 'components/shared/error/ApiErrorFallbackUI'
import { RouterTransition } from 'components/transitions'
import useGetCurrentPage from 'hooks/useGetCurrentPage'
import { useEffect } from 'preact/hooks'
import { useStore } from 'store'
import { useMenuStore } from 'store/menu'
import { useRouterStore } from 'store/router'
import { UrlQuery } from 'types/url'

declare global {
  interface Window {
    FyberSDK: any
    navigateBack: any
  }
}

export function Layout({ children }) {
  const { query, pathname } = useRouterStore()
  const { appid, uid } = query as UrlQuery
  const { setIsMenuOpen, isMenuOpen } = useMenuStore()
  const {
    data: config,
    isFetched,
    isError,
    isFetching,
  } = useGetConfig({ enabled: !!appid && !!uid })

  const { createRequestID } = useStore()

  const { currentPage } = useGetCurrentPage()

  const hideBanner =
    (query['client'] === 'api' && pathname === '/mobile') ||
    query['no_offer'] === 'show'

  const isAndroid = query['platform'] === 'android'

  useEffect(() => {
    createRequestID()
  }, [])

  useEffect(() => {
    window.navigateBack = navigateBack
  }, [pathname, currentPage])

  function navigateBack() {
    // calling fyberSDK so that when android presses back button it doesn't close webview
    if (!isAndroid) return

    if (document.getElementById('close-modal-button')) {
      document.getElementById('close-modal-button').click()
      window.FyberSDK.shouldHandleBackButton(false)
      return
    }

    if (currentPage === 'home') {
      window.FyberSDK.shouldHandleBackButton(true)
      return
    }

    window.FyberSDK.shouldHandleBackButton(false)
    window.history.back()
  }

  if (isError || isFetching) {
    return (
      <ApiErrorFallbackUI
        defaultHeader
        bannerHeight={100}
        scrollTopInfinite={0}
        isError={isError}
        isFetching={isFetching}
      />
    )
  }

  return (
    <>
      <Menu
        color={config?.colors.button_color}
        isOpen={isMenuOpen}
        onOpen={() => setIsMenuOpen(true)}
        onClose={() => setIsMenuOpen(false)}
      />
      <RouterTransition location={pathname}>
        {isFetched && !hideBanner && <Banner />}
        {currentPage === 'preview' && <Banner />}
        {/* wait for config to be loaded so that hooks get the info from useStore*/}
        {(currentPage === 'preview' || isFetched) && children}
      </RouterTransition>
    </>
  )
}
