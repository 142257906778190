export function MobileIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-full h-full"
    >
      <path
        d="M7 1.90997H6.24586C6.1971 1.91183 6.15096 1.93251 6.11711 1.96766C6.08327 2.00281 6.06437 2.04971 6.06437 2.09851C6.06437 2.1473 6.08327 2.1942 6.11711 2.22936C6.15096 2.26451 6.1971 2.28519 6.24586 2.28704H7C7.04876 2.28519 7.09491 2.26451 7.12875 2.22936C7.16259 2.1942 7.18149 2.1473 7.18149 2.09851C7.18149 2.04971 7.16259 2.00281 7.12875 1.96766C7.09491 1.93251 7.04876 1.91183 7 1.90997V1.90997Z"
        fill="#201F66"
      />
      <path
        d="M6.16682 11.7305H7.83316C7.88192 11.7286 7.92807 11.708 7.96191 11.6728C7.99575 11.6377 8.01465 11.5908 8.01465 11.542C8.01465 11.4932 7.99575 11.4463 7.96191 11.4111C7.92807 11.376 7.88192 11.3553 7.83316 11.3534H6.16682C6.11806 11.3553 6.07192 11.376 6.03808 11.4111C6.00424 11.4463 5.98533 11.4932 5.98533 11.542C5.98533 11.5908 6.00424 11.6377 6.03808 11.6728C6.07192 11.708 6.11806 11.7286 6.16682 11.7305V11.7305Z"
        fill="#201F66"
      />
      <path
        d="M7.75414 1.90997H7.56547C7.51671 1.91183 7.47056 1.93251 7.43672 1.96766C7.40288 2.00281 7.38398 2.04971 7.38398 2.09851C7.38398 2.1473 7.40288 2.1942 7.43672 2.22936C7.47056 2.26451 7.51671 2.28519 7.56547 2.28704H7.75414C7.8029 2.28519 7.84904 2.26451 7.88289 2.22936C7.91673 2.1942 7.93563 2.1473 7.93563 2.09851C7.93563 2.04971 7.91673 2.00281 7.88289 1.96766C7.84904 1.93251 7.8029 1.91183 7.75414 1.90997V1.90997Z"
        fill="#201F66"
      />
      <path
        d="M8.35269 7.66359H5.62733C5.50699 7.6615 5.38743 7.6834 5.27564 7.728C5.16385 7.77261 5.06207 7.83904 4.97622 7.9234C4.89038 8.00777 4.8222 8.10838 4.77565 8.21938C4.72911 8.33038 4.70514 8.44953 4.70514 8.5699C4.70514 8.69026 4.72911 8.80941 4.77565 8.92041C4.8222 9.03141 4.89038 9.13202 4.97622 9.21639C5.06207 9.30075 5.16385 9.36718 5.27564 9.41179C5.38743 9.45639 5.50699 9.47829 5.62733 9.4762H8.25972L8.47711 9.69495L9.25914 10.477V8.57113C9.25914 8.33062 9.16367 8.09995 8.99371 7.92979C8.82375 7.75962 8.5932 7.66388 8.35269 7.66359V7.66359ZM8.80906 8.57003V9.39034L8.44593 9.02612H5.62733C5.56648 9.02761 5.50594 9.01692 5.44929 8.99466C5.39263 8.9724 5.341 8.93903 5.29743 8.89652C5.25387 8.85401 5.21925 8.80321 5.19561 8.74712C5.17197 8.69102 5.15979 8.63077 5.15979 8.5699C5.15979 8.50902 5.17197 8.44877 5.19561 8.39267C5.21925 8.33658 5.25387 8.28578 5.29743 8.24327C5.341 8.20076 5.39263 8.16739 5.44929 8.14513C5.50594 8.12288 5.56648 8.11218 5.62733 8.11367H8.35269C8.47371 8.11374 8.58974 8.16184 8.67531 8.24741C8.76088 8.33298 8.80899 8.44902 8.80906 8.57003V8.57003Z"
        fill="#201F66"
      />
      <path
        d="M8.35269 4.73651H5.62734C5.38703 4.7368 5.15664 4.83239 4.98671 5.00232C4.81678 5.17225 4.72119 5.40264 4.7209 5.64296V7.54882L5.72059 6.5494H8.35269C8.5931 6.5494 8.82366 6.4539 8.99365 6.28391C9.16364 6.11392 9.25914 5.88336 9.25914 5.64296C9.25914 5.40255 9.16364 5.17199 8.99365 5.002C8.82366 4.83201 8.5931 4.73651 8.35269 4.73651V4.73651ZM5.17098 6.46245V5.64214C5.17112 5.52114 5.21925 5.40515 5.3048 5.3196C5.39036 5.23404 5.50635 5.18591 5.62734 5.18577H8.35269C8.47373 5.18577 8.58981 5.23385 8.67539 5.31944C8.76098 5.40502 8.80906 5.5211 8.80906 5.64214C8.80906 5.76317 8.76098 5.87925 8.67539 5.96484C8.58981 6.05042 8.47373 6.0985 8.35269 6.0985H5.5341L5.17098 6.46245Z"
        fill="#201F66"
      />
      <path
        d="M10.0157 10.7685C9.87246 10.7685 9.75488 10.8979 9.74231 11.0628C9.74231 11.0718 9.74231 11.0808 9.74231 11.0901H9.74613V11.8251C9.74599 11.9236 9.70683 12.018 9.63723 12.0876C9.56763 12.1573 9.47327 12.1965 9.37481 12.1967H4.62548C4.52702 12.1965 4.43265 12.1573 4.36306 12.0876C4.29346 12.018 4.2543 11.9236 4.25415 11.8251V10.4754H4.26318V9.93428H4.25415V2.93428H9.74586V9.27228H9.74203C9.74203 9.28158 9.74203 9.29115 9.74203 9.29963C9.75434 9.46369 9.87246 9.59275 10.0155 9.59275C10.1585 9.59275 10.2777 9.46396 10.2889 9.29963C10.2889 9.29033 10.2889 9.28076 10.2889 9.27228H10.2826V2.17467C10.2823 1.93276 10.1861 1.70084 10.015 1.52978C9.84399 1.35872 9.61207 1.2625 9.37016 1.26221H4.62548C4.38357 1.2625 4.15165 1.35872 3.98059 1.52978C3.80954 1.70084 3.71331 1.93276 3.71302 2.17467V11.8251C3.71331 12.067 3.80954 12.2989 3.98059 12.47C4.15165 12.641 4.38357 12.7373 4.62548 12.7376H9.37426C9.61622 12.7373 9.84821 12.6411 10.0193 12.4701C10.1904 12.299 10.2867 12.0671 10.287 11.8251V11.0901H10.2895C10.2895 11.0808 10.2895 11.0718 10.2895 11.0628C10.2763 10.8979 10.159 10.7685 10.0157 10.7685ZM4.25415 2.39342V2.17467C4.25473 2.07649 4.29408 1.98252 4.36363 1.91322C4.43318 1.84393 4.5273 1.80492 4.62548 1.80471H9.37426C9.47272 1.80485 9.56712 1.84401 9.63676 1.91361C9.70641 1.98321 9.74564 2.07757 9.74586 2.17604V2.39479L4.25415 2.39342Z"
        fill="#201F66"
      />
    </svg>
  )
}
