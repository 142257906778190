import { Offer } from 'api/offer/types'
import { useTracking } from 'api/tracking/hooks'
import { Modal as ModalComponent } from 'components/modal'
import type { ComponentChildren } from 'preact'
import { useCallback, useEffect, useState } from 'preact/hooks'
import { useStore } from 'store'
import { useRouterStore } from 'store/router'
import { useTimer } from './useTimer'

interface Props {
  children: ComponentChildren
  isOfferPage: boolean
  inject?: boolean
  offer?: Offer
  tabCurrentPage?: number
}

export default function useOfferModal() {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const Modal = ({
    children,
    isOfferPage,
    inject,
    offer,
    tabCurrentPage,
  }: Props) => {
    const { injectPixel, fireEvent } = useTracking()
    const { query } = useRouterStore()
    const { requestID } = useStore()
    const { secondsRef } = useTimer()

    const shouldInject = inject && isOpen

    useEffect(() => {
      if (shouldInject) {
        injectPixel('SL', {
          page: isOfferPage ? 'offer_preview' : 'offer_status',
          offer,
          tabCurrentPage,
        })
      }

      return () => {
        if (shouldInject && isOfferPage && query?.appid) {
          fireEvent('offerwall_deselection', {
            request_id: requestID,
            preview_period: secondsRef.current,
            ad_id: query.appid,
          })
        }
      }
    }, [])

    if (isOpen) {
      return (
        <ModalComponent onClickOutside={() => setIsOpen(false)}>
          {children}
        </ModalComponent>
      )
    } else return null
  }

  const open = useCallback(() => {
    setIsOpen(true)
  }, [])

  const close = useCallback(() => {
    setIsOpen(false)
  }, [])

  return { Modal, open, close }
}
