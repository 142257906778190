import { Trans } from 'react-i18next'

interface TabsProps {
  activeTab: number
  changeTabFunction?: (number) => any
  color?: string
  textColor?: string
}

interface TabProps {
  children: any
  isActive: boolean
  onClick?: () => any
  color?: string
  textColor?: string
}

const OfferTabs = ({
  activeTab,
  changeTabFunction,
  color,
  textColor,
}: TabsProps) => {
  return (
    <div
      className={`absolute z-[2] w-full flex bg-white`}
      id={'tabs'}
      style={{
        backgroundColor: color,
      }}
    >
      <Tab
        data-cy={'tab_1'}
        data-active={activeTab === 1 ? 'is-active' : 'not-active'}
        isActive={activeTab === 1}
        color={color}
        textColor={textColor}
        onClick={() => changeTabFunction(1)}
      >
        <Trans i18nKey="tab_1_label">High paying offers</Trans>
      </Tab>
      <Tab
        data-cy={'tab_2'}
        data-active={activeTab === 2 ? 'is-active' : 'not-active'}
        isActive={activeTab === 2}
        color={color}
        textColor={textColor}
        onClick={() => changeTabFunction(2)}
      >
        <Trans i18nKey="tab_2_label">Easy offers</Trans>
      </Tab>
      <div
        style={{
          position: 'absolute',
          left: activeTab === 1 ? '50%' : null,
          right: activeTab === 2 ? '50%' : null,
          bottom: 0,
          backgroundColor: 'white',
          width: '25px',
          height: '25px',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '100%',
            height: '100%',
            borderBottomLeftRadius: activeTab === 1 ? '100%' : null,
            borderBottomRightRadius: activeTab === 2 ? '100%' : null,
            backgroundColor: `${color}`,
          }}
        />
      </div>
    </div>
  )
}

export const Tab = ({
  children,
  color,
  textColor,
  isActive,
  ...props
}: TabProps) => (
  <button
    {...props}
    style={{
      position: 'relative',
      minWidth: 0,
      backgroundColor: isActive ? textColor : color,
      borderTopRightRadius: '25px',
      borderTopLeftRadius: '25px',
      color: isActive ? color : textColor,
    }}
    className={`flex-1 box-border py-3 px-6 mt-2 text-center break-words font-bold cursor-pointer`}
  >
    {children}
  </button>
)

export default OfferTabs
