import { ReactNode } from 'react'

const dimensions = {
  sm: {
    width: 14,
    center: 6.5,
    radius: 5,
    stroke: {
      width: 1.2,
      dasharray: 32,
    },
  },
  lg: {
    width: 212,
    center: 105,
    radius: 100,
    stroke: {
      width: 10,
      dasharray: 630,
    },
  },
}

interface Props {
  size: 'sm' | 'lg'
  percentage: number
  children?: ReactNode
}

export const ProgressCircle = ({ size, percentage, children }: Props) => {
  const { width, center, radius, stroke } = dimensions[size]

  // We can imigate the progress by giving circle element a `stroke-dashoffset`.
  // Full value (for lg, 630) means no progress, 0 means full progress.
  const progress = () =>
    stroke.dasharray - (percentage * stroke.dasharray) / 100
  return (
    <div className="flex justify-center w-full relative">
      <svg
        style={{ transform: 'rotate(-90deg)', strokeLinecap: 'round' }}
        height={width}
        width={width}
        fill="rgba(0,0,0,0)"
        strokeWidth={stroke.width}
      >
        <circle
          cx={center}
          cy={center}
          r={radius}
          fill="rgba(0,0,0,0)"
          stroke="var(--gray-200)"
          strokeDasharray={stroke.dasharray}
        />
        <circle
          cx={center}
          cy={center}
          r={radius}
          fill="rgba(0,0,0,0)"
          stroke="var(--green-500)"
          strokeDasharray={stroke.dasharray}
          strokeDashoffset={progress()}
        />
      </svg>
      {children}
    </div>
  )
}
