import { useTranslation } from 'react-i18next'
import styles from '../styles.module.css'

const SecondScreen = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={styles['step']}>
        <img src="/images/onboarding/open_menu.svg" alt="" />
        <span>{t('onboarding_page2_text1')}</span>
      </div>

      <div className={styles['step']}>
        <img src="/images/onboarding/tap_status.svg" alt="" />
        <span>{t('onboarding_page2_text2')}</span>
      </div>

      <div className={styles['step']}>
        <img src="/images/onboarding/find_offer.svg" alt="" />
        <span>{t('onboarding_page2_text3')}</span>
      </div>
    </>
  )
}

export default SecondScreen
