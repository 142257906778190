import { useEffect, useState } from 'preact/hooks'
import useViewport from './useViewport'

export const DEFAULT_HEIGHT = 100

/**
 * Handle resizable banner height depending on the device type. It returns
 * different aspect ratios which are defined in OPD.
 */
export default function useBannerHeight() {
  const { isTablet, isLandscape } = useViewport({ registerEventListener: true })
  const [height, setHeight] = useState<number>(DEFAULT_HEIGHT)

  useEffect(() => {
    const image = document.querySelector('#banner-image')
    if (image) {
      const width = image.getBoundingClientRect().width
      const height = calculateHeight(isTablet, isLandscape, width)
      setHeight(height)
    }
  }, [isTablet, isLandscape])

  return height
}

export function calculateHeight(
  isTablet: boolean,
  isLandscape: boolean,
  width: number
): number {
  if (isTablet || isLandscape) {
    // Tablet ratio is 5/1 (750wx150h)
    return width / 5
  } else {
    // Phone ratio is 3/1 (300wx100h)
    return width / 3
  }
}
