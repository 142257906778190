export const zh = {
  translations: {
    action_complete_q_action: `完成快速操作`,
    action_complete_survey: `完成快速调查`,
    action_social_media: `社交媒体互动`,
    action_simple_registration: `进行简单注册`,
    action_discover_new: `发现新的服务/产品`,
    action_mobile_sub: `获取特别移动订阅`,
    action_purchase: `购买`,
    action_new_service: `尝试新的服务/产品`,
    action_complete_action: `完成操作`,
    action_quick_quiz: `回答快速测验`,
    action_donation: `进行捐赠`,
    tags_free: `免费`,
    tags_limited_offer: `限时任务`,
    tags_multi_reward: `多重奖励`,
    tags_top_offer: `顶级任务！`,
    tags_new_users: `仅限新用户`,
    up_to: `最多{{ currency }}`,
    menu_faq: `常见问题（FAQ）`,
    menu_offers: `优惠`,
    menu_status: `状态`,
    menu_privacy: `隐私`,
    status_pending: `待处理`,
    status_reported: `已报告`,
    status_expired: `已过期`,
    status_rejected: `已拒绝`,
    status_completed: `已完成任务`,
    dtUserId: `您的 Digital Turbine 用户 ID 为 <1>{{ digitalTurbineId }}</1>`,
    complete_text: `完成`,
    check_inbox: `此任务已被报告。请查看您的电子邮件以获取回复`,
    contacted: `感谢您联系我们的客户服务。我们将尽快回复您`,
    description_report: `感谢您联系用户支持团队。有两个常见原因导致无法收到 {{ currencyText }} 的问题：</0><1>1. 未满足任务条件</1><2>应用程序必须下载并启动。</2><3>所有传输数据都需要正确且真实有效。</3><4>通常需要通过发送到您的电子邮件的链接验证您的电子邮件地址。</4><5>许多广告合作伙伴仅允许每人或每户完成一次任务。</5><6>2. 技术原因</6><7>您在启动推广应用程序时没有有效的网络连接。</7><8>您在开始提供之前已经安装了推广的应用程序。</8>`,
    problem_report: `{{ appName }}的问题报告`,
    problem_type1: `未收到积分`,
    problem_type2: `在任务期间出现错误`,
    problem_type3: `其他`,
    email_confirmation: `确认您的电子邮件`,
    full_name: `全名`,
    message: `留言`,
    submit_text: `提交`,
    onboarding_title_1: `完成任务并获得奖励`,
    onboarding_title_2: `检查任务状态`,
    onboarding_title_3: `缺失奖励？`,
    offer_instructions: `任务说明`,
    offer_details: `*有些奖励可能需要几天时间才会显示在帐户中`,
    next_text: `下一个`,
    done_text: `完成`,
    onboarding_page2_text1: `打开位于左上角的菜单`,
    onboarding_page2_text2: `点击”状态“查看所有任务`,
    onboarding_page2_text3: `找到您的任务并点击打开详细信息
`,
    onboarding_page3_text1: `找到任务并点击”报告问题“`,
    onboarding_page3_text2: `填写表格并提交您的问题`,
    onboarding_page3_text3: `我们的支持团队将与您联系！`,
    reengagement_earn_up: `最多赚取 <1>+{{ currencyValue }}</1> {{ currencyName }}`,
    reengagement_description: `您仍然有进行中的任务！<1></1> 现在完成还为时不晚`,
    reengagement_status_text: `继续游戏`,
    reengagement_expires1: `在{{ days }}天内到期`,
    reengagement_expires2: `在{{ hours }}小时内到期`,
    reengagement_button_text: `查看所有任务`,
    modal_reward: `总奖励`,
    modal_task: `总任务数`,
    modal_time: `完成所需天数`,
    modal_tasks_completed: `已完成任务数`,
    modal_remaining_days: `剩余天数`,
    modal_tasks_text: `任务`,
    modal_instructions_text: `说明`,
    complete_offer: `完成提案`,
    report_button_text: `遇到问题？点击这里`,
    report_timestamp: `您可以在{{ reportTime }}内报告此提案`,
    menu_user_support_guide: `用户支持指南`,
    banner_support: `支持`,
    mr_text_earned: `已获得`,
    mr_text_total: `总计`,
    earn_up_to: `最多赚取{{ currencyValue }} {{ currency }}`,
    earn_text: `赚取{{ currencyValue }} {{ currency }}`,
    up_to_text: `最多`,
    tab_1_label: `高收益任务`,
    tab_2_label: `简单任务`,
  },
}
