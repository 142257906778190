import { transactionOfferFromDto } from '../transaction/dto'
import { TransactionDto } from '../transaction/types'
import {
  ActionType,
  AssetUrl,
  Offer,
  OfferDto,
  OfferResponse,
  OfferResponseDto,
  OfferStep,
  OfferTag,
  Platform,
} from './types'

export function oneOfferResponseFromDto(dto: OfferDto): Offer {
  return offerFromDto(dto, false, 1)
}

export function offerResponseFromDto(
  dto: OfferResponseDto,
  lastPosition: number,
  platform: Platform
): OfferResponse {
  // It is used to find if an offer has "scarcity" or "rarity"
  // So that we can show a `Top Offer!` badge
  const rareOfferIds = Object.keys(dto.mobile_scarcity)

  return {
    offers: dto.offers
      ? dto.offers.map((offerDto, index) => {
          const isRare = rareOfferIds.includes(offerDto.lpid.toString())
          return offerFromDto(offerDto, isRare, lastPosition + index, platform)
        })
      : [],

    tabOneOffers: dto.tab_1_offers
      ? dto.tab_1_offers.map((offerDto, index) => {
          const isRare = rareOfferIds.includes(offerDto.lpid.toString())
          return offerFromDto(offerDto, isRare, lastPosition + index, platform)
        })
      : [],

    tabTwoOffers: dto.tab_2_offers
      ? dto.tab_2_offers.map((offerDto, index) => {
          const isRare = rareOfferIds.includes(offerDto.lpid.toString())
          return offerFromDto(offerDto, isRare, lastPosition + index, platform)
        })
      : [],

    reengagement: {
      totalReward: dto.reengagement?.total_reward,
      allTransactionsCount: dto.reengagement?.all_transactions_count,
      transactions: dto.reengagement?.transactions?.map(
        transactionOfferFromDto
      ),
    },

    pagination: {
      currentPage: dto.page ?? null,
      totalPages: dto.pages ?? null,
      tabOneCurrentPage: dto.tab_1_page ?? null,
      tabOneTotalPages: dto.tab_1_pages ?? null,
      tabTwoCurrentPage: dto.tab_2_page ?? null,
      tabTwoTotalPages: dto.tab_2_pages ?? null,
    },

    currencySale: {
      currency: dto.virtual_currency,
      isEnabled: dto.virtual_currency_sale_enabled,
      rewardMultiplier: dto.virtual_currency_sale_reward_multiplier,
      timeLeft: dto.vcs_time_left,
    },

    dataAttributes: {
      appCategory: dto.data_attrs['data-app-category'] ?? '',
      appId: dto.data_attrs['data-appid'] ?? '',
      country: dto.data_attrs['data-country'] ?? '',
      externalOfw: dto.data_attrs['data-external-ofw'] ?? false,
      filter: dto.data_attrs['data-filter'] ?? '',
      integration: dto.data_attrs['data-integration'],
      language: dto.data_attrs['data-language'] ?? '',
      placementId: dto.data_attrs['data-placement_id'] ?? '',
      publisherId: dto.data_attrs['data-publisher-id'],
      requestTimestamp: dto.data_attrs['data-request_timestamp'],
      userCreationTimestamp: dto.data_attrs['data-user-creation-timestamp'],
      userId: dto.data_attrs['data-userid'],
      userLifeStage: dto.data_attrs['data-user-life-stage'],
      userHasIgnite: dto.data_attrs['data-user-has-ignite'],
    },
  }
}

export function offerFromDto(
  dto: OfferDto,
  isRare: boolean,
  position: number,
  platform?: Platform
): Offer {
  return {
    id: dto.lpid,
    subId: dto.subid,
    creativeId: dto.creative_id,
    title: dto.mobile_title,
    icon: dto.icon,
    url: dto.url,
    reward: {
      amount: dto.rewd_amnt,
      amountPreSales: dto.rewd_amnt_pre_sale,
      currency: dto.rewd_curr ?? dto.virtual_currency,
    },
    comment: dto.comment,
    transactionComment: dto.transaction_comment,
    preview: dto.preview,
    offerUrl: dto.offer_url,
    tags: offerTags(dto, isRare, platform),
    multiPayout: dto.multi_payout,
    actionType: actionType(dto.user_task?.id),
    instructions: dto.instruction_steps ?? [],
    steps: offerSteps(dto),
    complexity: {
      value: dto.complexity,
      text: complexityText(dto.complexity),
    },
    attributionWindow: dto.attribution_window,
    assetUrls: verifyAssets(dto.asset_urls),
    attributes: {
      position,
      accountManagerId: dto.account_manager_id,
      advertiserId: dto.advertiser_id,
      appCategory: dto.mobile_app_primary_category,
      billingRelationId: dto.billing_relation_id,
      costModel: dto.cost_model,
      dynamicPayoutEnabled: dto.dynamic_payout_enabled,
      pid: dto.pid,
      storeId: dto.store_id,
      userTaskCategoryId: dto.user_task?.id,
    },
    in_grace_period: dto.in_grace_period,
  }
}

function verifyAssets(assetUrls: AssetUrl[]) {
  return (
    assetUrls
      ?.filter((assetUrl) => assetUrl)
      ?.filter((assetUrl) => assetUrl.raw) ?? []
  )
}

function offerTags(
  offer: OfferDto,
  isRare: boolean,
  platform: Platform
): OfferTag[] {
  const badges: OfferTag[] = []

  const complexityTag = complexityText(offer.complexity) as OfferTag
  badges.push(complexityTag)

  if (offer.multi_payout) {
    badges.push('multi-rewards')
  }

  if (
    offer.is_free &&
    actionType(offer.user_task?.id) !== 'make-a-donation' &&
    platform === 'ios'
  ) {
    badges.push('free')
  }

  // if it's app campaign
  if (offer.cost_model !== 'cost_per_action') {
    badges.push('new-users-only')
  }

  if (isRare) {
    badges.push('top-offer')
  }

  return badges
}

export function complexityText(value: number): OfferTag {
  if (value <= 33) {
    return 'easy'
  } else if (value > 33 && value <= 66) {
    return 'medium'
  } else {
    return 'hard'
  }
}

export function actionType(taskId: number): ActionType {
  switch (taskId) {
    case 5:
      return 'complete-a-quick-action'
    case 8:
      return 'complete-a-quick-survey'
    case 11:
      return 'social-media-engagement'
    case 14:
      return 'do-a-simple-registration'
    case 17:
      return 'discover-a-new-service/product'
    case 20:
      return 'get-a-special-mobile-subscription'
    case 23:
      return 'make-a-purchase'
    case 26:
      return 'try-a-new-service-product'
    case 27:
      return 'complete-an-action'
    case 28:
      return 'answer-a-quick-quiz'
    case 29:
      return 'make-a-donation'
  }
}

export function offerSteps(dto: OfferDto | TransactionDto): OfferStep[] {
  return dto.action_steps?.map((step) => ({
    actionId: step.action_id,
    completed: step.completed ?? false,
    description: step.step,
    distribution: step.payout_distribution,
    reward: step.rewd_amnt,
  }))
}
