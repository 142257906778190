export function RewardIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 2.00006C6.97999 2.00006 2.49999 6.48006 2.49999 12.0001C2.49999 17.5201 6.97999 22.0001 12.5 22.0001C18.02 22.0001 22.5 17.5201 22.5 12.0001C22.5 6.48006 18.02 2.00006 12.5 2.00006ZM12.5 20.0001C8.08999 20.0001 4.49999 16.4101 4.49999 12.0001C4.49999 7.59006 8.08999 4.00006 12.5 4.00006C16.91 4.00006 20.5 7.59006 20.5 12.0001C20.5 16.4101 16.91 20.0001 12.5 20.0001ZM12.81 11.1401C11.04 10.6901 10.47 10.2001 10.47 9.47006C10.47 8.63006 11.26 8.04006 12.57 8.04006C13.95 8.04006 14.47 8.70006 14.51 9.68006H16.22C16.17 8.34006 15.35 7.11006 13.73 6.71006V5.00006H11.4V6.69006C9.88999 7.01006 8.67999 7.99006 8.67999 9.50006C8.67999 11.2901 10.17 12.1901 12.34 12.7101C14.29 13.1701 14.68 13.8601 14.68 14.5801C14.68 15.1101 14.29 15.9701 12.58 15.9701C10.98 15.9701 10.35 15.2501 10.26 14.3301H8.53999C8.63999 16.0301 9.89999 16.9901 11.4 17.3001V19.0001H13.74V17.3301C15.26 17.0401 16.46 16.1701 16.47 14.5601C16.46 12.3601 14.57 11.6001 12.81 11.1401Z"
        fill="var(--purple-500)"
      />
    </svg>
  )
}
