export function EasyTagIcon() {
  return (
    <svg
      className="w-[26px] h-[12px] md:w-[40px] md:h-[18px]"
      width="26"
      height="13"
      viewBox="0 0 26 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 2.41965C18.6227 0.926775 15.7728 0.110229 12.8288 0.110229C10.0249 0.110229 7.30643 0.850908 5 2.21134L9.1643 5.93739C10.2987 5.455 11.5489 5.19788 12.8288 5.19788C14.2541 5.19788 15.6426 5.51663 16.8751 6.11023L21 2.41965Z"
        fill="#ECECEC"
      />
      <path
        d="M4.03658 3.28179C4.1008 3.22394 4.16583 3.16671 4.23141 3.11023L8 6.59344C7.93009 6.65117 7.86099 6.71015 7.79271 6.77026C7.7499 6.80808 7.70763 6.8464 7.66563 6.88522C6.16637 8.27099 5.32421 10.1504 5.32421 12.1102H0C0 8.84529 1.40333 5.71409 3.90083 3.40552C3.94581 3.36394 3.99106 3.32274 4.03658 3.28179Z"
        fill="#4CAF50"
      />
      <path
        d="M18 6.68696C18.0194 6.70446 18.0387 6.7221 18.0581 6.73973C19.6114 8.16409 20.4839 10.0958 20.4839 12.1102H26C26 8.75438 24.5461 5.53599 21.9586 3.16313C21.9392 3.14537 21.9199 3.12786 21.9005 3.11023L18 6.68696Z"
        fill="#ECECEC"
      />
      <circle cx="13" cy="11.1102" r="1" fill="#353535" />
      <path
        d="M4.5 2.61023L13.0001 11.1103"
        stroke="#353535"
        strokeLinecap="round"
      />
    </svg>
  )
}
