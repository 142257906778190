import type { ComponentChildren } from 'preact'
import { NumericFormat } from 'react-number-format'
import styles from './styles.module.css'

interface Props {
  children: ComponentChildren[]
}

export function OfferTask({ children }: Props) {
  return <ul className={styles.task}>{children}</ul>
}

interface OfferItemProps {
  description: string
  reward: number
  completed: boolean
  isMultiPayout: boolean
  currencyIcon?: string
}

export function OfferTaskItem({
  description,
  reward,
  completed,
  isMultiPayout,
  currencyIcon,
}: OfferItemProps) {
  return (
    <li
      className={styles.taskItem}
      style={{
        background: completed
          ? `url(/icons/checkmark-checked.svg) no-repeat 0`
          : `url(/icons/checkmark.svg) no-repeat 0`,
      }}
    >
      <div className={styles.description}>{description}</div>
      <div className={styles.reward}>
        {currencyIcon && isMultiPayout && (
          <img className={styles.currencyImage} src={currencyIcon} />
        )}
        {!currencyIcon && isMultiPayout && '+'}
        <NumericFormat
          displayType="text"
          value={reward}
          thousandSeparator={true}
          decimalScale={0}
        />
      </div>
    </li>
  )
}
