import { useEffect, useState } from 'preact/hooks'

export default function useStopwatch() {
  const [isRunning, setIsRunning] = useState<boolean>(false)
  const [timeLeft, setTimeLeft] = useState<number | null>(null)

  useEffect(() => {
    let intervalID
    if (isRunning && timeLeft > 0) {
      intervalID = setInterval(
        () => setTimeLeft((prevtimeLeft) => prevtimeLeft - 1),
        1000
      )
    }

    if (timeLeft === 0) {
      setTimeLeft(null)
    }

    return () => clearInterval(intervalID)
  }, [isRunning, timeLeft])

  const handleReset = () => {
    setIsRunning(false)
    setTimeLeft(null)
  }

  const formattedHours = (): string => {
    const hours = Math.floor(timeLeft / 3600).toString()
    return hours.padStart(2, '0')
  }

  const formattedMins = (): string => {
    const mins = Math.floor((timeLeft % 3600) / 60).toString()
    return mins.padStart(2, '0')
  }

  const formattedSecs = (): string => {
    const secs = Math.floor((timeLeft % 3600) % 60).toString()
    return secs.padStart(2, '0')
  }

  const showFormattedTime = (): string => {
    const secondsInADay = 86400
    if (timeLeft > secondsInADay) {
      const daysCount = Math.floor(timeLeft / secondsInADay)
      return `${daysCount} ${daysCount > 1 ? 'days' : 'day'}`
    }

    const finalHours = formattedHours()
    const finalMinutes = formattedMins()
    const finalSeconds = formattedSecs()

    return `${finalHours}:${finalMinutes}:${finalSeconds}`
  }

  return {
    timeLeft,
    setTimeLeft,
    showFormattedTime: () => showFormattedTime(),
    resetTimer: () => handleReset(),
    startTimer: () => setIsRunning(true),
    stopTimer: () => setIsRunning(false),
    isRunning,
  }
}
