function shadeColor(color = '#da1a42', percent: number): string {
  color =
    color.length === 4
      ? `${color[0]}${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`
      : color

  const shade = (colorRGB: number) => {
    const hex = Math.floor(
      Math.min((colorRGB * (100 + percent)) / 100, 255)
    ).toString(16)
    return hex.length === 1 ? `0${hex}` : hex
  }

  const R = parseInt(color.substring(1, 3), 16)
  const G = parseInt(color.substring(3, 5), 16)
  const B = parseInt(color.substring(5, 7), 16)

  const RR = shade(R)
  const GG = shade(G)
  const BB = shade(B)

  return `#${RR}${GG}${BB}`
}

export function makeGradient(
  mainColor: string,
  isSupportBanner?: boolean
): string {
  const shadeColorStartPercent = 40
  const shadeColorEndPercent = -68
  const gradientDirection = '135deg'
  const startColor = shadeColor(mainColor, shadeColorStartPercent)
  const endColor = shadeColor(mainColor, shadeColorEndPercent)
  return `${'linear-gradient'}(${gradientDirection}, ${startColor} 0%, ${endColor} 100%)${
    isSupportBanner ? ' 0% 0% / 100% 1124px' : ''
  }`
}
