import { useTracking } from 'api/tracking/hooks'
import useBannerHeight from 'hooks/useBannerHeight'
import useInfiniteScrollViewport from 'hooks/useInfiniteScrollViewport'
import useViewport from 'hooks/useViewport'
import { useMemo, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import { useSwipeable } from 'react-swipeable'
import { useMenuStore } from 'store/menu'
import { clamp } from 'utils/clamp'
import { CloseIcon } from '../shared/icons/CloseIcon'
import { MenuIcon } from '../shared/icons/MenuIcon'
import Button from './Button'
import FirstScreen from './screens/FirstScreen'
import SecondScreen from './screens/SecondScreen'
import ThirdScreen from './screens/ThirdScreen'
import styles from './styles.module.css'

interface Props {
  close: () => void
}

const Onboarding = ({ close }: Props) => {
  const [screen, setScreen] = useState<number>(1)

  const { scrollTopInfinite } = useInfiniteScrollViewport()
  const { isLandscape } = useViewport({ registerEventListener: true })

  const bannerHeight = useBannerHeight()
  const { injectPixel } = useTracking()
  const setOpenOnboarding = useMenuStore((store) => store.setOpenOnboarding)

  const handlers = useSwipeable({
    onSwipedLeft: goForward,
    onSwipedRight: goBack,
  })

  const { t } = useTranslation()

  const titles = [
    t('onboarding_title_1'),
    t('onboarding_title_2'),
    t('onboarding_title_3'),
  ]

  const height = useMemo(
    () =>
      isLandscape
        ? '100%'
        : clamp(47, bannerHeight - scrollTopInfinite, bannerHeight),
    [bannerHeight, scrollTopInfinite, isLandscape]
  )

  function closeOnboarding() {
    injectPixel('FTUE', { page_id: screen, reason: 'close' })
    setOpenOnboarding(false)
    close()
  }

  function goBack() {
    if (screen !== 1) {
      injectPixel('FTUE', { page_id: screen - 1, reason: 'back' })
      setScreen(screen - 1)
    }
  }

  function goForward() {
    if (screen === 3) {
      injectPixel('FTUE', { page_id: screen, reason: 'done' })
      setOpenOnboarding(false)
      close()
      return
    }

    if (screen < 3) {
      injectPixel('FTUE', { page_id: screen + 1, reason: 'next' })
      setScreen(screen + 1)
    }
  }

  return (
    <div className={styles['onboarding-container']} {...handlers}>
      <a className={styles['close-button']} onClick={closeOnboarding}>
        <CloseIcon />
      </a>
      <header style={{ height }} className={styles['header']}>
        <div
          style={{
            filter: screen === 0 && 'brightness(0.5)',
            top: isLandscape ? `${(bannerHeight - 32) / 2}px` : 'unset',
            left: isLandscape ? 0 : '18px',
          }}
          className={styles['menu-container']}
        >
          <MenuIcon />
        </div>
        {[2, 3].includes(screen) && (
          <img
            style={{
              position: 'absolute',
              left: '48px',
              top: `${bannerHeight / 2}px`,
            }}
            src="/images/onboarding/arrow.svg"
          />
        )}

        <p className={styles['title']}>{titles[screen - 1]}</p>
      </header>
      <div className={styles['offers-container']}>
        {screen === 1 && <FirstScreen />}
        {screen === 2 && <SecondScreen />}
        {screen === 3 && <ThirdScreen />}
      </div>

      <div className={styles['navigation']}>
        <div className="flex items-center justify-center w-full gap-2 p-4 transition-opacity duration-[var(--transition-md)]">
          {[1, 2, 3].map((screenNumber) => (
            <div
              key={screenNumber}
              className={`w-1 h-1 rounded-full bg-gray-200 ${
                screen === screenNumber && `bg-red-500`
              }`}
            />
          ))}
        </div>
        <Button onClick={goForward}>
          {screen === 3 ? t('done_text') : t('next_text')}
        </Button>
      </div>
    </div>
  )
}

export default Onboarding
