import { useGetConfig } from 'api/config/hooks'
import _ from 'lodash'

export type UseExperimentProps = {
  experimentName: string
  variantA: ((props: any) => any) | string
  variantB?: ((props: any) => any) | string
  variantC?: ((props: any) => any) | string
  variantD?: ((props: any) => any) | string
  variantE?: ((props: any) => any) | string
}

export default function useExperiment(props: UseExperimentProps) {
  const experimentName = props.experimentName
  const { data: config } = useGetConfig({ enabled: false })

  const variantName = config?.activeExperiments?.find(
    (experiment) => experiment.name === experimentName
  )?.group

  const selectedVariant = props['variant' + _.toUpper(variantName)]

  if (!variantName || !selectedVariant) {
    return props.variantA
  }

  return selectedVariant
}
