export function kFormatter(reward: number) {
  if (reward > 999999) {
    return parseFloat((reward / 1_000_000).toFixed(1)) + 'M'
  }
  if (reward > 999) {
    return parseFloat((reward / 1_000).toFixed(1)) + 'k'
  }

  return reward
}

export function mFormatter(reward: number) {
  if (reward > 999999) {
    return parseFloat((reward / 1_000_000).toFixed(1)) + 'M'
  }

  return reward
}
