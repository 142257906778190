export function CloseIcon({ fill = 'white' }) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.58984 7.5791L12.0967 4.07227C12.4189 3.75 12.4189 3.22559 12.0967 2.90332C11.7744 2.58105 11.25 2.58105 10.9277 2.90332L7.4209 6.41016L3.91406 2.90332C3.5918 2.58105 3.06738 2.58105 2.74512 2.90332C2.42285 3.22559 2.42285 3.75 2.74512 4.07227L6.25195 7.5791L2.74512 11.0859C2.42285 11.4082 2.42285 11.9326 2.74512 12.2549C3.06738 12.5771 3.5918 12.5771 3.91406 12.2549L7.4209 8.74805L10.9277 12.2549C11.25 12.5771 11.7744 12.5771 12.0967 12.2549C12.4189 11.9326 12.4189 11.4082 12.0967 11.0859L8.58984 7.5791Z"
        fill={fill}
      />
    </svg>
  )
}
