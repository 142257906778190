import { useEffect, useState } from 'preact/hooks'
import { useRouterStore } from 'store/router'
import { Page } from 'types/url'

export default function useGetCurrentPage() {
  const [currentPage, setCurrentPage] = useState<Page>()

  const { pathname } = useRouterStore()

  function getCurrentPage(): Page {
    switch (pathname) {
      case '/mobile':
        return 'home'
      case '/status':
        return 'status'
      case '/support':
        return 'support'
      case '/preview':
        return 'preview'
      case '/placement-preview':
        return 'placementPreview'
    }
  }

  useEffect(() => {
    if (pathname === '') return
    setCurrentPage(getCurrentPage())
  }, [pathname])

  return {
    currentPage,
  }
}
