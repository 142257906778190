import { ReactNode } from 'react'
import styles from './styles.module.css'

interface Props {
  children: ReactNode | ReactNode[]
  onClick: () => void
}

const Button = ({ children, onClick }: Props) => {
  return (
    <button className={styles['nextButton']} onClick={onClick}>
      {children}
    </button>
  )
}

export default Button
