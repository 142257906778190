import { VolumeOffIcon, VolumeUpIcon } from 'components/shared/icons'

interface Props {
  muted: boolean
  onClick: (event: MouseEvent) => void
}

export default function MuteButton({ muted, onClick }: Props) {
  return (
    <button
      className="flex justify-center items-center absolute top-4 right-4 w-8 h-8 border-none rounded-full bg-black/[.3] backdrop-blur-sm"
      onClick={onClick}
    >
      {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
    </button>
  )
}
