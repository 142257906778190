import { ActionType } from 'api/offer/types'
import { Trans } from 'react-i18next'
import styles from './styles.module.css'
import { getActionIcon, getActionText } from './utils'

const OfferAction = ({ type }: { type: ActionType }) => {
  if (type)
    return (
      <div className={styles.action}>
        <div className={styles.actionIcon}>{getActionIcon(type)}</div>
        <span className={styles.text}>
          <Trans>{getActionText(type)}</Trans>
        </span>
      </div>
    )

  return <></>
}

export default OfferAction
