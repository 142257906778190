import { DataCommon, DataSL, QuerySL } from 'api/tracking/types'
import { getDeviceParameters } from 'utils/getDeviceParameters'

type Data = DataCommon & DataSL

export default function sl(data: Data): QuerySL {
  const {
    query,
    attributes,
    config,
    pagination,
    currencySale,
    offer,
    page,
    requestID,
    tabCurrentPage,
  } = data

  const { deviceId, deviceType } = getDeviceParameters(query)

  const getSLActionType = () => {
    switch (offer?.actionType) {
      case 'complete-a-quick-action':
        return 'install_app'
      case 'complete-a-quick-survey':
        return 'complete_survey'
      case 'social-media-engagement':
        return 'social_media_engagement'
      case 'do-a-simple-registration':
        return 'register'
      case 'discover-a-new-service/product':
        return 'generate_lead'
      case 'get-a-special-mobile-subscription':
        return 'subscribe'
      case 'make-a-purchase':
        return 'purchase'
      case 'try-a-new-service-product':
        return 'free_trial'
      case 'complete-an-action':
        return 'complete_action'
      case 'answer-a-quick-quiz':
        return 'quiz'
      case 'make-a-donation':
        return 'donation'
    }
  }

  return {
    a: query.appid,
    client: query.client,
    device_id_type: deviceType,
    device_id: deviceId,
    group: 'Fyber',
    manufacturer: query.manufacturer,
    os_version: query.os_version,
    phone_model: query.phone_model,
    platform: query.platform,
    sdk_version: query.sdk_version,
    c: attributes?.country,
    i: attributes?.integration,
    l: attributes?.language,
    o: offer?.id,
    g: offer?.attributes?.pid ?? offer?.programId,
    d: offer?.attributes?.position,
    placement_id: attributes?.placementId,
    request_id: requestID,
    request_timestamp: attributes?.requestTimestamp,
    u: attributes?.userId,
    p: tabCurrentPage ?? pagination.currentPage,
    vcs_enabled: currencySale.isEnabled,
    experiments: JSON.stringify(config.activeExperiments),
    info_click_origin: page,
    ts: Math.floor(Date.now() / 1000),
    reward_amount: offer?.reward?.amount,
    reward_amount_pre_vcs: offer?.reward?.amountPreSales ?? '',
    action_type: getSLActionType(),
  }
}
