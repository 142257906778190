import axios from 'axios'
import { engineURL } from 'utils/environment'

export type ID = string
export type Timestamp = number

export const api = axios.create({
  baseURL: engineURL,
  headers: {
    'Content-Type': 'application/json',
  },
})

api.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)
