import { ErrorBoundary, Provider } from '@rollbar/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { render } from 'preact'
import { rollbarConfig } from 'utils/rollbar'
import { App } from './app'
import './styles/globals.css'

const queryClient = new QueryClient()

render(
  <Provider config={rollbarConfig}>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </ErrorBoundary>
  </Provider>,

  document.getElementById('app') as HTMLElement
)
