import { UrlQuery } from 'types/url'
import { create } from 'zustand'

interface MenuState {
  query: Partial<UrlQuery>
  setQuery: (query: Partial<UrlQuery>) => void
  pathname: string
  setPathname: (pathname: string) => void
}

export const useRouterStore = create<MenuState>((set) => ({
  query: {
    appid: undefined,
    uid: undefined,
    client: undefined,
  },
  pathname: '',
  setQuery: (query) => set(() => ({ query })),
  setPathname: (pathname) => set(() => ({ pathname })),
}))
