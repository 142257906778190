import { useEffect, useState } from 'preact/hooks'

export default function useViewport({ registerEventListener = true }) {
  const [width, setWidth] = useState(window?.innerWidth ?? 0)
  const [height, setHeight] = useState(window?.innerHeight ?? 0)
  const [isLandscape, setIsLandscape] = useState<boolean | null>(null)
  const [isTablet, setIsTablet] = useState<boolean | null>(null)

  const handleResize = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    setIsLandscape(width / height > 1)
    setIsTablet(width >= 600 && height >= 600)
  }, [width, height])

  useEffect(() => {
    if (registerEventListener) {
      window.addEventListener('resize', handleResize, { passive: true })
    }
    return () => {
      if (registerEventListener) {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [registerEventListener])

  return {
    width,
    height,
    isLandscape,
    isTablet,
  }
}
