import { useTranslation } from 'react-i18next'
import styles from '../styles.module.css'

const ThirdScreen = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={styles['step']}>
        <img src="/images/onboarding/tap_report.svg" alt="" />
        <span>{t('onboarding_page3_text1')}</span>
      </div>

      <div className={styles['step']}>
        <img src="/images/onboarding/fill_form.svg" alt="" />
        <span>{t('onboarding_page3_text2')}</span>
      </div>

      <div className={styles['step']}>
        <img src="/images/onboarding/support_team.svg" alt="" />
        <span>{t('onboarding_page3_text3')}</span>
      </div>
    </>
  )
}

export default ThirdScreen
