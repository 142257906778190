export function ArrowDownIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.91 8.58997L12.5 13.17L17.09 8.58997L18.5 9.99997L12.5 16L6.5 9.99997L7.91 8.58997Z"
        fill="#E4E4E7"
      />
    </svg>
  )
}
