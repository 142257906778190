import { PlayIcon } from 'components/shared/icons'

interface Props {
  onClick: (event: MouseEvent) => void
}

export default function PlayButton({ onClick }: Props) {
  return (
    <button
      className="flex justify-center items-center absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] w-12 h-12 border-none rounded-full bg-black/[.3] shadow-md backdrop-blur-sm"
      onClick={onClick}
    >
      <PlayIcon />
    </button>
  )
}
