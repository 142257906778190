export function TaskIcon() {
  const fillColor = 'var(--red-500)'
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M18.5 6H13.5C13.5 4.3 12.2 3 10.5 3H6.5C5.9 3 5.5 2.6 5.5 2C5.5 1.4 5.9 1 6.5 1H20C20.3 1 20.5 0.8 20.5 0.5C20.5 0.2 20.3 0 20 0H6.5C5.4 0 4.5 0.9 4.5 2C4.5 3.1 5.4 4 6.5 4H10.5C11.6 4 12.5 4.9 12.5 6H6.5C3.2 6 0.5 8.7 0.5 12V20C0.5 22.2 2.3 24 4.5 24C5.6 24 6.6 23.6 7.3 22.8L11.9 18.1C12.1 18 12.2 17.9 12.4 17.9C12.6 17.9 12.8 18 12.9 18.1L17.5 22.8C18.2 23.5 19.2 24 20.3 24C22.5 24 24.3 22.2 24.3 20V12C24.5 8.7 21.8 6 18.5 6ZM22.5 20C22.5 21.1 21.6 22 20.5 22C19.9 22 19.4 21.8 19.1 21.4L14.6 16.9C14.6 16.9 13.7 16 12.5 16C11.3 16 10.4 16.9 10.4 16.9L5.9 21.4C5.6 21.8 5.1 22 4.5 22C3.4 22 2.5 21.1 2.5 20V12C2.5 9.8 4.3 8 6.5 8H18.5C20.7 8 22.5 9.8 22.5 12V20Z"
          fill={fillColor}
        />
        <path
          d="M17.5 16C18.0523 16 18.5 15.5523 18.5 15C18.5 14.4477 18.0523 14 17.5 14C16.9477 14 16.5 14.4477 16.5 15C16.5 15.5523 16.9477 16 17.5 16Z"
          fill={fillColor}
        />
        <path
          d="M17.5 12C18.0523 12 18.5 11.5523 18.5 11C18.5 10.4477 18.0523 10 17.5 10C16.9477 10 16.5 10.4477 16.5 11C16.5 11.5523 16.9477 12 17.5 12Z"
          fill={fillColor}
        />
        <path
          d="M15.5 14C16.0523 14 16.5 13.5523 16.5 13C16.5 12.4477 16.0523 12 15.5 12C14.9477 12 14.5 12.4477 14.5 13C14.5 13.5523 14.9477 14 15.5 14Z"
          fill={fillColor}
        />
        <path
          d="M19.5 14C20.0523 14 20.5 13.5523 20.5 13C20.5 12.4477 20.0523 12 19.5 12C18.9477 12 18.5 12.4477 18.5 13C18.5 13.5523 18.9477 14 19.5 14Z"
          fill={fillColor}
        />
        <path
          d="M9.5 12H8.5V11C8.5 10.4 8.1 10 7.5 10C6.9 10 6.5 10.4 6.5 11V12H5.5C4.9 12 4.5 12.4 4.5 13C4.5 13.6 4.9 14 5.5 14H6.5V15C6.5 15.6 6.9 16 7.5 16C8.1 16 8.5 15.6 8.5 15V14H9.5C10.1 14 10.5 13.6 10.5 13C10.5 12.4 10.1 12 9.5 12Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
