import { Offer as OfferType } from 'api/offer/types'
import OfferAction from 'components/offer/Action'
import Tag from 'components/tag'
import useInfiniteScrollViewport from 'hooks/useInfiniteScrollViewport'
import { memo } from 'preact/compat'
import { useEffect, useRef, useState } from 'preact/hooks'
import { Fade } from '../../transitions'
import RewardButton from './RewardButton'
import styles from './styles.module.css'

interface Props {
  offer: OfferType
  onClick: () => void
  /**
   * Fired only once when the offer is visible
   */
  onVisible?: () => void
}

const OfferRow = memo(({ offer, onClick, onVisible }: Props) => {
  const [injected, setInjected] = useState<boolean>(false)
  const [fadeIn, setFadeIn] = useState<boolean>(false)
  const offerRef = useRef(null)

  const { isOfferVisible } = useInfiniteScrollViewport()

  useEffect(() => {
    if (injected || !onVisible) {
      return
    }

    const position = offerRef.current.getBoundingClientRect()

    if (isOfferVisible(position)) {
      onVisible()
      setInjected(true)
    }
  }, [isOfferVisible])

  useEffect(() => {
    setFadeIn(true)
  }, [])

  return (
    <Fade in={fadeIn}>
      <li
        className={styles.offer}
        ref={offerRef}
        onClick={onClick}
        data-cy="offer-row"
      >
        <img
          className={styles.image}
          width={51}
          height={51}
          src={offer.icon}
          style={{ backgroundImage: `url(/images/loader.svg)` }}
          onLoad={(e: any) => {
            e.target.style.backgroundImage = 'unset'
          }}
          onError={(e: any) => {
            e.target.onerror = null
            e.target.src = '/images/digital-turbine-logo.svg'
          }}
          loading="lazy"
        />
        <div className={styles.content}>
          <h2 className={styles.title}>{offer.title}</h2>
          <OfferAction type={offer.actionType} />
          <div className={styles.tags}>
            {offer.tags.map((tag) => (
              <>
                {tag !== 'new-users-only' && (
                  <Tag key={`tag-${offer.id}-${tag}`} type={tag} />
                )}
              </>
            ))}
          </div>
        </div>
        <RewardButton offer={offer} />
      </li>
    </Fade>
  )
})

export default OfferRow
