interface Props {
  color: string
  isTablet: boolean
}

export function RewardClipPath({ color }: Props) {
  return (
    <svg
      className="absolute w-full rounded-md"
      width="92"
      height="15"
      viewBox="0 0 92 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3168_34506)">
        <path
          d="M45.0991 0.249178C46.0257 0.0843529 46.9743 0.084353 47.9009 0.249179L104 10.2273V15H-11V10.2273L45.0991 0.249178Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3168_34506">
          <path
            d="M0 0H92V9C92 11.7614 89.7614 14 87 14H5C2.23858 14 0 11.7614 0 9V0Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
