import { AssetUrl } from 'api/offer/types'

interface Props {
  assetUrl: AssetUrl
}

export default function Image({ assetUrl }: Props) {
  return (
    <img
      className="flex-shrink-0 m-0 p-0 w-full h-full object-contain"
      draggable={false}
      src={assetUrl.low}
    />
  )
}
