import { ar } from './languages/ar'
import { de } from './languages/de'
import { en } from './languages/en'
import { es } from './languages/es'
import { fr } from './languages/fr'
import { it } from './languages/it'
import { ja } from './languages/ja'
import { ko } from './languages/ko'
import { nl } from './languages/nl'
import { pl } from './languages/pl'
import { pt } from './languages/pt'
import { pt_BR } from './languages/pt-BR'
import { ru } from './languages/ru'
import { sv } from './languages/sv'
import { tr } from './languages/tr'
import { zh } from './languages/zh'

export const resources = {
  ar,
  de,
  en,
  es,
  fr,
  it,
  ja,
  ko,
  nl,
  pl,
  'pt-BR': pt_BR,
  pt,
  ru,
  sv,
  tr,
  zh,
}
