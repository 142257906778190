import { useEffect, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import styles from '../styles.module.css'

const FirstScreen = () => {
  const [showImages, setShowImages] = useState<boolean>(true)

  const { t } = useTranslation()

  useEffect(() => {
    const timer = setTimeout(() => setShowImages(false), 1300)

    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      <div className={styles['offer-container']}>
        <div className={styles['offer-container-row']}>
          <img src="/images/onboarding/offer_cow.svg" alt="" />
          <div className={styles['offer-middle']}>
            <div className={styles['empty-row']}></div>
            <div className={styles['empty-row']}></div>
            <img
              style={{ width: '26px', height: '12px' }}
              src="/images/onboarding/meter.svg"
              alt=""
            />
          </div>
          <img
            style={{ height: '42px' }}
            src="/images/onboarding/money_button.svg"
            alt=""
          />
        </div>
        <div className={styles['offer-container-details']}>
          <p>{t('offer_instructions')}</p>
          <div className={styles['offer-container-instructions']}>
            <img src="/images/onboarding/uncheck.svg" alt="" />
            <div />
          </div>
          <div className={styles['offer-container-icons']}>
            <img src="/images/onboarding/controller.svg" alt="" />
            <div className="h-[60px] w-px bg-gray-200"></div>
            <img src="/images/onboarding/money.svg" alt="" />
            <div className="h-[60px] w-px bg-gray-200"></div>

            <img src="/images/onboarding/hourglass.svg" alt="" />
          </div>
          <img
            className={styles['money-icon']}
            src="/images/onboarding/money_button_big.svg"
            alt=""
          />
        </div>
      </div>

      {showImages && (
        <div className={styles['disappear-offer']}>
          <img src="/images/onboarding/offer_1.svg" alt="" />
          <img src="/images/onboarding/offer_2.svg" alt="" />
        </div>
      )}
      {!showImages && (
        <p className={styles['info-note']}>{t('offer_details')}</p>
      )}
    </>
  )
}

export default FirstScreen
