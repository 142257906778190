import { Modal as ModalComponent } from 'components/modal'
import type { ComponentChildren } from 'preact'
import { useCallback, useState } from 'preact/hooks'

interface Props {
  children: ComponentChildren
}

export default function useModal() {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const Modal = ({ children }: Props) => {
    if (!isOpen) return null

    if (isOpen) {
      return (
        <ModalComponent onClickOutside={() => setIsOpen(false)}>
          {children}
        </ModalComponent>
      )
    }
  }

  const open = useCallback(() => {
    setIsOpen(true)
  }, [])

  const close = useCallback(() => {
    setIsOpen(false)
  }, [])

  return { Modal, open, close }
}
