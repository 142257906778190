import { create } from 'zustand'

interface MenuState {
  isMenuOpen: boolean
  setIsMenuOpen: (isOpen: boolean) => void
  openOnboarding: boolean | undefined
  setOpenOnboarding: (isOpen: boolean) => void
  activeTab: number
  setActiveTab: (tab: number) => void
}

export const useMenuStore = create<MenuState>((set) => ({
  isMenuOpen: false,
  openOnboarding: undefined,
  activeTab: 1,
  setIsMenuOpen: (isMenuOpen) => set(() => ({ isMenuOpen })),
  setOpenOnboarding: (openOnboarding) => set(() => ({ openOnboarding })),
  setActiveTab: (activeTab) => set(() => ({ activeTab })),
}))
