export function ArrowLeftIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 7.50461C14 7.03144 13.6263 6.64741 13.1659 6.64741C10.7387 6.64741 3.84922 6.64741 3.84922 6.64741C3.84922 6.64741 6.66425 3.75438 7.91954 2.4643C8.07635 2.30315 8.16476 2.08457 8.16393 1.85655C8.16393 1.62854 8.07552 1.40996 7.91788 1.24966L7.91704 1.2488C7.59091 0.916211 7.06461 0.917068 6.73932 1.25137C5.47568 2.55002 2.51052 5.59735 1.24439 6.89857C1.08841 7.05887 1 7.27745 1 7.50461C1 7.73177 1.08841 7.95035 1.24439 8.11065C2.50635 9.40758 5.45483 12.4378 6.72597 13.7441C7.05794 14.0853 7.59675 14.0853 7.92872 13.7441C8.2465 13.4175 8.2465 12.8886 7.92955 12.5629C6.6851 11.2814 3.84922 8.3618 3.84922 8.3618H13.1659C13.6263 8.3618 14 7.97778 14 7.50461Z"
        fill="white"
      />
    </svg>
  )
}
