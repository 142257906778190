export const ru = {
  translations: {
    action_complete_q_action: `Завершите быстрoe действие`,
    action_complete_survey: `Завершите быстрый опрос`,
    action_social_media: `Вовлечение в социальные медиа`,
    action_simple_registration: `Выполните простую регистрацию`,
    action_discover_new: `Откройте для себя новый сервис/продукт`,
    action_mobile_sub: `Получите специальную мобильную подписку`,
    action_purchase: `Сделайте покупку`,
    action_new_service: `Попробуйте новый сервис/продукт`,
    action_complete_action: `Завершите действие`,
    action_quick_quiz: `Ответьте на быстрый тест`,
    action_donation: `Сделайте пожертвование`,
    tags_free: `Бесплатно`,
    tags_limited_offer: `Лимитированное предложение`,
    tags_multi_reward: `Множественные вознаграждения`,
    tags_top_offer: `Топ-предложение!`,
    tags_new_users: `Только новые пользователи`,
    up_to: `До +{{ currencyValue }} {{ currency }}`,
    menu_faq: `Часто задаваемые вопросы (FAQ)`,
    menu_offers: `Предложения`,
    menu_status: `Статус`,
    menu_privacy: `Конфиденциальность`,
    status_pending: `В ожидании`,
    status_reported: `Сообщено`,
    status_expired: `Истекло`,
    status_rejected: `Отклонено`,
    status_completed: `Завершенная задача`,
    dtUserId: `Ваш идентификатор пользователя Digital Turbine: <1>{{ digitalTurbineId }}</1>`,
    complete_text: `Завершено`,
    check_inbox: `Это предложение было сообщено. Пожалуйста, проверьте свою электронную почту для ответа.`,
    contacted: `Благодарим за обращение в нашу службу поддержки. Мы ответим вам как можно скорее.`,
    description_report: `<0>Спасибо за обращение в службу поддержки пользователей. Существует две общие причины проблем с получением {{ currencyText }}</0><1>1. Не выполнены требования предложения</1><2>Приложения должны быть загружены И запущены для завершения предложения.</2><3>Все данные должны быть введены правильно и честно.</3><4>Зачастую необходимо подтвердить свой адрес электронной почты по ссылке, отправленной на ваш электронный адрес.</4><5>Многие рекламные партнеры разрешают только одну транзакцию на человека или домохозяйство.</5><6>2. Технические причины</6><7>У вас не было рабочего подключения к данным, когда вы запустили продвигаемое приложение.</7><8>У вас уже было установлено продвигаемое приложение до начала предложения.</8>`,
    problem_report: `Отчет о проблеме для {{ appName }}`,
    problem_type1: `Кредиты не получены`,
    problem_type2: `Ошибка во время предложения`,
    problem_type3: `Другое`,
    email_confirmation: `Подтвердите ваш адрес электронной почты`,
    full_name: `Полное имя`,
    message: `Сообщение`,
    submit_text: `Отправить`,
    onboarding_title_1: `Завершите предложение и получите награды`,
    onboarding_title_2: `Проверьте статус предложения`,
    onboarding_title_3: `Отсутствующие награды?`,
    offer_instructions: `Инструкции по предложению`,
    offer_details: `*Некоторые награды могут занять несколько дней, прежде чем они появятся на счете`,
    next_text: `Далее`,
    done_text: `Готово`,
    onboarding_page2_text1: `Откройте меню, расположенное в левом верхнем углу`,
    onboarding_page2_text2: `Нажмите "Статус", чтобы просмотреть все ваши предложения`,
    onboarding_page2_text3: `Найдите ваше предложение и нажмите, чтобы открыть детали`,
    onboarding_page3_text1: `Найдите предложение и нажмите "Сообщить о проблеме"`,
    onboarding_page3_text2: `Заполните форму и отправьте свою проблему`,
    onboarding_page3_text3: `Наша служба поддержки свяжется с вами!`,
    reengagement_earn_up: `Заработайте до <1>+{{ currencyValue }}</1> {{ currencyName }}`,
    reengagement_description: `У вас все еще есть предложения, которые выполняются! <1></1> Не слишком поздно, чтобы их завершить`,
    reengagement_status_text: `Продолжайте играть`,
    reengagement_expires1: `Истекает через {{ days }} дней`,
    reengagement_expires2: `Истекает через {{ hours }} часов`,
    reengagement_button_text: `ПОКАЗАТЬ ВСЕ ПРЕДЛОЖЕНИЯ`,
    modal_reward: `Общее количество наград`,
    modal_task: `Общее количество задач`,
    modal_time: `Дни на завершение`,
    modal_tasks_completed: `Завершенные задачи`,
    modal_remaining_days: `Осталось дней`,
    modal_tasks_text: `Задачи`,
    modal_instructions_text: `Инструкции`,
    complete_offer: `Завершить предложение`,
    report_button_text: `Есть проблемы? Нажмите здесь`,
    report_timestamp: `Вы сможете сообщить об этом предложении в течение {{ reportTime }}`,
    menu_user_support_guide: `Руководство по поддержке пользователя`,
    banner_support: `Поддержка`,
    mr_text_earned: `Заработано`,
    mr_text_total: `Всего`,
    earn_up_to: `Заработайте до {{ currencyValue }} {{ currency }}`,
    earn_text: `Заработайте {{ currencyValue }} {{ currency }}`,
    up_to_text: `До`,
    tab_1_label: `Высокооплачиваемые задания`,
    tab_2_label: `Легкие задания`,
  },
}
