import { CSSProperties } from 'react'

interface FlexProps {
  alignItems?: CSSProperties['alignItems']
  justifyContent?: CSSProperties['justifyContent']
  flexDirection?: CSSProperties['flexDirection']
  flexGrow?: CSSProperties['flexGrow']
  gap?: CSSProperties['gap']
  margin?: CSSProperties['margin']
  padding?: CSSProperties['padding']
  backgroundColor?: CSSProperties['backgroundColor']
  gridArea?: CSSProperties['gridArea']
  zIndex?: CSSProperties['zIndex']
  children?: any
}

type Props = FlexProps & {
  onClick?: () => void
}

export const Flex = (props: Props) => {
  const { onClick, ...rest } = props

  return (
    <div style={{ display: 'flex', ...rest }} onClick={onClick}>
      {props.children}
    </div>
  )
}
