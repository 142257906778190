export function RegisterIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-full h-full"
    >
      <path
        d="M8.87606 8.03167C8.78691 8.0316 8.70143 7.99613 8.63841 7.93307C8.5754 7.87 8.54 7.7845 8.54 7.69534V7.20316C8.54 6.80923 8.38352 6.43144 8.10497 6.15289C7.82642 5.87434 7.44862 5.71785 7.05469 5.71785C6.66076 5.71785 6.28297 5.87434 6.00442 6.15289C5.72587 6.43144 5.56938 6.80923 5.56938 7.20316V7.36011H6.94532C7.03282 7.36261 7.1159 7.39912 7.17691 7.4619C7.23792 7.52467 7.27206 7.60876 7.27206 7.6963C7.27206 7.78384 7.23792 7.86793 7.17691 7.93071C7.1159 7.99348 7.03282 8.03 6.94532 8.03249H5.23332C5.18916 8.03249 5.14542 8.02379 5.10462 8.00689C5.06381 7.98999 5.02673 7.96521 4.9955 7.93398C4.96427 7.90275 4.9395 7.86568 4.9226 7.82487C4.90569 7.78407 4.897 7.74033 4.897 7.69616V7.20398C4.897 6.63173 5.12432 6.08291 5.52897 5.67827C5.93362 5.27362 6.48243 5.0463 7.05469 5.0463C7.62695 5.0463 8.17577 5.27362 8.58041 5.67827C8.98506 6.08291 9.21239 6.63173 9.21239 7.20398V7.69616C9.21217 7.78522 9.17664 7.87056 9.11359 7.93345C9.05054 7.99635 8.96512 8.03167 8.87606 8.03167V8.03167Z"
        fill="#201F66"
      />
      <path
        d="M7.05469 5.71704C6.6299 5.71711 6.21746 5.57413 5.88385 5.31116C5.55025 5.04818 5.31491 4.68054 5.21578 4.26747C5.11664 3.85441 5.15949 3.42 5.33741 3.03426C5.51533 2.64853 5.81796 2.33394 6.19651 2.14121C6.57507 1.94848 7.00749 1.88883 7.42408 1.97189C7.84068 2.05495 8.21717 2.27586 8.49287 2.59903C8.76857 2.9222 8.92741 3.32879 8.9438 3.75327C8.96019 4.17774 8.83317 4.59537 8.58321 4.93883C8.55721 4.97456 8.52443 5.00482 8.48674 5.02788C8.44905 5.05094 8.40718 5.06635 8.36353 5.07323C8.31989 5.08011 8.27531 5.07833 8.23235 5.06799C8.1894 5.05764 8.1489 5.03893 8.11317 5.01294C8.07744 4.98694 8.04718 4.95416 8.02412 4.91647C8.00106 4.87877 7.98565 4.83691 7.97877 4.79326C7.97189 4.74962 7.97367 4.70504 7.98402 4.66208C7.99436 4.61913 8.01307 4.57863 8.03907 4.5429C8.20002 4.32155 8.28174 4.05245 8.27107 3.77898C8.26039 3.50551 8.15794 3.2436 7.98022 3.03547C7.80251 2.82734 7.55988 2.68512 7.29146 2.63173C7.02304 2.57834 6.74446 2.6169 6.50063 2.74119C6.2568 2.86547 6.06192 3.06824 5.94741 3.31682C5.83291 3.56539 5.80544 3.84529 5.86945 4.11138C5.93345 4.37747 6.0852 4.61425 6.30022 4.78356C6.51524 4.95288 6.78101 5.04485 7.05469 5.04465C7.14219 5.04715 7.22528 5.08366 7.28629 5.14644C7.3473 5.20922 7.38143 5.29331 7.38143 5.38085C7.38143 5.46839 7.3473 5.55248 7.28629 5.61525C7.22528 5.67803 7.14219 5.71454 7.05469 5.71704V5.71704Z"
        fill="#201F66"
      />
      <path
        d="M11.4781 12.4414H2.63129C2.36168 12.4414 2.10307 12.3344 1.91222 12.144C1.72137 11.9536 1.61386 11.6952 1.61328 11.4256V9.78495C1.61357 9.51534 1.7208 9.25685 1.91145 9.0662C2.10209 8.87556 2.36058 8.76833 2.6302 8.76804H11.4781C11.7477 8.76804 12.0063 8.87499 12.1972 9.06543C12.388 9.25587 12.4955 9.51424 12.4961 9.78386V11.4245C12.4958 11.6943 12.3884 11.9529 12.1975 12.1436C12.0067 12.3343 11.7479 12.4414 11.4781 12.4414V12.4414ZM2.63129 9.43932C2.53996 9.43947 2.45241 9.47581 2.38783 9.5404C2.32325 9.60498 2.2869 9.69253 2.28676 9.78386V11.4245C2.28683 11.5158 2.32315 11.6034 2.38775 11.668C2.45235 11.7326 2.53994 11.7689 2.63129 11.769H11.4781C11.5694 11.7689 11.657 11.7326 11.7216 11.668C11.7862 11.6034 11.8225 11.5158 11.8226 11.4245V9.78386C11.8225 9.69253 11.7861 9.60498 11.7215 9.5404C11.657 9.47581 11.5694 9.43947 11.4781 9.43932H2.63129Z"
        fill="#201F66"
      />
      <path
        d="M4.08188 11.3392C4.01536 11.3392 3.95035 11.3194 3.89505 11.2825C3.83976 11.2455 3.79667 11.1929 3.77124 11.1315C3.74581 11.07 3.73917 11.0024 3.75217 10.9372C3.76516 10.8719 3.79721 10.812 3.84426 10.765L4.57324 10.0352C4.63679 9.97455 4.72153 9.94119 4.80936 9.94222C4.89718 9.94326 4.98111 9.9786 5.04321 10.0407C5.10532 10.1028 5.14066 10.1867 5.1417 10.2746C5.14273 10.3624 5.10937 10.4471 5.04875 10.5107L4.32031 11.2408C4.28906 11.2721 4.25189 11.297 4.21096 11.3139C4.17003 11.3308 4.12616 11.3394 4.08188 11.3392Z"
        fill="#201F66"
      />
      <path
        d="M4.81086 11.3392C4.76672 11.3392 4.72301 11.3306 4.68223 11.3137C4.64146 11.2968 4.60442 11.272 4.57324 11.2408L3.84426 10.5118C3.78105 10.4486 3.74552 10.363 3.74547 10.2737C3.74542 10.1843 3.78085 10.0986 3.84398 10.0354C3.90711 9.97223 3.99276 9.9367 4.08209 9.93665C4.17142 9.93659 4.25711 9.97203 4.32031 10.0352L5.04929 10.7641C5.09634 10.8112 5.12839 10.8711 5.14139 10.9363C5.15439 11.0016 5.14775 11.0692 5.12232 11.1306C5.09688 11.1921 5.0538 11.2446 4.9985 11.2816C4.94321 11.3186 4.87819 11.3383 4.81168 11.3384L4.81086 11.3392Z"
        fill="#201F66"
      />
      <path
        d="M6.55238 11.3392C6.48587 11.3392 6.42085 11.3194 6.36556 11.2824C6.31027 11.2455 6.26718 11.1929 6.24174 11.1315C6.21631 11.07 6.20967 11.0024 6.22267 10.9371C6.23567 10.8719 6.26772 10.812 6.31477 10.765L7.04375 10.0352C7.10695 9.97206 7.19263 9.93666 7.28193 9.93674C7.37124 9.93681 7.45685 9.97236 7.51995 10.0356C7.58304 10.0988 7.61844 10.1844 7.61836 10.2737C7.61829 10.3631 7.58274 10.4487 7.51953 10.5118L6.79055 11.2407C6.7593 11.272 6.72217 11.2968 6.68129 11.3137C6.64042 11.3306 6.59661 11.3393 6.55238 11.3392V11.3392Z"
        fill="#201F66"
      />
      <path
        d="M7.28136 11.3392C7.23722 11.3392 7.19351 11.3305 7.15273 11.3137C7.11196 11.2968 7.07492 11.272 7.04374 11.2407L6.31476 10.5118C6.28347 10.4805 6.25863 10.4435 6.24167 10.4026C6.22471 10.3618 6.21595 10.318 6.2159 10.2738C6.21579 10.1846 6.25116 10.0989 6.31422 10.0357C6.37727 9.9725 6.46285 9.93693 6.55213 9.93683C6.64141 9.93673 6.72707 9.97209 6.79027 10.0352L7.51952 10.7652C7.56658 10.8123 7.59864 10.8722 7.61163 10.9375C7.62462 11.0027 7.61796 11.0704 7.59249 11.1319C7.56702 11.1933 7.52389 11.2459 7.46856 11.2828C7.41322 11.3198 7.34817 11.3395 7.28163 11.3395L7.28136 11.3392Z"
        fill="#201F66"
      />
      <path
        d="M9.29851 11.3392C9.232 11.3391 9.16698 11.3194 9.11169 11.2824C9.0564 11.2454 9.01331 11.1929 8.98788 11.1314C8.96244 11.07 8.9558 11.0024 8.9688 10.9371C8.9818 10.8719 9.01385 10.812 9.0609 10.765L9.78906 10.0352C9.85211 9.97213 9.93762 9.93674 10.0268 9.93677C10.1159 9.93679 10.2014 9.97223 10.2644 10.0353C10.3274 10.0983 10.3628 10.1839 10.3628 10.273C10.3628 10.3622 10.3273 10.4476 10.2643 10.5107L9.53531 11.2396C9.50432 11.271 9.46745 11.2959 9.42682 11.313C9.38619 11.3301 9.34259 11.339 9.29851 11.3392Z"
        fill="#201F66"
      />
      <path
        d="M10.0275 11.3392C9.98336 11.3392 9.93964 11.3306 9.89886 11.3137C9.85808 11.2968 9.82105 11.272 9.78989 11.2407L9.06063 10.5117C9.02933 10.4805 9.0045 10.4434 8.98753 10.4026C8.97057 10.3618 8.96181 10.318 8.96176 10.2738C8.96171 10.2296 8.97037 10.1858 8.98724 10.145C9.00411 10.1041 9.02886 10.067 9.06008 10.0357C9.0913 10.0044 9.12838 9.97956 9.16921 9.9626C9.21003 9.94564 9.25379 9.93688 9.298 9.93683C9.3422 9.93678 9.38599 9.94544 9.42685 9.9623C9.46771 9.97917 9.50484 10.0039 9.53614 10.0351L10.2651 10.7641C10.3122 10.8112 10.3442 10.8711 10.3572 10.9363C10.3702 11.0015 10.3636 11.0692 10.3381 11.1306C10.3127 11.1921 10.2696 11.2446 10.2143 11.2816C10.159 11.3186 10.094 11.3383 10.0275 11.3383V11.3392Z"
        fill="#201F66"
      />
    </svg>
  )
}
