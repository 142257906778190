export const es = {
  translations: {
    action_complete_q_action: `Completa una acción rápida`,
    action_complete_survey: `Completa una encuesta rápida`,
    action_social_media: `Participación en redes sociales`,
    action_simple_registration: `Realiza un registro sencillo`,
    action_discover_new: `Descubre un nuevo servicio/producto`,
    action_mobile_sub: `Obtén una suscripción móvil especial`,
    action_purchase: `Realiza una compra`,
    action_new_service: `Prueba un nuevo servicio/producto`,
    action_complete_action: `Completa una acción`,
    action_quick_quiz: `Responde a un cuestionario rápido`,
    action_donation: `Realiza una donación`,
    tags_free: `Gratis`,
    tags_limited_offer: `Oferta limitada`,
    tags_multi_reward: `Recompensa múltiple`,
    tags_top_offer: `¡Mejor oferta!`,
    tags_new_users: `Solo nuevos usuarios`,
    up_to: `Hasta +{{ currencyValue }} {{ currency }}`,
    menu_faq: `Preguntas frecuentes (FAQ)`,
    menu_offers: `Ofertas`,
    menu_status: `Estado`,
    menu_privacy: `Privacidad`,
    status_pending: `Pendiente`,
    status_reported: `Reportado`,
    status_expired: `Caducado`,
    status_rejected: `Rechazado`,
    status_completed: `Tarea completada`,
    dtUserId: `Tu ID de usuario de Digital Turbine es <1>{{ digitalTurbineId }}</1>`,
    complete_text: `Completo`,
    check_inbox: `Esta oferta ha sido reportada. Por favor, revisa tu correo electrónico para la respuesta.`,
    contacted: `Gracias por ponerte en contacto con nuestro servicio al cliente. Nos pondremos en contacto contigo lo antes posible.`,
    description_report: `<0>Gracias por contactar al equipo de soporte al usuario. Hay dos razones comunes para problemas al recibir {{ currencyText }}</0><1>1. No se cumplieron los requisitos de la oferta</1><2>Las aplicaciones deben descargarse y ejecutarse para completar la oferta.</2><3>Todos los datos deben ingresarse de manera correcta y veraz.</3><4>En muchas ocasiones, es necesario validar su dirección de correo electrónico a través de un enlace enviado a su correo electrónico.</4><5>Muchos socios publicitarios permiten solo una transacción por persona o hogar.</5><6>2. Razones técnicas</6><7>No tenía una conexión de datos funcional cuando inició una aplicación promocionada.</7><8>Ya tenía la aplicación promocionada instalada antes de comenzar la oferta.</8>`,
    problem_report: `Informe de problema para {{ appName }}`,
    problem_type1: `No se han recibido créditos`,
    problem_type2: `Error durante la oferta`,
    problem_type3: `Otro`,
    email_confirmation: `Confirma tu correo electrónico`,
    full_name: `Nombre completo`,
    message: `Mensaje`,
    submit_text: `Enviar`,
    onboarding_title_1: `Completa la oferta y recibe recompensas`,
    onboarding_title_2: `Verificar estado de la oferta`,
    onboarding_title_3: `¿Faltan recompensas?`,
    offer_instructions: `Instrucciones de la oferta`,
    offer_details: `*Puede tomar varios días para que las recompensas aparezcan en tu cuenta`,
    next_text: `Siguiente`,
    done_text: `Hecho`,
    onboarding_page2_text1: `Abre el menú ubicado en la esquina superior izquierda`,
    onboarding_page2_text2: `Toca "Estado" para ver todas tus ofertas`,
    onboarding_page2_text3: `Encuentra tu oferta y toca para ver detalles`,
    onboarding_page3_text1: `Encuentra la oferta y toca "Informar problema"`,
    onboarding_page3_text2: `Completa el formulario y envía tu problema`,
    onboarding_page3_text3: `¡Nuestro equipo de soporte se pondrá en contacto contigo!`,
    reengagement_earn_up: `Gana hasta <1>+{{ currencyValue }}</1> {{ currencyName }}`,
    reengagement_description: `¡Todavía tienes ofertas en progreso! <1></1> No es demasiado tarde para completarlas`,
    reengagement_status_text: `Continuar jugando`,
    reengagement_expires1: `Caduca en {{ days }} días`,
    reengagement_expires2: `Caduca en {{ hours }} horas`,
    reengagement_button_text: `VER TODAS LAS OFERTAS`,
    modal_reward: `Recompensas totales`,
    modal_task: `Tareas totales`,
    modal_time: `Días para completar`,
    modal_tasks_completed: `Tareas completadas`,
    modal_remaining_days: `Días restantes`,
    modal_tasks_text: `Tareas`,
    modal_instructions_text: `Instrucciones`,
    complete_offer: `Completar la oferta`,
    report_button_text: `¿Tienes problemas? Haz clic aquí`,
    report_timestamp: `Podrás informar sobre esta oferta en {{ reportTime }}`,
    menu_user_support_guide: `Guía de Apoyo al Usuario`,
    banner_support: `Soporte`,
    mr_text_earned: `Ganado`,
    mr_text_total: `Total`,
    earn_up_to: `Gana hasta {{ currencyValue }} {{ currency }}`,
    earn_text: `Gana {{ currencyValue }} {{ currency }}`,
    up_to_text: `Hasta`,
    tab_1_label: `Ofertas bien pagadas`,
    tab_2_label: `Ofertas fáciles`,
  },
}
