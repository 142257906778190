import { ReactElement } from 'react'

interface Props {
  children: ReactElement
  onClickOutside: () => void
}

export function Modal({ children, onClickOutside }: Props) {
  return (
    <div
      role="dialog"
      className="flex justify-center items-center fixed top-0 left-0 w-full h-full p-0 bg-black/[.8] border-none z-[2] overflow-y-scroll"
      onClick={onClickOutside}
    >
      <div
        className="contents overflow-y-scroll"
        onClick={(event) => event.stopPropagation()}
      >
        {children}
      </div>
    </div>
  )
}
