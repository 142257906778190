import { useQueryClient } from '@tanstack/react-query'
import { useGetConfig } from 'api/config/hooks'
import useStopwatch from 'hooks/useStopwatch'
import { useEffect } from 'preact/hooks'
import { Trans } from 'react-i18next'
import styles from './styles.module.css'

const OfferHint = () => {
  const { timeLeft, setTimeLeft, showFormattedTime, startTimer, resetTimer } =
    useStopwatch()

  const queryClient = useQueryClient()
  const { data: config } = useGetConfig({ enabled: false })

  const multiplier = config?.rewardMultiplier
  const currencyName = config?.currencyName
  const formattedTime = showFormattedTime()

  if (timeLeft === 0) {
    queryClient.invalidateQueries(['config'])
    resetTimer()
  }

  useEffect(() => {
    if (config?.vcsTimeLeft) {
      setTimeLeft(config?.vcsTimeLeft)
      startTimer()
    }
  }, [config?.vcsTimeLeft])

  const text =
    timeLeft === null ? (
      <Trans i18nKey="vcs_time_left_1">
        <strong>A limited time offer!</strong> Earn{' '}
        <strong>{{ multiplier }} times </strong>
        more {{ currencyName }}
      </Trans>
    ) : (
      <Trans>
        <strong>SALE!</strong> Earn <strong>{{ multiplier }}x</strong> more for
        the next <strong>{{ formattedTime }}</strong>
      </Trans>
    )

  return <p className={styles.hint}>{text}</p>
}

export default OfferHint
