import { useQuery } from '@tanstack/react-query'
import { useRouterStore } from 'store/router'
import ConfigService from './service'

interface UseGetConfigOptions {
  enabled: boolean
}

export function useGetConfig({ enabled = false }: UseGetConfigOptions) {
  const { query, pathname } = useRouterStore()
  const isPreview = pathname === '/placement-preview'

  return useQuery(
    ['config'],
    () =>
      isPreview
        ? ConfigService.getPreview(query as any)
        : ConfigService.get(query as any),
    {
      retry: 5,
      retryDelay: 2000,
      staleTime: 100000,
      enabled: enabled,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )
}
