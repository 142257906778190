import { AssetUrl } from 'api/offer/types'
import { useEffect, useRef } from 'preact/hooks'
import { useVideoStore } from 'store/video'
import { useSwiperSlide } from 'swiper/react'
import MuteButton from './MuteButton'
import PlayButton from './PlayButton'

interface Props {
  playing?: boolean
  assetUrl: AssetUrl
}

export default function Video({ assetUrl }: Props) {
  const { isActive } = useSwiperSlide()
  const ref = useRef<HTMLVideoElement>()

  const muted = useVideoStore((state) => state.muted)
  const mute = useVideoStore((state) => state.mute)
  const unmute = useVideoStore((state) => state.unmute)

  const playing = useVideoStore((state) => state.playing)
  const play = useVideoStore((state) => state.play)
  const pause = useVideoStore((state) => state.pause)

  useEffect(() => {
    // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted

    if (playing) {
      const playPromise = ref.current?.play()
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            ref.current?.play()
          })
          .catch(() => {
            // Auto-play was prevented
            // Show paused UI.
          })
      }
    } else {
      ref.current?.pause()
    }
  }, [playing])

  useEffect(() => {
    if (!isActive) pause()
    if (isActive) play()
  }, [isActive])

  return (
    <div className="contents">
      <video
        className="flex-shrink-0 m-0 p-0 w-full h-full object-contain landscape:w-screen landscape:h-screen landscape:md:w-full landscape:md:h-full"
        ref={ref}
        src={assetUrl.low}
        loop
        autoPlay={playing}
        playsInline
        muted={muted}
        onClick={pause}
        preload="none"
      />
      <div className="contents">
        <MuteButton muted={muted} onClick={muted ? unmute : mute} />
        {!playing && <PlayButton onClick={play} />}
      </div>
    </div>
  )
}
