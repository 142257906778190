import { TransactionResponseDto } from 'api/transaction/types'

export const transactionResponse: TransactionResponseDto = {
  user: {
    id: 202998539,
    userid: 'therandomuser',
    application_id: 1246,
    email: null,
    activation_code: null,
    locale: null,
    accepted_fair_play: false,
    flash_cookie: null,
    score: 0,
    creation_date: null,
    created_at: 1608115952,
    updated_at: 1608115952,
  },
  transactions: [
    {
      reportable: true,
      action_description: null,
      actn_text: 'Download and START',
      all_rewards: [],
      attribution_window: 30,
      can_be_continued: true,
      completed: 'no',
      complexity: 0,
      dynamic_payout_enabled: false,
      icon_hires: 'https:/app_icons/4263/big_icon.png',
      icon_lores: 'https:/app_icons/4263/big_icon.png',
      id: 1319729782,
      landing_page_id: 107189,
      mobile_app_identifier: 'com.frogmind.badland',
      offer_url:
        'https://mqh.go2cloud.org/aff_ad?campaign_id=15069&aff_id=88&aff_sub=5f7aac7d-8b60-4406-96ac-208a9842642b&aff_sub3=14684&aff_sub5=0.3',
      mobile_problem_reported: null,
      mobile_report_link: 'Report',
      mobile_show_latency: '38 minutes',
      mobile_status: 'Expired',
      multi_payout: true,
      action_steps: [
        {
          action_id: 'LEVEL10',
          completed: true,
          step: 'Complete level 10',
          payout_distribution: 30,
          rewd_amnt: 30,
        },
        {
          action_id: 'LEVEL20',
          completed: false,
          step: 'Complete level 20',
          payout_distribution: 20,
          rewd_amnt: 20,
        },
        {
          action_id: 'LEVEL30',
          completed: false,
          step: 'Complete level 30',
          payout_distribution: 20,
          rewd_amnt: 20,
        },
        {
          action_id: 'LEVEL40',
          completed: false,
          step: 'Complete level 40',
          payout_distribution: 20,
          rewd_amnt: 20,
        },
        {
          action_id: 'LEVEL50',
          completed: false,
          step: 'Complete level 50',
          payout_distribution: 10,
          rewd_amnt: 10,
        },
      ],
      problem_reported: null,
      program_id: '94523',
      report: null,
      report_button_time: 'a few minutes',
      report_link: 'Report problem',
      required_actions: 'Download and START',
      reward: 51,
      rewd_amnt: 51,
      show_latency: null,
      starting_time: '29 days ago',
      status: 'expired',
      subid: 'f561af1e-081a-4e12-9a10-e08a3221d584',
      title: 'BADLAND',
      transaction_comment: null,
      type: 'app',
      user_task: {
        id: 5,
        icon: '/assets/19274/icos_ofw_install_original.svg',
      },
      user_task_icon: '/assets/19274/icos_ofw_install_original.svg',
      virtual_currency: {
        name: 'Banana',
        icon: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/opd/virtual_currency_icon/75cfa861-c5b9-41c3-a87c-4b7e5f9d4bc7.png',
      },
      instruction_steps: ['instruction 1', 'instruction 2', 'instruction 3'],
    },
    {
      reportable: true,
      action_description: null,
      actn_text: 'Download and START',
      all_rewards: [],
      attribution_window: 30,
      can_be_continued: true,
      completed: 'no',
      complexity: 1,
      dynamic_payout_enabled: false,
      icon_hires: 'https:/app_icons/11198/big_icon.png',
      icon_lores: 'https:/app_icons/11198/big_icon.png',
      id: 1319729780,
      landing_page_id: 151172,
      mobile_app_identifier: 'com.alkobyshai.sudokusolver',
      mobile_problem_reported: null,
      mobile_report_link: 'Report',
      mobile_show_latency: '38 minutes',
      mobile_status: 'Expired',
      multi_payout: false,
      action_steps: [
        {
          step: 'Download and open application',
          completed: false,
        },
      ],
      problem_reported: null,
      program_id: '121531',
      report: null,
      report_button_time: 'a few minutes',
      report_link: 'Report problem',
      required_actions: 'Download and START',
      reward: 44,
      rewd_amnt: 44,
      show_latency: null,
      starting_time: '30 days ago',
      status: 'expired',
      subid: '82ed3351-ec3e-42a6-a0f4-e08a60cecf82',
      title: 'Sudoku Solver',
      transaction_comment: null,
      type: 'app',
      user_task: {
        id: 5,
        icon: '/assets/19274/icos_ofw_install_original.svg',
      },
      user_task_icon: '/assets/19274/icos_ofw_install_original.svg',
      virtual_currency: {
        name: 'Banana',
        icon: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/opd/virtual_currency_icon/75cfa861-c5b9-41c3-a87c-4b7e5f9d4bc7.png',
      },
      instruction_steps: [],
    },
    {
      reportable: true,
      action_description: 'Bar',
      action_steps: [
        {
          step: 'Download and open application',
          completed: false,
        },
      ],
      actn_text: 'Download and START',
      all_rewards: [],
      attribution_window: 30,
      can_be_continued: true,
      completed: 'no',
      complexity: 50,
      dynamic_payout_enabled: false,
      icon_hires: 'https:/app_icons/11317/big_icon.png',
      icon_lores: 'https:/app_icons/11317/big_icon.png',
      id: 1319729790,
      landing_page_id: 151422,
      mobile_app_identifier: 'com.tinder',
      mobile_problem_reported: null,
      mobile_report_link: null,
      mobile_show_latency: '50 minutes',
      mobile_status: 'Verifying',
      multi_payout: false,
      problem_reported: null,
      program_id: '123442',
      report: null,
      report_button_time: '44 minutes',
      report_link: null,
      required_actions: 'Download and START',
      reward: 49,
      rewd_amnt: 49,
      show_latency:
        '50 minutes<br/>You can contact our support team after the expected waiting time has passed.',
      starting_time: '6 minutes ago',
      status: 'pending',
      subid: '93454105-5ca5-432f-9ec8-e08a02a3e0de',
      title: 'Tinder',
      transaction_comment: null,
      type: 'task',
      user_task: {
        id: 27,
        icon: '/assets/19273/icos_ofw_mobile_original.svg',
      },
      user_task_icon: '/assets/19273/icos_ofw_mobile_original.svg',
      virtual_currency: {
        name: 'Banana',
        icon: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/opd/virtual_currency_icon/75cfa861-c5b9-41c3-a87c-4b7e5f9d4bc7.png',
      },
      instruction_steps: [],
    },
    {
      reportable: false,
      action_description: 'Make sure this works',
      action_steps: [
        {
          step: 'Install and Play until level 10',
          completed: false,
        },
      ],
      actn_text: 'Nakarm kotka',
      all_rewards: [],
      attribution_window: 30,
      can_be_continued: null,
      completed: 'no',
      complexity: 0,
      dynamic_payout_enabled: false,
      icon_hires:
        'hires://icons.iconarchive.com/icons/artdesigner/lovely-halloween/64/crazy-cat-icon.png',
      icon_lores:
        'lores://icons.iconarchive.com/icons/artdesigner/lovely-halloween/64/crazy-cat-icon.png',
      id: 1319729786,
      landing_page_id: 101801,
      mobile_app_identifier: '',
      mobile_problem_reported: null,
      mobile_report_link: 'Report',
      mobile_show_latency: '38 minutes',
      mobile_status: 'Verifying',
      multi_payout: false,
      problem_reported: null,
      program_id: '90911',
      report: null,
      report_button_time: 'a few minutes',
      report_link: 'Report problem',
      required_actions: 'Install and Play until level 10',
      reward: 49,
      rewd_amnt: 49,
      show_latency: null,
      starting_time: '29 days ago',
      status: 'pending',
      subid: 'be74cdb0-8adb-4fae-8d0b-e14bf3f05896',
      title: 'Mobile qa offer 20',
      transaction_comment: null,
      type: 'task',
      user_task: {
        id: 26,
        icon: '/assets/19279/icos_ofw_freetrial_original.svg',
      },
      user_task_icon: '/assets/19279/icos_ofw_freetrial_original.svg',
      virtual_currency: {
        name: 'Banana',
        icon: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/opd/virtual_currency_icon/75cfa861-c5b9-41c3-a87c-4b7e5f9d4bc7.png',
      },
      instruction_steps: [],
    },
    {
      reportable: true,
      action_description: null,
      action_steps: [
        {
          step: 'Take a Super Survey and earn FREE rewards. \r',
          completed: false,
        },
        {
          step: 'Answer a few easy questions about yourself then take a survey! These surveys are usually 7-12 minutes long. New surveys everyday. Try once a day!',
          completed: false,
        },
      ],
      actn_text: 'Take a Super Survey!\r\n',
      all_rewards: [],
      attribution_window: 30,
      can_be_continued: null,
      completed: 'no',
      complexity: 0,
      dynamic_payout_enabled: true,
      icon_hires: 'https:/assets/66821/tap-250-blue_rectangle_115.png',
      icon_lores: 'https:/assets/66821/tap-250-blue_rectangle_115.png',
      id: 1319729785,
      landing_page_id: 151381,
      mobile_app_identifier: '',
      mobile_problem_reported: null,
      mobile_report_link: 'Report',
      mobile_show_latency: '38 minutes',
      mobile_status: 'Verifying',
      multi_payout: false,
      problem_reported: null,
      program_id: '123428',
      report: null,
      report_button_time: 'a few minutes',
      report_link: 'Report problem',
      required_actions:
        'Take a Super Survey and earn FREE rewards. \r\nAnswer a few easy questions about yourself then take a survey! These surveys are usually 7-12 minutes long. New surveys everyday. Try once a day!',
      reward: 46,
      rewd_amnt: 46,
      show_latency: null,
      starting_time: '29 days ago',
      status: 'pending',
      subid: '215f4195-245c-4491-adab-e0349b33ad48',
      title: 'Take a Super Survey!',
      transaction_comment: null,
      type: 'task',
      user_task: {
        id: 14,
        icon: '/assets/19275/icos_ofw_registration_original.svg',
      },
      user_task_icon: '/assets/19275/icos_ofw_registration_original.svg',
      virtual_currency: {
        name: 'Banana',
        icon: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/opd/virtual_currency_icon/75cfa861-c5b9-41c3-a87c-4b7e5f9d4bc7.png',
      },
      instruction_steps: [],
    },
    {
      reportable: false,
      action_description: null,
      action_steps: [
        {
          step: 'Download and open application',
          completed: false,
        },
      ],
      actn_text: 'Download and START',
      all_rewards: [],
      attribution_window: 30,
      can_be_continued: true,
      completed: 'no',
      complexity: 0,
      dynamic_payout_enabled: false,
      icon_hires: 'https:/app_icons/11312/big_icon.png',
      icon_lores: 'https:/app_icons/11312/big_icon.png',
      id: 1319729787,
      landing_page_id: 151358,
      mobile_app_identifier: 'com.wb.goog.got.conquest',
      mobile_problem_reported: null,
      mobile_report_link: 'Report',
      mobile_show_latency: '38 minutes',
      mobile_status: 'Expired',
      multi_payout: false,
      problem_reported: null,
      program_id: '123414',
      report: null,
      report_button_time: 'a few minutes',
      report_link: 'Report problem',
      required_actions: 'Download and START',
      reward: 15,
      rewd_amnt: 15,
      show_latency: null,
      starting_time: '29 days ago',
      status: 'expired',
      subid: '27f115af-0918-4fd0-b6b5-e08a54650364',
      title: 'Game of Thrones: Conquest™',
      transaction_comment: null,
      type: 'app',
      user_task: {
        id: 5,
        icon: '/assets/19274/icos_ofw_install_original.svg',
      },
      user_task_icon: '/assets/19274/icos_ofw_install_original.svg',
      virtual_currency: {
        name: 'Banana',
        icon: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/opd/virtual_currency_icon/75cfa861-c5b9-41c3-a87c-4b7e5f9d4bc7.png',
      },
      instruction_steps: [],
    },
    {
      reportable: true,
      action_description: null,
      action_steps: [
        {
          step: 'Download and open application',
          completed: false,
        },
      ],
      actn_text: 'Download and START',
      all_rewards: [],
      attribution_window: 30,
      can_be_continued: true,
      completed: 'no',
      complexity: 100,
      dynamic_payout_enabled: false,
      icon_hires: 'https:/app_icons/11322/big_icon.png',
      icon_lores: 'https:/app_icons/11322/big_icon.png',
      id: 1319729791,
      landing_page_id: 151452,
      mobile_app_identifier: 'com.facebook.orca',
      mobile_problem_reported: null,
      mobile_report_link: null,
      mobile_show_latency: '38 minutes',
      mobile_status: 'Verifying',
      multi_payout: false,
      problem_reported: null,
      program_id: '123451',
      report: null,
      report_button_time: '32 minutes',
      report_link: null,
      required_actions: 'Download and START',
      reward: 73,
      rewd_amnt: 73,
      show_latency:
        '38 minutes<br/>You can contact our support team after the expected waiting time has passed.',
      starting_time: '6 minutes ago',
      status: 'pending',
      subid: '767aee2a-d7e9-41fd-985f-e08a4baa7f9b',
      title: 'Messenger – Text and Video Chat for',
      transaction_comment: null,
      type: 'app',
      user_task: {
        id: 5,
        icon: '/assets/19274/icos_ofw_install_original.svg',
      },
      user_task_icon: '/assets/19274/icos_ofw_install_original.svg',
      virtual_currency: {
        name: 'Banana',
        icon: 'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/opd/virtual_currency_icon/75cfa861-c5b9-41c3-a87c-4b7e5f9d4bc7.png',
      },
      instruction_steps: [],
    },
  ],
  support_enabled: true,
}
