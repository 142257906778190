import { http, HttpResponse, passthrough } from 'msw'
import { config } from './data'

const configHandlers = [
  http.get('*/client_config', () => {
    const shouldInterceptConfig = localStorage.getItem('interceptNeededConfig')
    if (shouldInterceptConfig) {
      return passthrough()
    }
    return HttpResponse.json(config)
  }),
]

export default configHandlers
