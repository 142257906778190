import {
  Transaction,
  TransactionDto,
  TransactionResponse,
  TransactionResponseDto,
  User,
  UserDto,
} from 'api/transaction/types'
import { actionType, complexityText, offerSteps } from '../offer/dto'
import { Offer, OfferTag } from '../offer/types'

export function transactionResponseFromDto(
  dto: TransactionResponseDto
): TransactionResponse {
  return {
    isSupportEnabled: false,
    transactions: dto.transactions.map(transactionFromDto),
    user: userFromDto(dto.user),
  }
}

function transactionFromDto(dto: TransactionDto): Transaction {
  return {
    status: dto.status,
    offer: transactionOfferFromDto(dto),
  }
}

export function transactionOfferFromDto(dto: TransactionDto): Offer {
  return {
    id: dto.landing_page_id,
    programId: dto.program_id,
    appIdentifier: dto.mobile_app_identifier,
    offerUrl: dto.offer_url,
    reportTime: dto.report_button_time,
    problemReported: dto.problem_reported,
    reportable: dto.reportable,
    subId: dto.subid,
    title: dto.title,
    icon: dto.icon_hires,
    reward: {
      amount: dto.rewd_amnt ?? dto.reward,
      amountPreSales: dto.reward,
      currency: dto.virtual_currency.name,
      icon: dto.virtual_currency.icon,
    },
    tags: statusTags(dto),
    actionType: actionType(dto.user_task?.id),
    multiPayout: dto.multi_payout,
    attributionWindow: dto.attribution_window,
    canContinue: dto.can_be_continued,
    completed: dto.completed,
    steps: offerSteps(dto),
    complexity: {
      value: dto.complexity,
      text: complexityText(dto.complexity),
    },
    instructions: dto.instruction_steps,
    expiresIn: dto.expires_in,
  }
}

function statusTags(offer: TransactionDto) {
  const badges: OfferTag[] = []

  const complexityTag = complexityText(offer.complexity) as OfferTag
  badges.push(complexityTag)

  if (offer.multi_payout) {
    badges.push('multi-rewards')
  }

  return badges
}

function userFromDto(dto: UserDto): User {
  if (!dto) {
    return null
  }

  return {
    id: dto.id,
    userId: dto.userid,
    score: dto.score,
    applicationId: dto.application_id,
    acceptedFairPlay: dto.accepted_fair_play,
    createdAt: dto.created_at,
    updatedAt: dto.updated_at,
    email: dto.email,
    activationCode: dto.activation_code,
    locale: dto.locale,
    flashCookie: dto.flash_cookie,
    creationDate: dto.creation_date,
  }
}
