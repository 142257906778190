import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { CardType } from 'types/cardType'
import { RewardIcon, TaskIcon, TimeIcon } from '../../shared/icons'
import styles from './styles.module.css'

interface Props {
  value: number
  secondaryValue?: number
  type: CardType
}

export function OfferCard({ value, secondaryValue, type }: Props) {
  return (
    <div className={styles.card}>
      <div className={styles.icon}>
        <Icon type={type} />
      </div>

      {!secondaryValue ? (
        <div className={styles.value}>
          <NumericFormat
            displayType="text"
            value={value}
            thousandSeparator={true}
            decimalScale={0}
          />
        </div>
      ) : (
        <DoubleValue primary={value} secondary={secondaryValue} />
      )}

      <Label type={type} />
    </div>
  )
}

function DoubleValue({ primary, secondary }) {
  return (
    <div className={styles.valueWrapper}>
      <div className={styles.primaryValue}>{primary}</div>
      <div className={styles.secondaryValue}>&nbsp;/&nbsp;{secondary}</div>
    </div>
  )
}

function Icon({ type }: { type: CardType }) {
  switch (type) {
    case 'reward':
      return <RewardIcon />
    case 'task':
    case 'tasks-completed':
      return <TaskIcon />
    case 'time':
    case 'remaining-days':
      return <TimeIcon />
    default:
      return null
  }
}

function Label({ type }: { type: CardType }) {
  const { t } = useTranslation()
  return (
    <div className={styles.label}>
      {t(`modal_${type}`.replaceAll('-', '_'))}
    </div>
  )
}
