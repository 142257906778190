import { createQueryString } from 'utils/createQueryString'
import { trackerURL } from 'utils/environment'
import at from './queries/at'
import event from './queries/event'
import exit from './queries/exit'
import ftue from './queries/ftue'
import mbe from './queries/mbe'
import nav from './queries/nav'
import ofw from './queries/ofw'
import sl from './queries/sl'
import tab from './queries/tab'
import { DataCommon, DataEXIT, DataFTUE, Event } from './types'

export default class TrackingService {
  static at(data): string {
    const query = at(data)
    const queryString = createQueryString(query)
    return `${trackerURL}/at.gif?${queryString}`
  }

  static ofw(data): string {
    const query = ofw(data)
    const queryString = createQueryString(query)
    return `${trackerURL}/ofw.gif?${queryString}`
  }

  static mbe(data): string {
    const query = mbe(data)
    const queryString = createQueryString(query)
    return `${trackerURL}/mbe.gif?${queryString}`
  }

  static sl(data): string {
    const query = sl(data)
    const queryString = createQueryString(query)
    return `${trackerURL}/sl.gif?${queryString}`
  }

  static nav(data): string {
    const query = nav(data)
    const queryString = createQueryString(query)
    return `${trackerURL}/nav.gif?${queryString}`
  }

  static ftue(data: DataCommon & DataFTUE): string {
    const query = ftue(data)
    const queryString = createQueryString(query)
    return `${trackerURL}/ftue.gif?${queryString}`
  }

  static exit(data: DataCommon & DataEXIT): string {
    const query = exit(data)
    const queryString = createQueryString(query)
    return `${trackerURL}/exit.gif?${queryString}`
  }

  static tab(data): string {
    const query = tab(data)
    const queryString = createQueryString(query)
    return `${trackerURL}/tab.gif?${queryString}`
  }

  static event(_event: Event, data): string {
    const query = event(_event, data)
    const queryString = createQueryString(query)
    return `${trackerURL}/tracker?${queryString}`
  }
}
