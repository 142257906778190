import { Offer } from 'api/offer/types'
import { Trans } from 'react-i18next'
import { numericFormatter } from 'react-number-format'
import { CloseIcon } from '../../shared/icons'
import { Flex } from '../../shared/layout/Flex'
import { getActionText } from '../Action/utils'
import styles from './styles.module.css'

interface Props {
  offer: Offer
  hasAssets: boolean
  onClose: () => void
}

export function OfferHeader({ offer, hasAssets, onClose }: Props) {
  const currency = offer.reward.currency
  const currencyValue = numericFormatter(offer.reward.amount.toString(), {
    displayType: 'text',
    thousandSeparator: true,
    decimalScale: 0,
  })

  const headerWidth = hasAssets
    ? `landscape:w-[calc(${document.documentElement.clientWidth}px_-_100vh)] landscape:md:w-full`
    : 'unset'
  return (
    <div
      className={`flex justify-between py-3 px-4 bg-white shadow-xs z-[3] ${headerWidth}`}
      style={{ gridArea: 'header' }}
    >
      <Flex gap="12px" alignItems="center">
        <img
          className={styles.image}
          data-testid="icon"
          src={offer.icon}
          onError={(e: any) => {
            e.target.onerror = null
            e.target.src = '/images/digital-turbine-logo.svg'
          }}
          loading="lazy"
        />
        <Flex flexDirection="column" gap="2px">
          <label className={styles.actionText} data-testid="action-text">
            {getActionText(offer.actionType)}
          </label>
          <h1 className={styles.title} data-testid="title">
            {offer.title}
          </h1>
          <h2 className={styles.rewardText} data-testid="reward-text">
            {offer.multiPayout ? (
              <Trans i18nKey="earn_up_to">
                Earn up to {{ currencyValue }} {{ currency }}
              </Trans>
            ) : (
              <Trans i18nKey="earn_text">
                Earn {{ currencyValue }} {{ currency }}
              </Trans>
            )}
          </h2>
        </Flex>
      </Flex>
      <div
        className={styles.button}
        id="close-modal-button"
        data-testid="close-button"
        onClick={onClose}
      >
        <CloseIcon fill="var(--gray-500)" />
      </div>
    </div>
  )
}
